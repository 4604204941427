import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export function TrafficSourceAccountDropdown({
                                                 tsAccount,
                                                 handleChangeTsAccount,
                                                 width,
                                                 data,
                                             }) {
    return (
        <>
            <FormControl sx={{width: width}}>
                <InputLabel id="ts-account-label">TS Account</InputLabel>
                <Select
                    labelId="ts-account-label"
                    id="ts-account-label-select"
                    value={tsAccount}
                    label="TS ACCOUNT"
                    onChange={handleChangeTsAccount}
                >
                    <MenuItem value={""}>
                        {" "}
                        <em>None</em>
                    </MenuItem>
                    {(data || []).map((item, i) => {
                        return (
                            <MenuItem key={i} value={item.id}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
}
