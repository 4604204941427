import React, {useContext, useEffect} from "react";

import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {getAlgorithmsTypes} from "../context/actions/algorithms/algorithms";
import {checkAutoBiddingEnabled} from "../context/actions/campaign/campaign";
import {OpenChangeBidDialog} from "./openChangeBidDialog";
import {ManualBiddingDialog} from "./manualBiddingComponent";
import BidScheduling from "./bidScheduling";
import {OpenSchedulingModal} from "./openSchedulingModal";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import AutoBidding from "./autoBidding";
import {GlobalContext} from "../context/Provider";

const listItems = ["Manual Bidding", "Auto Bidding", "Bid Scheduling"];

export function AlertDialogChangeModal({
                                           handleCloseDialog,
                                           handleChange,
                                           dataForEdit
                                       }) {
    const [openmanualBidingDialog, setOpenManualBidingDialog] =
        React.useState(false);
    const [openScheduling, setOpenScheduling] = React.useState(false);

    const [openAutoBidding, setOpenAutoBidding] = React.useState(false);

    const {
        campaignState,
        campaignDispatch,
        algorithmsState,
        algorithmsDispatch,
    } = useContext(GlobalContext);
    const {checkSelectedAutoBiddingAlgorithm} = campaignState;
    const {algorithmsTypes} = algorithmsState;

    useEffect(() => {
        getAlgorithmsTypes()(algorithmsDispatch);
    }, [algorithmsDispatch]);
    const typeLengthGtZero = algorithmsTypes?.data.length > 0;
    useEffect(() => {
        algorithmsTypes?.data.forEach((item) => {
            if (item.algorithm_type === "BIDDING_OPTIMIZATION") {
                checkAutoBiddingEnabled(
                    Number(dataForEdit?.id),
                    Number(item.id)
                )(campaignDispatch);
            }
        });
    }, [algorithmsTypes?.data, typeLengthGtZero, campaignDispatch,dataForEdit?.id]);

    const handleChangeSave = (data) => {
        handleChange(data);
    };

    const openDialogForModal = (name) => {
        switch (name) {
            case "Manual Bidding":
                if (checkSelectedAutoBiddingAlgorithm?.data === null) {
                    setOpenManualBidingDialog(true);
                }
                break;
            case "Bid Scheduling":
                setOpenScheduling(true);
                break;
            case "Auto Bidding":
                setOpenAutoBidding(true);
                break;
            default:
                return "";
        }
    };
    const handleCloseManualBidding = () => {
        setOpenManualBidingDialog(false);
    };
    const handleCloseSchedulingModal = () => {
        setOpenScheduling(false);
    };
    const handleCloseAutoBiddingModal = () => {
        setOpenAutoBidding(false);
    };
    let manualBiddingOffInfo = (
        <span style={{color: "#808080"}}>
      Manual Bidding is off while Auto Bidding is on for this campaign
    </span>
    );
    return (
        <div>
            {listItems.map((item, i) => {
                return (
                    <ListItem key={i} sx={{cursor: "pointer"}}>
                        <ListItemAvatar>
                            <Avatar>
                                {item === "Bid Scheduling" ? (
                                    <EventNoteIcon sx={{color: "#fff"}}/>
                                ) : item === "Auto Bidding" ? (
                                    <AutoGraphIcon sx={{color: "#fff"}}/>
                                ) : item === "Manual Bidding" ? (
                                    <PriceChangeIcon sx={{color: "#fff"}}/>
                                ) : null}
                            </Avatar>
                        </ListItemAvatar>
                        <span
                            onClick={() => openDialogForModal(item)}
                            style={{width: 400}}
                        >
              {checkSelectedAutoBiddingAlgorithm?.data === null ||
              item !== "Manual Bidding" ? (
                  item
              ) : (
                  <span>
                    {manualBiddingOffInfo}
                  </span>
              )}
            </span>
                    </ListItem>
                );
            })}

            <OpenChangeBidDialog
                open={openmanualBidingDialog}
                handleClose={handleCloseManualBidding}
            >
                <ManualBiddingDialog
                    campaignId={dataForEdit?.campaignId}
                    dataForEdit={dataForEdit}
                    handleChangeBidValue={handleChangeSave}
                    handleCloseManualDialog={handleCloseManualBidding}
                />
            </OpenChangeBidDialog>
            <OpenSchedulingModal
                size={"1300px"}
                open={openScheduling}
                handleClose={handleCloseSchedulingModal}
            >
                <BidScheduling
                    campaignId={dataForEdit?.campaignId}
                    dataForEdit={dataForEdit}
                    handleChangeBidValue={handleChangeSave}
                    handleCloseSchedulingModal={handleCloseSchedulingModal}
                />
            </OpenSchedulingModal>
            <OpenSchedulingModal
                size={"700px"}
                open={openAutoBidding}
                handleClose={handleCloseAutoBiddingModal}
            >
                <AutoBidding
                    campaignId={dataForEdit?.campaignId}
                    dataForEdit={dataForEdit}
                    handleChangeBidValue={handleChangeSave}
                    handleCloseAutoBiddingModal={handleCloseAutoBiddingModal}
                    handleCloseOriginalDialog={handleCloseDialog}

                />
            </OpenSchedulingModal>
            <Button
                sx={{mt: 3, mb: 2, mr: 5}}
                onClick={handleCloseDialog}
                color="warning"
            >
                Cancel
            </Button>
        </div>
    );
}
