import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_EVENTS_ERROR,
    CREATE_EVENTS_LOADING,
    CREATE_EVENTS_SUCCESS,
    DELETE_EVENT_ERROR,
    DELETE_EVENT_LOADING,
    DELETE_EVENT_SUCCESS,
    GET_EVENTS_ERROR,
    GET_EVENTS_LOADING,
    GET_EVENTS_REMOTE_TS_ERROR,
    GET_EVENTS_REMOTE_TS_LOADING,
    GET_EVENTS_REMOTE_TS_SUCCESS,
    GET_EVENTS_REMOTE_VOLUUM_ERROR,
    GET_EVENTS_REMOTE_VOLUUM_LOADING,
    GET_EVENTS_REMOTE_VOLUUM_SUCCESS,
    GET_EVENTS_SUCCESS,
    UPDATE_EVENT_ERROR,
    UPDATE_EVENT_SUCCESS
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";


export const getEvents = (tsAccountId) => (dispatch) => {
    const id = tsAccountId ? tsAccountId : null;
    dispatch({
        type: GET_EVENTS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/event/`, {
            params: {
                ts_account_id: id,
            },
        })
        .then((res) => {
            dispatch({
                type: GET_EVENTS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_EVENTS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getRemoteTS = (tsAccountId) => (dispatch) => {
    dispatch({
        type: GET_EVENTS_REMOTE_TS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/event/remote/ts/${tsAccountId}`, {})
        .then((res) => {
            dispatch({
                type: GET_EVENTS_REMOTE_TS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_EVENTS_REMOTE_TS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getRemoteVoluum = () => (dispatch) => {
    dispatch({
        type: GET_EVENTS_REMOTE_VOLUUM_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/event/remote/voluum`, {})
        .then((res) => {
            dispatch({
                type: GET_EVENTS_REMOTE_VOLUUM_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_EVENTS_REMOTE_VOLUUM_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};


export const createAndEditEvent = (event) => (dispatch) => {
    dispatch({
        type: CREATE_EVENTS_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/event`, event)
        .then((res) => {
            if (!!event.id) {
                dispatch({
                    type: UPDATE_EVENT_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CREATE_EVENTS_SUCCESS,
                    payload: res.data,
                });
            }

        })
        .catch((err) => {
            if (!!event.id) {
                dispatch({
                    type: UPDATE_EVENT_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            } else {
                dispatch({
                    type: CREATE_EVENTS_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            }
        });
};

export const deleteEvent = (id) => (dispatch) => {
    dispatch({
        type: DELETE_EVENT_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/event/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_EVENT_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_EVENT_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};

