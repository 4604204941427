import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_TS_ACCOUNTS_ERROR,
    CREATE_TS_ACCOUNTS_LOADING,
    CREATE_TS_ACCOUNTS_SUCCESS,
    DELETE_TS_ACCOUNTS_ERROR,
    DELETE_TS_ACCOUNTS_LOADING,
    DELETE_TS_ACCOUNTS_SUCCESS,
    GET_TS_ACCOUNTS_ERROR,
    GET_TS_ACCOUNTS_LOADING,
    GET_TS_ACCOUNTS_SUCCESS,
    UPDATE_TS_ACCOUNTS_ERROR,
    UPDATE_TS_ACCOUNTS_LOADING,
    UPDATE_TS_ACCOUNTS_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createTsAccount = (tsAccount) => (dispatch) => {
    dispatch({
        type: CREATE_TS_ACCOUNTS_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/ts/accounts/`, tsAccount)
        .then((res) => {
            dispatch({
                type: CREATE_TS_ACCOUNTS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_TS_ACCOUNTS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const updateTsAccount = (tsAccount) => (dispatch) => {
    dispatch({
        type: UPDATE_TS_ACCOUNTS_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/ts/accounts/`, tsAccount)
        .then((res) => {
            dispatch({
                type: UPDATE_TS_ACCOUNTS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_TS_ACCOUNTS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getTsAccounts = () => (dispatch) => {
    dispatch({
        type: GET_TS_ACCOUNTS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/ts/accounts/`)
        .then((res) => {
            dispatch({
                type: GET_TS_ACCOUNTS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_TS_ACCOUNTS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteTsAccount = (id) => (dispatch) => {
    dispatch({
        type: DELETE_TS_ACCOUNTS_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/ts/accounts/${id}`)
        .then((res) => {
            dispatch({
                type: DELETE_TS_ACCOUNTS_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_TS_ACCOUNTS_ERROR,
                payload: err.response ? err.response.data.detail : "could not connect",
            });
        });
};
