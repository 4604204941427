import {GET_LANDER_ERROR, GET_LANDER_LOADING, GET_LANDER_SUCCESS} from "../../constants/actionTypes";

const landers = (state, {payload, type}) => {
    switch (type) {
        case GET_LANDER_LOADING: {
            return {
                ...state,
                landersList: {
                    ...state.landersList,
                    loading: true,
                },
            };
        }

        case GET_LANDER_SUCCESS: {
            return {
                ...state,
                landersList: {
                    ...state.landersList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_LANDER_ERROR: {
            return {
                ...state,
                landersList: {
                    ...state.landersList,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default landers;
