import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_USER_ERROR,
    CREATE_USER_LOADING,
    CREATE_USER_SUCCESS,
    DELETE_USER_ERROR,
    DELETE_USER_LOADING,
    DELETE_USER_SUCCESS, GET_SLACK_USERS_ERROR,
    GET_SLACK_USERS_LOADING, GET_SLACK_USERS_SUCCESS,
    GET_USER_ERROR,
    GET_USER_LOADING,
    GET_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_LOADING,
    UPDATE_USER_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createUser = (user) => (dispatch) => {
    dispatch({
        type: CREATE_USER_LOADING,
    });

    axiosInstance()
        .post(`/api/${version}/user`, user)
        .then((res) => {
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
                const errors = []
                err.response.data.detail.map((item) => errors.push(item.loc[1]))
                dispatch({
                    type: CREATE_USER_ERROR,
                    payload: {errors, errorType: "VALIDATION_ERROR"}
                });
            } else {
                dispatch({
                    type: CREATE_USER_ERROR,
                    payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
                });
            }}
        );
};

export const updateUser = (user) => (dispatch) => {
    dispatch({
        type: UPDATE_USER_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/user`, user)
        .then((res) => {
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
                const errors = []
                err.response.data.detail.map((item) => errors.push(item.loc[1]))
                dispatch({
                    type: UPDATE_USER_ERROR,
                    payload: {errors, errorType: "VALIDATION_ERROR"}
                });
            } else {
                dispatch({
                    type: UPDATE_USER_ERROR,
                    payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
                });
            }}
        );
};

export const getUsers = () => (dispatch) => {
    dispatch({
        type: GET_USER_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/user`)
        .then((res) => {
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_USER_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteUser = (id) => (dispatch) => {
    dispatch({
        type: DELETE_USER_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/user/${id}`)
        .then((res) => {
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_USER_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};

export const getSlackUsers = () => (dispatch) => {
    dispatch({
        type: GET_SLACK_USERS_LOADING
    });

    axiosInstance()
        .get(`/api/${version}/user/slack`)
        .then((res) => {
            dispatch({
                type: GET_SLACK_USERS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_SLACK_USERS_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};
