export const initReportsState = {
    report: {
        loading: false,
        data: [],
        error: null,
    },
    totalReport: {
        loading: false,
        data: [],
        error: null,
    },
    successReport: {
        loading: false,
        data: {results: []},
        error: null,
    },
    errorReport: {
        loading: false,
        data: {results: []},
        error: null,
    },
    pauseCampaign: {
        loading: false,
        error: null,
        data: null,
    },
    bidReport: {
        loading: false,
        data: [],
        error: null,
    },
    variationsReport: {
        loading: false,
        data: [],
        error: null,
    },
    performanceMonitor: {
        loading: false,
        data: [],
        error: null,
    },
};
