import {GlobalContext} from "../../context/Provider";
import {useContext, useState} from "react";
import Box from "@mui/material/Box";
import {COMPLETE_REPORT_GROUP_BY, dateToStr} from "./common";
import {Tooltip} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIconOutlined from "@mui/icons-material/EditOutlined";
import HandymanIcon from '@mui/icons-material/Handyman';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import {CreateAndEditDialog} from "../../components/createAndEditDialog";
import {CreateCampaign} from "../../campaign";
import {AlertDialog} from "../../components/dialog";
import {AlertDialogPauseAndResume} from "../../components/dialogPause";
import {CreateHistoryDialog} from "../../components/createHistoryDialog";
import {AlertDialogVariationsHistory} from "../../components/alertDialogVariationsHistory";
import {AlertDialogDuplicate, AlertDialogDuplicateResponseMessage} from "../../components/dialogDuplicate";
import {OpenModalMenu} from "../../components/openModalMenu";
import {AlertDialogChangeModal} from "../../components/dialogChangeModal";
import {AlertDialogBidHistory} from "../../components/alertDialogBidCapaignHistory";
import {AlertDialogChangeMaxBudget} from "../../components/dialogChangeMaxBudget";
import {CreateEditTask} from "../../taskManagement";
import {useNavigate} from "react-router-dom";

import {
    changeMaxDailyBudget,
    createCampaignAction,
    duplicateCampaign,
    pauseCampaign,
    resetMessageRemoteData,
    resetRemoteData,
    resumeCampaign,
    updateCampaignAction
} from "../../context/actions/campaign/campaign";
import {DUPLICATE_CAMPAIGN_ERROR, DUPLICATE_CAMPAIGN_SUCCESS} from "../../constants/actionTypes";
import {createTask} from "../../context/actions/tasks/tasks";
import {getReport} from "../../context/actions/reports/reports";
import ReportTable from "./commonReportsTable";

export default function CampaignReportsTable(props) {
    const {
        tsAccountsList,
        dateRange,
        tsAccount,
        showAssignedOnly
    } = props;
    const {
        campaignDispatch,
        reportsDispatch,
        voluumState
    } = useContext(GlobalContext);


    const navigate = useNavigate();

    const {voluumCampaigns} = voluumState;

    const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
    const [campaignId, setCampaignId] = useState("");
    const [campaignTsAccount, setCampaignTsAccount] = useState("");
    const [openPauseDialog, setOpenPauseDialog] = useState(false);
    const [isPause, setIsPause] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [openChangeMaxBudgetDialog, setOpenChangeMaxBudgetDialog] = useState(false);
    const [openHistoryBidDialog, setOpenHistoryBidDialog] = useState(false);
    const [campaignNameReport, setCampaignName] = useState("");
    const [openChangeBidDialog, setOpenChangeBidDialog] = useState(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const [openCreateEditTaskDialog, setOpenCreateEditTaskDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDuplicateDialogResMsg, setOpenDuplicateDialogResMsg] = useState(false);
    const [duplicateResMsg, setDuplicateResMsg] = useState("");

    let columns = [
        {
            field: "ts_account_id",
            headerName: "TS",
            width: 120,
            color: "#484848",
            renderCell: (cellValues) => {
                let tsAccount = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return (
                    <div
                        style={{display: "flex", alignItems: "center", color: "#484848"}}
                    >
                        {tsAccount?.name}
                    </div>
                );
            },
            filterable: false,
        },
        {field: "campaign_id", headerName: "CID", width: 80},
        {
            field: "campaign_name",
            headerName: "Campaign name",
            width: 300,
            color: "#484848",
            renderCell: (params) => (
                <>
                    <Tooltip title={params.row.campaign_name}>
                        <span
                            sx={{
                                pt: 1,
                                color: "#484848",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: 940,
                                whiteSpace: "nowrap",
                            }}
                        >
                          {params.row.campaign_name}
                        </span>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "Pause",
            headerName: "",
            color: "#484848",

            width: 30,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.campaign_id !== "Total" && (
                            <>
                                {cellValues.row.campaign_status !== "PAUSED" && (
                                    <PauseIcon
                                        style={{cursor: "pointer", color: "#484848"}}
                                        onClick={() => preparePauseCampaign(cellValues.row, true)}
                                    />
                                )}
                                {cellValues.row.campaign_status === "PAUSED" && (
                                    <PlayArrowIcon
                                        style={{cursor: "pointer", color: "#484848"}}
                                        onClick={() => preparePauseCampaign(cellValues.row, false)}
                                    />
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
        {
            field: "Duplicate",
            headerName: "",
            width: 30,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.campaign_id !== "Total" && (
                            <>
                                <ContentCopyIcon
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() => prepareDuplicateCampaign(cellValues.row, true)}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
        {
            field: "Edit",
            headerName: "",
            width: 30,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.campaign_id !== "Total" && (
                            <>
                                <EditIconOutlined
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() => redirectToEdit(cellValues)}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
        {
            field: "Drill",
            headerName: "",
            width: 30,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.campaign_id !== "Total" && (
                            <>
                                <HandymanIcon
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() => redirectToCompleteReports(cellValues.row)}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
        {field: "campaign_status", headerName: "Status", width: 100},
        {
            field: "daily_budget_reset",
            headerName: "Max Budget",
            width: 120,
            color: "#484848",
            type: "number",
            renderCell: (cellValues) => {
                if (cellValues.row.daily_budget_reset === undefined) {
                    return "";
                }
                const tsAccount = tsAccountsList.data.find(
                    (x) => x.id === cellValues.row.ts_account_id
                );
                const currency = tsAccount.currency;
                let formattedBudgetReset = "";
                if (currency === "USD") {
                    formattedBudgetReset += "$";
                } else if (currency === "EUR") {
                    formattedBudgetReset += "€";
                }
                formattedBudgetReset += cellValues.row.daily_budget_reset;
                return (
                    <>
                        {
                            <>
                                {formattedBudgetReset}&nbsp;
                                <EditIconOutlined
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() =>
                                        changeCampaignsMaxBudget(cellValues.row, false)
                                    }
                                />
                            </>
                        }
                    </>
                );
            },
        },
        {
            field: "bid",
            headerName: "Campaign Bid",
            width: 120,
            color: "#484848",
            type: "number",
            renderCell: (cellValues) => {
                if (cellValues.row.bid === undefined) {
                    return "";
                }
                const tsAccount = tsAccountsList.data.find(
                    (x) => x.id === cellValues.row.ts_account_id
                );
                const currency = tsAccount.currency;
                let formattedPrice = "";
                if (currency === "USD") {
                    formattedPrice += "$";
                } else if (currency === "EUR") {
                    formattedPrice += "€";
                }
                formattedPrice += cellValues.row.bid;
                return (
                    <>
                        {
                            <>
                                {formattedPrice}&nbsp;
                                <HistoryIcon
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() =>
                                        redirectToBidHistory(
                                            cellValues.row.campaign_id,
                                            cellValues.row.ts_account_id
                                        )
                                    }
                                />
                                <EditIconOutlined
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() =>
                                        changeCampaignsBid(cellValues.row, true, tsAccount)
                                    }
                                />
                            </>
                        }
                    </>
                );
            },
        },
        {
            field: "Bid Scheduling",
            headerName: "Active BS",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.campaign_id === "Total") {
                    return "";
                }
                if (cellValues.row.has_scheduling) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#484848",
                            }}
                        >
                            <ToggleOnOutlinedIcon/>
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#484848",
                            }}
                        >
                            <ToggleOffOutlinedIcon/>
                        </div>
                    );
                }
            },
            filterable: false,
        },
        {
            field: "impressions",
            headerName: "Impressions",
            width: 110,
            color: "#484848",
            type: "number",
        },
        {
            field: "clicks",
            color: "#484848",
            headerName: "Clicks",
            width: 80,
            type: "number",
        },
        {
            field: "cpm",
            headerName: "eCPM",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "cost",
            headerName: "Cost",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "conversions",
            headerName: "Conversions",
            width: 100,
            color: "#484848",
            type: "number",
        },
        {
            field: "revenue",
            headerName: "Revenue",
            width: 100,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "profit",
            headerName: "Profit",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "roi",
            headerName: "ROI",
            width: 100,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return params?.value + "%";
            },
        },
        {
            field: "ap",
            headerName: "AP",
            width: 80,
            color: "#484848",
            type: "number",
        },
        {
            field: "traffic_estimate",
            headerName: "Traffic Est",
            color: "#484848",
            width: 110,
            type: "number",
            valueFormatter: (params) => {
                if (params?.value === undefined) {
                    return "";
                }
                return params?.value + "%";
            },
        },
        {
            field: "G1",
            headerName: "G1",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G1 ? params.row.G1 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G1}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G2",
            headerName: "G2",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G2 ? params.row.G2 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G2}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G3",
            headerName: "G3",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G3 ? params.row.G3 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G3}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G4",
            color: "#484848",
            headerName: "G4",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G4 ? params.row.G4 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G4}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G5",
            headerName: "G5",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G5 ? params.row.G5 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G5}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G6",
            color: "#484848",
            headerName: "G6",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G6 ? params.row.G6 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G6}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "number_of_variations",
            headerName: "Variations",
            type: "number",
            color: "#484848",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.number_of_variations === undefined) {
                    return "";
                }
                const varitions = cellValues.row.number_of_variations;
                return (
                    <>
                        {
                            <>
                                {varitions}
                                <HistoryIcon
                                    onClick={() =>
                                        redirectToHistory(
                                            cellValues.row.campaign_id,
                                            cellValues.row.ts_account_id,
                                            cellValues.row
                                        )
                                    }
                                />
                            </>
                        }
                    </>
                );
            },
        },
        {
            field: "added_variations",
            headerName: "Added Variations",
            type: "number",
            color: "#484848",
            width: 120,
        },
        {
            field: "tasks_message",
            headerName: "Tasks",
            width: 300,
            color: "#484848",
            renderCell: (params) => (
                <>
                    <Tooltip title={params.row.tasks_message}>
                        <span
                            sx={{
                                pt: 1,
                                color: "#484848",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: 940,
                                whiteSpace: "nowrap",
                            }}
                        >
                          {params.row.tasks_message}
                        </span>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "Add Task",
            headerName: "Add Task",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <>
                        {cellValues.row.campaign_id !== "Total" && (
                            <>
                                <AddAlertOutlinedIcon
                                    style={{cursor: "pointer", color: "#484848"}}
                                    onClick={() => redirectToCreateTask(cellValues)}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const prepareDuplicateCampaign = (campaign, isPause) => {
        setOpenDuplicateDialog(true);
        setCampaignId(campaign.campaign_id);
        setCampaignTsAccount(campaign.ts_account_id);
    };

    const preparePauseCampaign = (campaign, isPause) => {
        setOpenPauseDialog(true);
        setCampaignId(campaign.campaign_id);
        setCampaignTsAccount(campaign.ts_account_id);
        setIsPause(isPause);
    };

    const redirectToEdit = (campaign) => {
        setOpenCreateEditDialog(true);
        setSelectedCampaign(campaign.row);
        setCampaignId(campaign.row.campaign_id);
    };

    const redirectToCompleteReports = (campaign) => {
        navigate("/reports/complete", {
            state: {
                campaignId: campaign.campaign_id,
                tsAccountId: campaign.ts_account_id,
                tsAccountName: tsAccountsList.data.find((x) => x.id === campaign.ts_account_id)?.name,
                dateRange: dateRange,
            }
        })
    }

    const changeCampaignsMaxBudget = (campaign) => {
        setOpenChangeMaxBudgetDialog(true);
        setSelectedCampaign(campaign);
        setCampaignId(campaign.campaign_id);
        setCampaignTsAccount(campaign.ts_account_id);
    };

    const redirectToBidHistory = (campaignId, tsAccountId) => {
        setOpenHistoryBidDialog(true);
        setCampaignId(campaignId);
        setCampaignName(tsAccountId);
    };

    const changeCampaignsBid = (campaign) => {
        setOpenChangeBidDialog(true);
        setSelectedCampaign(campaign);
        setCampaignId(campaign.campaign_id);
        setCampaignTsAccount(campaign.ts_account_id);
    };

    const redirectToHistory = (campaignId, tsAccountId, campaign) => {
        setOpenHistoryDialog(true);
        setCampaignId(campaignId);
        setCampaignName(tsAccountId);
        setSelectedCampaign(campaign);
    };

    const redirectToCreateTask = (campaign) => {
        setOpenCreateEditTaskDialog(true);
        setCampaignId(campaign.row.campaign_id);
    };

    const addOrEdit = (props, assignAlgo) => {
        setOpenCreateEditDialog(false);
        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        props.id
            ? updateCampaignAction(props, assignAlgo)(campaignDispatch)
            : createCampaignAction(props, assignAlgo)(campaignDispatch);
    };

    const handleCloseCreateEditDialog = () => {
        setOpenCreateEditDialog(false);
        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        resetMessageRemoteData()(campaignDispatch);
    };

    const handleCloseCreateEditTaskDialog = () => {
        setOpenCreateEditTaskDialog(false);
    };

    const handleCloseHistoryDialog = () => {
        setOpenHistoryDialog(false);

        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        resetMessageRemoteData()(campaignDispatch);
    };

    const handleCloseHistoryBidDialog = () => {
        setOpenHistoryBidDialog(false);

        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        resetMessageRemoteData()(campaignDispatch);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const handleClosePause = () => {
        setOpenPauseDialog(false);
    };

    const handleCloseDuplicate = () => {
        setOpenDuplicateDialog(false);
    };

    const handleCloseDuplicateResponseMessage = () => {
        setOpenDuplicateDialogResMsg(false);
    };

    const handleCloseCampaignBid = (data) => {
        setOpenChangeBidDialog(false);
        setSelectedCampaign("");
        setCampaignId("");
        setCampaignTsAccount("");
    };

    const handleCloseMaxBudget = (data) => {
        setOpenChangeMaxBudgetDialog(false);
        setSelectedCampaign("");
        setCampaignId("");
        setCampaignTsAccount("");
    };

    const handleChangeMaxBudget = (data) => {
        changeMaxDailyBudget(campaignId, campaignTsAccount, data)(reportsDispatch);
        setOpenChangeMaxBudgetDialog(false);
        setSelectedCampaign("");
        setCampaignId("");
        setCampaignTsAccount("");
    };

    const handleDuplicate = () => {
        setOpenDuplicateDialog(false);
        setOpenDuplicateDialogResMsg(true);
        setDuplicateResMsg("Attempting to duplicate...");

        duplicateCampaign(
            campaignId,
            campaignTsAccount
        )((res) => {
            setOpenDuplicateDialogResMsg(true);

            if (res.type === DUPLICATE_CAMPAIGN_ERROR) {
                setDuplicateResMsg(res.payload.detail);
            } else if (res.type === DUPLICATE_CAMPAIGN_SUCCESS) {
                setDuplicateResMsg(
                    "Campaign duplicated successfully. The new campaign's id is: " +
                    res.payload.remote_id
                );
            }
        });
    };

    const handlePause = () => {
        setOpenPauseDialog(false);
        isPause
            ? pauseCampaign(campaignId, campaignTsAccount)(reportsDispatch)
            : resumeCampaign(campaignId, campaignTsAccount)(reportsDispatch);
        createTask(props)(() =>
            getReport(
                dateToStr(dateRange.from),
                dateToStr(dateRange.to),
                tsAccount,
                showAssignedOnly,
                COMPLETE_REPORT_GROUP_BY.CAMPAIGN
            )(reportsDispatch));
    };

    const addTask = (props) => {
        setOpenCreateEditTaskDialog(false);
        setSelectedCampaign(null);
        createTask(props)(() =>
            getReport(
                dateToStr(dateRange.from),
                dateToStr(dateRange.to),
                tsAccount,
                showAssignedOnly,
                COMPLETE_REPORT_GROUP_BY.CAMPAIGN
            )(reportsDispatch));
    };

    return (
        <Box>
            <ReportTable
                columns={columns}
                tsAccountId={tsAccount}
                dateRange={dateRange}
                showAssignedOnly={showAssignedOnly}
                groupBy={COMPLETE_REPORT_GROUP_BY.CAMPAIGN}
            />
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateCampaign
                    recordForEdit={selectedCampaign}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                    tsAccounts={tsAccountsList.data}
                    voluumCampaigns={voluumCampaigns.data}
                    campaignId={campaignId}
                    isFromReport={true}
                />

                <AlertDialog
                    open={openDeleteDialog}
                    handleClose={alertInCreateDialog}
                    name={selectedCampaign?.remote_id}
                />
            </CreateAndEditDialog>
            <AlertDialogPauseAndResume
                open={openPauseDialog}
                handleClose={handleClosePause}
                name={campaignId}
                handlePause={handlePause}
                isPause={isPause}
            ></AlertDialogPauseAndResume>
            <CreateHistoryDialog
                open={openHistoryDialog}
                handleClose={handleCloseHistoryDialog}
            >
                <AlertDialogVariationsHistory
                    redirectToHistory={selectedCampaign}
                    handleCloseHistoryDialog={handleCloseHistoryDialog}
                    campaignId={campaignId}
                    isFromReport={true}
                    campaignNameReport={campaignNameReport}
                    tsAccounts={tsAccountsList.data}
                />
            </CreateHistoryDialog>

            <AlertDialogDuplicate
                open={openDuplicateDialog}
                handleClose={handleCloseDuplicate}
                campaignId={campaignId}
                handleDuplicate={handleDuplicate}
            ></AlertDialogDuplicate>

            <AlertDialogDuplicateResponseMessage
                open={openDuplicateDialogResMsg}
                handleClose={handleCloseDuplicateResponseMessage}
                message={duplicateResMsg}
            ></AlertDialogDuplicateResponseMessage>
            <OpenModalMenu
                open={openChangeBidDialog}
                handleCloseDialog={handleCloseCampaignBid}
            >
                <AlertDialogChangeModal
                    campaignId={campaignId}
                    handleCloseDialog={handleCloseCampaignBid}
                    dataForEdit={selectedCampaign}
                    campaignTsAccount={campaignTsAccount}
                ></AlertDialogChangeModal>
            </OpenModalMenu>

            <CreateHistoryDialog
                open={openHistoryBidDialog}
                handleClose={handleCloseHistoryBidDialog}
            >
                <AlertDialogBidHistory
                    open={openChangeBidDialog}
                    campaignId={campaignId}
                    handleClose={handleCloseHistoryBidDialog}
                    campaignTsAccount={campaignTsAccount}
                    campaignBidReport={campaignNameReport}
                ></AlertDialogBidHistory>
            </CreateHistoryDialog>
            <AlertDialogChangeMaxBudget
                open={openChangeMaxBudgetDialog}
                handleChange={handleChangeMaxBudget}
                handleClose={handleCloseMaxBudget}
                dataForEdit={selectedCampaign}
            ></AlertDialogChangeMaxBudget>

            <CreateAndEditDialog
                open={openCreateEditTaskDialog}
                handleClose={handleCloseCreateEditTaskDialog}
            >
                <CreateEditTask
                    selectedCampaignId={campaignId}
                    addOrEdit={addTask}
                    handleCloseCreateEditDialog={handleCloseCreateEditTaskDialog}>
                </CreateEditTask>
            </CreateAndEditDialog>
        </Box>
    )
}
