import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    GET_OFFER_ERROR,
    GET_OFFER_LOADING,
    GET_OFFER_SUCCESS,
    TOGGLE_SHOULD_IGNORE_OFFER_SUCCESS,
    TOGGLE_SHOULD_IGNORE_OFFER_LOADING,
    TOGGLE_SHOULD_IGNORE_OFFER_ERROR
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getOffersDomains = () => (dispatch) => {
    dispatch({
        type: GET_OFFER_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/offer/cached-offers`)
        .then((res) => {
            dispatch({
                type: GET_OFFER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_OFFER_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const toggleShouldIgnoreOffer = (offerDomain) => (dispatch) => {
    dispatch({
        type: TOGGLE_SHOULD_IGNORE_OFFER_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/offer/toggle-should-ignore?offer_domain=${offerDomain}`, {})
        .then((res) => {
            dispatch({
                type: TOGGLE_SHOULD_IGNORE_OFFER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: TOGGLE_SHOULD_IGNORE_OFFER_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
