import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    ADD_MORE_VARIATIONS_CPM,
    BIDDING_OPTIMIZATION,
    BLACKLIST_SITE_CPM,
    BLACKLIST_VARIATION_CPM,
    BLACKLIST_ZONE_CPM,
    FACTOR_BROWSERS_BLACKLISTING,
    FACTOR_DEVICE_TYPES_BLACKLISTING,
    FACTOR_DEVICES_BLACKLISTING,
    FACTOR_OS_BLACKLISTING,
    INCREASE_DAILY_BUDGET_CPM,
    DECREASE_DAILY_BUDGET_CPM,
    PAUSE_CAMPAIGN_LAST_30_DAYS_CPM,
    PAUSE_CAMPAIGN_LAST_7_DAYS_CPM,
    SHORTLIST_SITE_CPM,
    SHORTLIST_ZONE_CPM,
    WHITELIST_SITE_CPM,
    WHITELIST_ZONE_CPM
} from "../constants/algosConst";

export default function AlgoCPM({
                                    campaignType,
                                    dropdownsCPM,
                                    handleChangeAlgorithmCPM,
                                    filtered,
                                }) {
    return (
        <>
            {(campaignType === "SEED" || campaignType === "SL") && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_ZONE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_ZONE_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.BLACKLIST_ZONE_CPM}
                                onChange={handleChangeAlgorithmCPM}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_ZONE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_SITE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_SITE_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.BLACKLIST_SITE_CPM}
                                onChange={handleChangeAlgorithmCPM}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_SITE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div> {WHITELIST_ZONE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={WHITELIST_ZONE_CPM}
                                id="voluum-id-label-select"
                                displayEmpty
                                value={dropdownsCPM.WHITELIST_ZONE_CPM}
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === WHITELIST_ZONE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{WHITELIST_SITE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={WHITELIST_SITE_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.WHITELIST_SITE_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === WHITELIST_SITE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}

            {campaignType !== "SL" && campaignType !== "WL" && campaignType !== "" && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{SHORTLIST_SITE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={SHORTLIST_SITE_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.SHORTLIST_SITE_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === SHORTLIST_SITE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{SHORTLIST_ZONE_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={SHORTLIST_ZONE_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.SHORTLIST_ZONE_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === SHORTLIST_ZONE_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
            {campaignType !== "" && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_VARIATION_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_VARIATION_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.BLACKLIST_VARIATION_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_VARIATION_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{PAUSE_CAMPAIGN_LAST_7_DAYS_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={PAUSE_CAMPAIGN_LAST_7_DAYS_CPM}
                                // defaultValue={}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.PAUSE_CAMPAIGN_LAST_7_DAYS_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === PAUSE_CAMPAIGN_LAST_7_DAYS_CPM
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{PAUSE_CAMPAIGN_LAST_30_DAYS_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={PAUSE_CAMPAIGN_LAST_30_DAYS_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.PAUSE_CAMPAIGN_LAST_30_DAYS_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === PAUSE_CAMPAIGN_LAST_30_DAYS_CPM
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{ADD_MORE_VARIATIONS_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={ADD_MORE_VARIATIONS_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.ADD_MORE_VARIATIONS_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === ADD_MORE_VARIATIONS_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{INCREASE_DAILY_BUDGET_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={INCREASE_DAILY_BUDGET_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.INCREASE_DAILY_BUDGET_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === INCREASE_DAILY_BUDGET_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{DECREASE_DAILY_BUDGET_CPM}</div>
                        <FormControl fullWidth>
                            <Select
                                name={DECREASE_DAILY_BUDGET_CPM}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.DECREASE_DAILY_BUDGET_CPM}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === DECREASE_DAILY_BUDGET_CPM) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BIDDING_OPTIMIZATION}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BIDDING_OPTIMIZATION}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.BIDDING_OPTIMIZATION}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BIDDING_OPTIMIZATION) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_OS_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_OS_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.FACTOR_OS_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_OS_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_BROWSERS_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_BROWSERS_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.FACTOR_BROWSERS_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_BROWSERS_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_DEVICES_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_DEVICES_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.FACTOR_DEVICES_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_DEVICES_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_DEVICE_TYPES_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_DEVICE_TYPES_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPM.FACTOR_DEVICE_TYPES_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPM}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_DEVICE_TYPES_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
        </>
    );
}
