export const BLACKLIST_ZONE_CPC = "BLACKLIST_ZONE_CPC";
export const BLACKLIST_SITE_CPC = "BLACKLIST_SITE_CPC";
export const WHITELIST_ZONE_CPC = "WHITELIST_ZONE_CPC";
export const WHITELIST_SITE_CPC = "WHITELIST_SITE_CPC";
export const SHORTLIST_ZONE_CPC = "SHORTLIST_ZONE_CPC";
export const SHORTLIST_SITE_CPC = "SHORTLIST_SITE_CPC";
export const BLACKLIST_VARIATION_CPC = "BLACKLIST_VARIATION_CPC";
export const PAUSE_CAMPAIGN_LAST_7_DAYS_CPC = "PAUSE_CAMPAIGN_LAST_7_DAYS_CPC";
export const PAUSE_CAMPAIGN_LAST_30_DAYS_CPC =
    "PAUSE_CAMPAIGN_LAST_30_DAYS_CPC";
export const ADD_MORE_VARIATIONS_CPC = "ADD_MORE_VARIATIONS_CPC";
export const INCREASE_DAILY_BUDGET_CPC = "INCREASE_DAILY_BUDGET_CPC"
export const DECREASE_DAILY_BUDGET_CPC = "DECREASE_DAILY_BUDGET_CPC"

export const BLACKLIST_ZONE_CPM = "BLACKLIST_ZONE_CPM";
export const BLACKLIST_SITE_CPM = "BLACKLIST_SITE_CPM";
export const WHITELIST_ZONE_CPM = "WHITELIST_ZONE_CPM";
export const WHITELIST_SITE_CPM = "WHITELIST_SITE_CPM";
export const SHORTLIST_ZONE_CPM = "SHORTLIST_ZONE_CPM";
export const SHORTLIST_SITE_CPM = "SHORTLIST_SITE_CPM";
export const BLACKLIST_VARIATION_CPM = "BLACKLIST_VARIATION_CPM";
export const PAUSE_CAMPAIGN_LAST_7_DAYS_CPM = "PAUSE_CAMPAIGN_LAST_7_DAYS_CPM";
export const PAUSE_CAMPAIGN_LAST_30_DAYS_CPM = "PAUSE_CAMPAIGN_LAST_30_DAYS_CPM";
export const ADD_MORE_VARIATIONS_CPM = "ADD_MORE_VARIATIONS_CPM";
export const INCREASE_DAILY_BUDGET_CPM = "INCREASE_DAILY_BUDGET_CPM"
export const DECREASE_DAILY_BUDGET_CPM = "DECREASE_DAILY_BUDGET_CPM"
export const BIDDING_OPTIMIZATION = "BIDDING_OPTIMIZATION"
export const FACTOR_OS_BLACKLISTING = "FACTOR_OS_BLACKLISTING"
export const FACTOR_BROWSERS_BLACKLISTING = "FACTOR_BROWSERS_BLACKLISTING"
export const FACTOR_DEVICES_BLACKLISTING = "FACTOR_DEVICES_BLACKLISTING"
export const FACTOR_DEVICE_TYPES_BLACKLISTING = "FACTOR_DEVICE_TYPES_BLACKLISTING"
