import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    GET_CAMPAIGN_TYPES_ERROR,
    GET_CAMPAIGN_TYPES_LOADING,
    GET_CAMPAIGN_TYPES_SUCCESS,
    UPDATE_TYPE_TO_CAMPAIGNS_ERROR,
    UPDATE_TYPE_TO_CAMPAIGNS_LOADING,
    UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getCampaignTypes = () => (dispatch) => {
    dispatch({
        type: GET_CAMPAIGN_TYPES_LOADING,
    });

    axiosInstance()
        .get(`/api/${version}/campaign_types`)
        .then((res) => {
            dispatch({
                type: GET_CAMPAIGN_TYPES_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_CAMPAIGN_TYPES_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const updateTypeToCampaigns =
    (campaignIds, type, tsAccount) => (dispatch) => {
        dispatch({
            type: UPDATE_TYPE_TO_CAMPAIGNS_LOADING,
        });

        axiosInstance()
            .put(
                `/api/${version}/campaign_types/${type}?ts_account_id=${tsAccount}`,
                campaignIds
            )
            .then((res) => {
                dispatch({
                    type: UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_TYPE_TO_CAMPAIGNS_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };
