import{
    GET_DOMAIN_TASKS_ERROR,
    GET_DOMAIN_TASKS_LOADING,
    GET_DOMAIN_TASKS_SUCCESS,
    REPLACE_DOMAIN_ERROR,
    REPLACE_DOMAIN_LOADING,
    REPLACE_DOMAIN_SUCCESS,
    DELETE_DOMAIN_TASK_ERROR,
    DELETE_DOMAIN_TASK_LOADING,
    DELETE_DOMAIN_TASK_SUCCESS
} from "../../constants/actionTypes";

const domainTasks = (state, {payload, type}) => {
    switch (type) {
        case GET_DOMAIN_TASKS_LOADING: {
            return {
                ...state,
                domainTasksList: {
                    ...state.domainTasksList,
                    loading: true,
                },
            };
        }

        case GET_DOMAIN_TASKS_SUCCESS: {
            return {
                ...state,
                domainTasksList: {
                    ...state.domainTasksList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_DOMAIN_TASKS_ERROR: {
            return {
                ...state,
                domainTasksList: {
                    ...state.domainTasksList,
                    loading: false,
                    error: payload,
                },
            };
        }
        case REPLACE_DOMAIN_LOADING: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: true,
                },
            };
        }

        case REPLACE_DOMAIN_SUCCESS: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case REPLACE_DOMAIN_ERROR: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    error: payload,
                },
            };
        }
        case DELETE_DOMAIN_TASK_LOADING: {
            return {
                ...state,
                domainTasksList: {
                    ...state.domainTasksList,
                    loading: true,
                },
            };
        }

        case DELETE_DOMAIN_TASK_SUCCESS: {
            return {
              ...state,
              domainTasksList: {
                ...state.domainTasksList,
                loading: false,
                data: state.domainTasksList.data.filter(item => item.id !== payload.id),
              },
            };
          }
          
        case DELETE_DOMAIN_TASK_ERROR: {
            return {
                ...state,
                domainTasksList: {
                    ...state.domainTasksList,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default domainTasks;
