import {
    CREATE_EVENTS_ERROR,
    CREATE_EVENTS_LOADING,
    CREATE_EVENTS_SUCCESS,
    DELETE_EVENT_LOADING,
    DELETE_EVENT_SUCCESS,
    GET_EVENTS_ERROR,
    GET_EVENTS_LOADING,
    GET_EVENTS_REMOTE_TS_ERROR,
    GET_EVENTS_REMOTE_TS_LOADING,
    GET_EVENTS_REMOTE_TS_SUCCESS,
    GET_EVENTS_REMOTE_VOLUUM_ERROR,
    GET_EVENTS_REMOTE_VOLUUM_LOADING,
    GET_EVENTS_REMOTE_VOLUUM_SUCCESS,
    GET_EVENTS_SUCCESS,
    UPDATE_EVENT_ERROR,
    UPDATE_EVENT_SUCCESS
} from "../../constants/actionTypes";

const events = (state, {payload, type}) => {
    switch (type) {
        case GET_EVENTS_LOADING: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_EVENTS_SUCCESS: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_EVENTS_ERROR: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case GET_EVENTS_REMOTE_TS_LOADING: {
            return {
                ...state,
                eventsTsList: {
                    ...state.eventsTsList,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_EVENTS_REMOTE_TS_SUCCESS: {
            return {
                ...state,
                eventsTsList: {
                    ...state.eventsTsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_EVENTS_REMOTE_TS_ERROR: {
            return {
                ...state,
                eventsTsList: {
                    ...state.eventsTsList,
                    loading: false,
                    error: payload,
                },
            };
        }
        case GET_EVENTS_REMOTE_VOLUUM_LOADING: {
            return {
                ...state,
                eventsVoluumList: {
                    ...state.eventsVoluumList,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_EVENTS_REMOTE_VOLUUM_SUCCESS: {
            return {
                ...state,
                eventsVoluumList: {
                    ...state.eventsVoluumList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_EVENTS_REMOTE_VOLUUM_ERROR: {
            return {
                ...state,
                eventsVoluumList: {
                    ...state.eventsVoluumList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_EVENTS_LOADING: {
            return {
                ...state,
                addEvent: {
                    ...state.addEvent,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_EVENTS_ERROR: {
            return {
                ...state,
                addEvent: {
                    ...state.addEvent,
                    loading: false,
                },
            };
        }

        case CREATE_EVENTS_SUCCESS: {
            return {
                ...state,
                addEvent: {
                    ...state.addEvent,
                    loading: false,
                    data: payload,
                },

                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: [payload, ...state.eventsList.data],
                },
            };
        }

        case DELETE_EVENT_LOADING: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: state.eventsList.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_EVENT_SUCCESS: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: state.eventsList.data.filter((item) => item.id !== payload),
                },
            };
        }

        case UPDATE_EVENT_SUCCESS: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: state.eventsList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        case UPDATE_EVENT_ERROR: {
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    data: state.eventsList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        default:
            return state;
    }
};

export default events;
