import {
    ASSIGN_ALGORITHMS_ERROR,
    ASSIGN_ALGORITHMS_LOADING,
    ASSIGN_ALGORITHMS_SUCCESS,
    CHECK_AUTO_BIDDING_DATA_ERROR,
    CHECK_AUTO_BIDDING_DATA_LOADING,
    CHECK_AUTO_BIDDING_DATA_SUCCESS,
    CREATE_ALGO_BIDDING_ERROR,
    CREATE_ALGO_BIDDING_LOADING,
    CREATE_ALGO_BIDDING_SUCCESS,
    ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING,
    ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS,
    CREATE_CAMPAIGN_ERROR,
    CREATE_CAMPAIGN_LOAD,
    CREATE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_LOADING,
    DELETE_CAMPAIGN_SUCCESS,
    GET_CAMPAIGNS_FOR_ALGORITHM_ERROR,
    GET_CAMPAIGNS_FOR_ALGORITHM_LOADING,
    GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS,
    GET_CAMPAIGN_BY_ID_ERROR,
    GET_CAMPAIGN_BY_ID_LOADING,
    GET_CAMPAIGN_BY_ID_SUCCESS,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS,
    GET_CAMPAIGN_TREE_ERROR,
    GET_CAMPAIGN_TREE_LOADING,
    GET_CAMPAIGN_TREE_SUCCESS,
    GET_MANUAL_BIDDING_ERROR,
    GET_MANUAL_BIDDING_LOADING,
    GET_MANUAL_BIDDING_SUCCESS,
    GET_REMOTE_DATA_CAMPAIGN_ERROR,
    GET_REMOTE_DATA_CAMPAIGN_LOADING,
    GET_REMOTE_DATA_CAMPAIGN_SUCCESS,
    GET_SEED_CAMPAIGN_ERROR,
    GET_SEED_CAMPAIGN_LOAD,
    GET_SEED_CAMPAIGN_SUCCESS,
    RESET_ASSIGN_ALGORITHMS_LOADING,
    RESET_ERROR_CAMPAIGN_ERROR,
    RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR,
    RESET_ERROR_MESSAGE_TREE_ERROR,
    RESET_REMOTE_DATA_CAMPAIGN,
    UPDATE_CAMPAIGN_ERROR,
    UPDATE_CAMPAIGN_LOADING,
    UPDATE_CAMPAIGN_SUCCESS,
} from "../../constants/actionTypes";

const campaign = (state, {payload, type}) => {
    switch (type) {
        case GET_REMOTE_DATA_CAMPAIGN_LOADING: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loading: true,
                },
            };
        }

        case GET_REMOTE_DATA_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loading: false,
                    data: payload,
                    message: null,
                    error: false,
                },
            };
        }
        case GET_REMOTE_DATA_CAMPAIGN_ERROR: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loading: false,
                    data: null,
                    message: payload,
                    error: true,
                },
            };
        }

        case RESET_REMOTE_DATA_CAMPAIGN: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loading: false,
                    data: payload,
                },
            };
        }

        case RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR: {
            return {
                ...state,
                remoteData: {
                    loading: false,
                    data: null,
                    message: payload,
                    error: false,
                },
            };
        }

        case GET_SEED_CAMPAIGN_LOAD: {
            return {
                ...state,
                campaigns: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        }

        case GET_SEED_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaigns: {
                    loading: false,
                    data: payload,
                },
            };
        }
        case RESET_ERROR_CAMPAIGN_ERROR: {
            return {
                ...state,
                campaigns: {
                    loading: false,
                    data: [],
                    error: payload,
                },
            };
        }
        case ASSIGN_ALGORITHMS_LOADING: {
            return {
                ...state,
                assignAlgoCampaign: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        }

        case ASSIGN_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                assignAlgoCampaign: {
                    loading: false,
                    data: payload,
                },
            };
        }
        case ASSIGN_ALGORITHMS_ERROR: {
            return {
                ...state,
                assignAlgoCampaign: {
                    loading: false,
                    data: [],
                    error: payload,
                },
            };
        }

        case RESET_ASSIGN_ALGORITHMS_LOADING: {
            return {
                ...state,
                assignAlgoCampaign: {
                    loading: false,
                    error: null,
                    data: payload,
                },
            };
        }
        case GET_SEED_CAMPAIGN_ERROR: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_CAMPAIGN_LOAD: {
            return {
                ...state,
                addCampaign: {
                    ...state.addCampaign,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_CAMPAIGN_ERROR: {
            return {
                ...state,
                addCampaign: {
                    ...state.addCampaign,
                    loading: false,
                },
            };
        }

        case CREATE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                addCampaign: {
                    ...state.addCampaign,
                    loading: false,
                    data: payload,
                },

                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: [payload, ...state.campaigns.data],
                },
            };
        }
        case UPDATE_CAMPAIGN_LOADING: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: true,
                },
            };
        }
        case UPDATE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: state.campaigns.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        case UPDATE_CAMPAIGN_ERROR: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: state.campaigns.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }

        case DELETE_CAMPAIGN_LOADING: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: state.campaigns.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: state.campaigns.data.filter((item) => item.id !== payload),
                },
            };
        }

        case GET_CAMPAIGN_TREE_LOADING: {
            return {
                ...state,
                treeCampaign: {
                    loading: true,
                    error: null,
                    data: null,
                },
            };
        }

        case GET_CAMPAIGN_TREE_SUCCESS: {
            return {
                ...state,
                treeCampaign: {
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_CAMPAIGN_TREE_ERROR: {
            return {
                ...state,
                treeCampaign: {
                    loading: false,
                    data: null,
                    message: payload,
                    error: true,
                },
            };
        }

        case RESET_ERROR_MESSAGE_TREE_ERROR: {
            return {
                ...state,
                treeCampaign: {
                    loading: false,
                    data: null,
                    message: payload,
                    error: false,
                },
            };
        }

        case ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING: {
            return {
                ...state,
                assignAlgorithmsCampaign: {
                    ...state.assignAlgorithmsCampaign,
                    loading: false,
                },
            };
        }

        case ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                assignAlgorithmsCampaign: {
                    ...state.assignAlgorithmsCampaign,
                    loading: false,
                    data: payload,
                },

                campaigns: {
                    ...state.campaigns,
                    loading: false,
                    data: [payload, ...state.campaigns.data],
                },
            };
        }

        case GET_CAMPAIGNS_FOR_ALGORITHM_LOADING: {
            return {
                ...state,
                getAssignAlgorithmsCampaign: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        }

        case GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS: {
            return {
                ...state,
                getAssignAlgorithmsCampaign: {
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_CAMPAIGNS_FOR_ALGORITHM_ERROR: {
            return {
                ...state,
                getAssignAlgorithmsCampaign: {
                    loading: false,
                    data: null,
                    error: true,
                },
            };
        }

        case GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING: {
            return {
                ...state,
                getCampaignDefaultAlgorithmsState: {
                    loading: true,
                    error: null,
                    data: [],
                },
            };
        }

        case GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                getCampaignDefaultAlgorithmsState: {
                    ...state.getCampaignDefaultAlgorithmsState,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR: {
            return {
                ...state,
                getCampaignDefaultAlgorithmsState: {
                    loading: false,
                    data: null,
                    error: true,
                },
            };
        }
        case GET_CAMPAIGN_BY_ID_LOADING: {
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    loading: true,
                },
            };
        }

        case GET_CAMPAIGN_BY_ID_SUCCESS: {
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    loading: false,
                    data: payload,
                    error: false,
                },
            };
        }
        case GET_CAMPAIGN_BY_ID_ERROR: {
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    loading: false,
                    data: null,
                    message: payload,
                    error: true,
                },
            };
        }
        case GET_MANUAL_BIDDING_LOADING: {
            return {
                ...state,
                manualBidding: {
                    ...state.manualBidding,
                    loading: true,
                    data: payload,
                },
            };
        }
        case GET_MANUAL_BIDDING_SUCCESS: {
            return {
                ...state,
                manualBidding: {
                    ...state.manualBidding,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_MANUAL_BIDDING_ERROR: {
            return {
                ...state,
                manualBidding: {
                    ...state.manualBidding,
                    loading: false,
                    data: payload,
                },
            };
        }
        case CREATE_ALGO_BIDDING_LOADING: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loadingData: true,
                },
            };
        }
        case CREATE_ALGO_BIDDING_SUCCESS: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loadingData: false,
                    algoData: payload,
                },
            };
        }
        case CREATE_ALGO_BIDDING_ERROR: {
            return {
                ...state,
                remoteData: {
                    ...state.remoteData,
                    loadingData: false,
                    algoData: payload,
                },
            };
        }
        case CHECK_AUTO_BIDDING_DATA_LOADING: {
            return {
                ...state,
                checkSelectedAutoBiddingAlgorithm: {
                    ...state.remoteData,
                    loading: true,
                    data: null,
                },
            };
        }
        case CHECK_AUTO_BIDDING_DATA_SUCCESS: {
            return {
                ...state,
                checkSelectedAutoBiddingAlgorithm: {
                    ...state.remoteData,
                    loading: false,
                    data: payload,
                },
            };
        }
        case CHECK_AUTO_BIDDING_DATA_ERROR: {
            return {
                ...state,
                checkSelectedAutoBiddingAlgorithm: {
                    ...state.remoteData,
                    loading: false,
                    data: payload,
                    error: true,
                },
            };
        }

        default:
            return state;
    }
};

export default campaign;
