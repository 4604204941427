import {
    CREATE_BLACK_LIST_SITE_ERROR,
    CREATE_BLACK_LIST_SITE_LOADING,
    CREATE_BLACK_LIST_SITE_SUCCESS,
    CREATE_BLACK_LIST_ZONE_ERROR,
    CREATE_BLACK_LIST_ZONE_LOADING,
    CREATE_BLACK_LIST_ZONE_SUCCESS,
    DELETE_BLACK_LIST_SITE_LOADING,
    DELETE_BLACK_LIST_SITE_SUCCESS,
    DELETE_BLACK_LIST_ZONE_LOADING,
    DELETE_BLACK_LIST_ZONE_SUCCESS,
    GET_BLACK_LIST_SITE_ERROR,
    GET_BLACK_LIST_SITE_LOADING,
    GET_BLACK_LIST_SITE_SUCCESS,
    GET_BLACK_LIST_ZONE_ERROR,
    GET_BLACK_LIST_ZONE_LOADING,
    GET_BLACK_LIST_ZONE_SUCCESS,
} from "../../constants/actionTypes";

const blackListZone = (state, {payload, type}) => {
    switch (type) {
        case GET_BLACK_LIST_ZONE_LOADING: {
            return {
                ...state,
                blackListZone: {
                    ...state.blackListZone,
                    loading: true,
                },
            };
        }

        case GET_BLACK_LIST_ZONE_SUCCESS: {
            return {
                ...state,
                blackListZone: {
                    ...state.blackListZone,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_BLACK_LIST_ZONE_ERROR: {
            return {
                ...state,
                blackListZone: {
                    ...state.blackListZone,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_BLACK_LIST_ZONE_LOADING: {
            return {
                ...state,
                addVoluum: {
                    ...state.addVoluum,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_BLACK_LIST_ZONE_ERROR: {
            return {
                ...state,
                addBlackListZone: {
                    ...state.addBlackListZone,
                    loading: false,
                },
            };
        }

        case CREATE_BLACK_LIST_ZONE_SUCCESS: {
            return {
                ...state,
                addBlackListZone: {
                    ...state.addBlackListZone,
                    loading: false,
                    data: payload,
                },

                blackListZone: {
                    ...state.blackListZone,
                    loading: false,
                    data: [payload, ...state.blackListZone.data],
                },
            };
        }

        case DELETE_BLACK_LIST_ZONE_LOADING: {
            return {
                ...state,
                blackListZone: {
                    ...state.blackListZone,
                    loading: false,
                    data: state.blackListZone.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_BLACK_LIST_ZONE_SUCCESS: {
            return {
                ...state,
                blackListZone: {
                    ...state.blackListZone,
                    loading: false,
                    data: state.blackListZone.data.filter(
                        (item) => item.id !== payload
                    ),
                },
            };
        }

        case GET_BLACK_LIST_SITE_LOADING: {
            return {
                ...state,
                blackListSites: {
                    ...state.blackListSites,
                    loading: true,
                },
            };
        }

        case GET_BLACK_LIST_SITE_SUCCESS: {
            return {
                ...state,
                blackListSites: {
                    ...state.blackListSites,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_BLACK_LIST_SITE_ERROR: {
            return {
                ...state,
                blackListSites: {
                    ...state.blackListSites,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_BLACK_LIST_SITE_LOADING: {
            return {
                ...state,
                addBlackListSite: {
                    ...state.addBlackListSite,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_BLACK_LIST_SITE_ERROR: {
            return {
                ...state,
                addBlackListSite: {
                    ...state.addBlackListSite,
                    loading: false,
                },
            };
        }

        case CREATE_BLACK_LIST_SITE_SUCCESS: {
            return {
                ...state,
                addBlackListSite: {
                    ...state.addBlackListSite,
                    loading: false,
                    data: payload,
                },

                blackListSites: {
                    ...state.blackListSites,
                    loading: false,
                    data: [payload, ...state.blackListSites.data],
                },
            };
        }

        case DELETE_BLACK_LIST_SITE_LOADING: {
            return {
                ...state,
                blackListSites: {
                    ...state.blackListSites,
                    loading: false,
                    data: state.blackListSites.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_BLACK_LIST_SITE_SUCCESS: {
            return {
                ...state,
                blackListSites: {
                    ...state.blackListSites,
                    loading: false,
                    data: state.blackListSites.data.filter(
                        (item) => item.id !== payload
                    ),
                },
            };
        }

        default:
            return state;
    }
};

export default blackListZone;
