import {
    CREATE_USER_ERROR,
    CREATE_USER_LOADING,
    CREATE_USER_SUCCESS,
    DELETE_USER_LOADING,
    DELETE_USER_SUCCESS,
    GET_USER_ERROR,
    GET_USER_LOADING,
    GET_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_LOADING,
    UPDATE_USER_SUCCESS,
} from "../../constants/actionTypes";

const users = (state, {payload, type}) => {
    switch (type) {
        case GET_USER_LOADING: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: true,
                },
            };
        }

        case GET_USER_SUCCESS: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_USER_ERROR: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_USER_LOADING: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_USER_ERROR: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_USER_SUCCESS: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    loading: false,
                    data: payload,
                },

                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: [payload, ...state.usersList.data],
                },
            };
        }
        case UPDATE_USER_LOADING: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: true,
                },
            };
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: state.usersList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        case UPDATE_USER_ERROR: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: state.usersList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }

        case DELETE_USER_LOADING: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: state.usersList.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_USER_SUCCESS: {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    loading: false,
                    data: state.usersList.data.filter(
                        (item) => item.id !== payload
                    ),
                },
            };
        }

        default:
            return state;
    }
};

export default users;
