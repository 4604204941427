import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";

const createCheckbox = (periodNum, isSelected, onClick) => {
    return (<FormControlLabel
        key={"period"+periodNum}
        value={periodNum}
        control={
            <Checkbox
                checked={isSelected}
                onClick={onClick}
                name={periodNum.toString()}
            />
        }
        label={`Last ${periodNum} days`}
    />)
}

export function PeriodPanel(props) {
    const {
        label,
        availablePeriods,
        /* [number] */
        selectedPeriods,
        /* ([number])=> void */
        onSelectedPeriodsChange
    } = props;

    const onCheckboxClick = (e) => {
        const newSelectedPeriods = new Set(selectedPeriods);
        // name of checkbox is "3" or "7" or ... we convert the name to a number
        // to signify the period
        let periodNum = Number(e.target.name);
        if (e.target.checked) {
            newSelectedPeriods.add(periodNum)
        } else {
            newSelectedPeriods.delete(periodNum);
        }
        // convert to array and sort values {1,3,2} => [1,3,2] => [1,2,3]
        onSelectedPeriodsChange(Array.from(newSelectedPeriods).sort());
    }
    return (
        <Grid
            container
            sx={{mb: 5, mt: 2}}
            style={{display: "block", textAlign: "start"}}
        >
            <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                <FormLabel component="legend">{label}</FormLabel>
                <FormGroup
                    style={{display: "block", margin: "0"}}
                >
                    {
                        availablePeriods.map(periodNum => {
                            const isChecked = selectedPeriods.includes(periodNum)
                            return createCheckbox(periodNum, isChecked, onCheckboxClick)
                        })
                    }
                </FormGroup>
            </FormControl>
        </Grid>
    )
}
