import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export function OpenSchedulingModal({
                                        open,
                                        handleClose,
                                        children,
                                        size,
                                    }) {
    return (
        <div>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: `${size}`,
                        },
                    },
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </div>
    );
}
