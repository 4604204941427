import {ERROR_RESET, LOGIN_ERROR, LOGIN_LOADING, LOGIN_RESET, LOGIN_SUCCESS,} from "../../constants/actionTypes";

const auth = (state, {payload, type}) => {
    switch (type) {
        case LOGIN_LOADING:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    error: false,
                    loading: true,
                },
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    loading: false,
                    data: payload,
                },
            };

        case LOGIN_ERROR:
            return {
                ...state,
                auth: {
                    ...state.auth,
                    loading: false,
                    error: payload,
                },
            };
        case LOGIN_RESET:
            return {
                ...state.auth,
                auth: {
                    ...state.auth,
                    loading: false,
                    data: payload,
                    error: null,
                },
            };
        case ERROR_RESET:
            return {
                ...state.auth,
                auth: {
                    ...state.auth,
                    loading: false,
                    data: null,
                    error: payload,
                },
            };
        default:
            return state;
    }
};

export default auth;
