import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import {UsersDropdown} from "../components/usersDropdown";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import {Alert, Autocomplete} from "@mui/material";
import {GlobalContext} from "../context/Provider";
import {getUsers} from "../context/actions/users/users";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {getCampaigns} from "../context/actions/campaign/campaign";

function CreateEditTask(props) {
    const {
        recordCreated,
        apiError,
        addOrEdit,
        recordForEdit,
        handleCloseCreateEditDialog,
        children,
        handleCloseDeleteDialog,
        selectedCampaignId
    } = props;

    const {usersDispatch, usersState} = useContext(GlobalContext);
    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);
    const {campaignDispatch, campaignState} = useContext(GlobalContext);

    const {usersList} = usersState;
    const {tsAccountsList} = tsAccountsState;
    const {campaigns} = campaignState;

    useEffect(() => {
        getCampaigns()(campaignDispatch);
    }, [campaignDispatch]);

    useEffect(() => {
        getUsers()(usersDispatch);
    }, [usersDispatch]);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    const handleChangeTsAccount = (event) => {
        setTsAccountId(event.target.value || '');
    };

    const handleChangeUser = (event) => {
        setUserId(event.target.value);
    };
    const userDropdownSelected = () => {
        setUserList(true)
    }
    const handleChangeTaskMessage = (taskMessage) => {
        if (!taskMessage) {
            setNoTsAccountSelected(true)
            setDisableSaveButton(true)
        }
        if (typeof taskMessage === "string") {
            setNoTsAccountSelected(false)
            setDisableSaveButton(false)
            setTaskMessage(taskMessage)
            setTaskMessageObj({label: taskMessage})
        } else {
            setTaskMessage(taskMessage.label);
            setTaskMessageObj(taskMessage);
        }
    };

    const handleChangeCampaign = (selectedCampaign) => {
        if (!selectedCampaign) {
            return
        }
        setCampaignId(selectedCampaign.id)
        setSelectedCampaign(selectedCampaign)
    };

    const handleChangeTaskStatus = (selectedStatus) => {
        if (!selectedStatus) {
            return
        }
        setTaskStatus(selectedStatus.label)
        setTaskStatusObj(selectedStatus)
    };

    const taskMessages = []
    const taskStatuses = [{label: "CREATED"}, {label: "IN_PROGRESS"}, {label: "COMPLETED"}]

    const [taskMessage, setTaskMessage] = useState("")
    const [taskMessageObj, setTaskMessageObj] = useState({label: ""})
    const [taskStatus, setTaskStatus] = useState(taskStatuses[0].label)
    const [taskStatusObj, setTaskStatusObj] = useState(taskStatuses[0])
    const [tsAccountId, setTsAccountId] = useState("");
    const [userId, setUserId] = useState("");
    const [remindOn, setRemindOn] = useState("")
    const [campaignId, setCampaignId] = useState("")
    const [selectedCampaign, setSelectedCampaign] = useState("")
    const [userList, setUserList] = useState(false)
    const [noTsAccountSelected, setNoTsAccountSelected] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(false)

    useEffect(() => {
        if (recordForEdit) {
            const {ts_account_id, remind_on, user_id, task_message, campaign_id, status} = recordForEdit;

            if (ts_account_id) {
                setTsAccountId(ts_account_id);
            }

            if (remind_on) {
                setRemindOn(remind_on)
            }

            if (user_id) {
                setUserId(user_id)
            }

            if (task_message) {
                setTaskMessage(task_message)
                setTaskMessageObj({label: task_message})
            }

            if (status) {
                setTaskStatus(status)
                setTaskStatusObj({label: status})
            }

            if (campaign_id) {
                setCampaignId(campaign_id)
                const selectedCampaign = campaigns.data.find(item => item.id === campaign_id);
                setSelectedCampaign(selectedCampaign)
            }
        }
        if (selectedCampaignId) {
            const selectedCampaign = campaigns.data.find(item => item.remote_id === selectedCampaignId);
            if (selectedCampaign) {
                setCampaignId(selectedCampaign.id)
                setSelectedCampaign(selectedCampaign)
            }
        }
    }, [recordForEdit, campaigns, selectedCampaignId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {};
        if (recordForEdit) {
            data = {
                id: recordForEdit.id,
                last_sent_on: recordForEdit.last_sent_on,
                created_on: recordForEdit.created_on,
                created_by: recordForEdit.created_by,
                completed_on: recordForEdit.completed_on
            };
        }

        if (taskMessage) {
            data.task_message = taskMessage
        }

        if (taskStatus) {
            data.status = taskStatus
        }

        data.ts_account_id = tsAccountId

        if (remindOn !== "") {
            const dateTime = new Date(remindOn);
            data.remind_on = localToUTC(dateTime);
        }

        if (userId !== "") {
            data.user_id = userId
        }

        if (campaignId !== "") {
            data.campaign_id = campaignId
        }

        addOrEdit(data);
    };

    const getTimezoneOffset = (value) => {
        return value.getTimezoneOffset() * 60000;
    }

    const localToUTC = (dateTime) => {
        return new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
    };

    const handleDateChange = (date) => {
        setRemindOn(date);
    };

    let errorMessage = 'An error occurred: ' + apiError

    if (apiError && apiError.errorType === 'VALIDATION_ERROR') {
        errorMessage = 'Please fill the following fields: ' + apiError.errors
    }

    return (
        <Container component="main" maxWidth="xs">
            {recordForEdit && recordCreated === 'update' ? (
                <Typography component="h1" variant="h5">
                    Update Task
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create Task
                </Typography>
            )}
            {apiError && <Alert severity="error">{errorMessage}</Alert>}
            <Box
                sx={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}} id="form">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={campaigns.data}
                                getOptionLabel={option => option.remote_id + " (" + option.name + ")"}
                                onChange={(event, newValue) => {
                                    handleChangeCampaign(newValue);
                                }}
                                // isOptionEqualToValue={(option, value) => option.label === value.label}
                                value={selectedCampaign}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField {...params} label="Campaign Remote ID"/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={taskMessages}
                                autoSelect
                                freeSolo
                                onChange={(event, newValue) => {
                                    handleChangeTaskMessage(newValue);
                                }}
                                onInputChange={() => {
                                    setDisableSaveButton(false);
                                    setNoTsAccountSelected(false)
                                }}
                                // isOptionEqualToValue={(option, value) => option.label === value.label}
                                value={taskMessageObj}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField {...params} label="Task Message"/>}
                            />
                        </Grid>
                        {noTsAccountSelected &&
                        <div style={{paddingLeft: '20px', color: 'red'}}>Please add a Task Message</div>}
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={taskStatuses}
                                onChange={(event, newValue) => {
                                    handleChangeTaskStatus(newValue);
                                }}
                                // isOptionEqualToValue={(option, value) => option.label === value.label}
                                value={taskStatusObj}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField {...params} label="Campaign Status"/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TrafficSourceAccountDropdown
                                tsAccount={tsAccountId}
                                data={tsAccountsList.data}
                                width={"100%"}
                                handleChangeTsAccount={handleChangeTsAccount}
                            />
                        </Grid>
                        {" "}
                        <Grid item xs={12}
                              onMouseDown={userDropdownSelected}
                        >
                            <UsersDropdown
                                userId={userId}
                                tsAccountId={tsAccountId}
                                data={usersList.data}
                                width={"100%"}
                                handleChangeUser={handleChangeUser}
                            />
                        </Grid>
                        {userList && !tsAccountId && (
                            <div style={{paddingLeft: '20px', color: 'red'}}>Please select a TS account first.</div>)}
                        {" "}
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    componentsProps={{textField: {variant: 'outlined'}}}
                                    label="Remind On (UTC)"
                                    value={dayjs(remindOn)}
                                    onChange={handleDateChange}
                                    disablePast={true}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                        disabled={disableSaveButton}
                    >
                        Save
                    </Button>
                </Box>

            </Box>
            {children}
        </Container>
    );
}

export {CreateEditTask};
