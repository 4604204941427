import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GlobalContext } from "../context/Provider";
import LoadingSpinner from "../components/LoadingSpinner";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { darken, lighten } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { getDomains, fetchDomains } from "../context/actions/domains/domains";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function ListDomains() {

    let columns;
    columns = [
        { field: "name", headerName: "Domain Name", minWidth: 300 },
        { field: "id", headerName: "Id", width: 300 },
        { field: "status", headerName: "Status", minWidth: 150 },
        { field: "paused", headerName: "Paused", minWidth: 150 },
    ];

    const { domainsDispatch, domainsState } = useContext(GlobalContext);
    const [fetchIsLoading, setFetchIsLoading] = useState(false);

    const {
        domainsList
    } = domainsState;

    useEffect(() => {
        getDomains()(domainsDispatch);
    }, [domainsDispatch]);
    
    const handleFetchDomains = () => {
        setFetchIsLoading(true);
        fetchDomains(() => {
            setFetchIsLoading(false);
        })(domainsDispatch);
    };

    return (
        <>
            <Typography component="h1" variant="h5" sx={{ mb: 5 }}>
                All Domains
            </Typography>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={handleFetchDomains} disabled={fetchIsLoading} sx={{marginBottom: '10px'}}>
                    {fetchIsLoading ? 'Refreshing...' : 'Refresh'}
                </Button>
            </Grid>
            {domainsList.data && !domainsList.loading ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--new-task": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.success.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--due-task": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid rows={domainsList.data} columns={columns} autoHeight />
                </Box>
            ) : (
                <LoadingSpinner />
            )}

        </>
    )
}

export { ListDomains };
