export const initialBiddingState = {
    biddingReports: {
        loading: false,
        data: [],
        error: false,
        message: null,
    },
    createSchedulingBid: {
        loading: false,
        data: null,
        error: false,
        message: null,

    },
    deleteBidding: {
        loading: false,
        data: [],
        error: null,
    },
};
