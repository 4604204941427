import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {AlertDialog} from "../components/dialog";
import Grid from "@mui/material/Grid";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {CreateBlockedSiteZone} from "./createBlockedSiteZone";
import {
    createBlackListSites,
    deleteBlackListSite,
    getBlackListSites,
} from "../context/actions/blackListSite/blackList";
import {getTrafficSource} from "../context/actions/traffic/trafficSource";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import LoadingSpinner from "../components/LoadingSpinner";

function GlobalSiteZoneList() {
    const columns = [
        {field: "site_hostname", headerName: "Site host name", width: 300},

        {
            field: "ts_account_id",
            headerName: "Ts Account Name",
            width: 200,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {
            field: "traffic_source_id",
            headerName: "Traffic Source",
            width: 150,
            renderCell: (cellValues) => {
                let user = trafficSourceList.data.find(
                    (x) => x.id === cellValues.value
                );
                return <>{user?.name}</>;
            },
        },
        {field: "blacklisted_by", headerName: "Blacklisted by", width: 150},
        {field: "blacklisted_on", headerName: "Blacklisted on", width: 150},

        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <DeleteIcon
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const {
        blackListZoneState,
        blackListZoneDispatch,
        tsAccountsDispatch,
        tsAccountsState,
    } = useContext(GlobalContext);
    const {trafficSourceList, tsAccountsList} = tsAccountsState;
    const {
        blackListSites: {data, loading},
    } = blackListZoneState;

    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleClick = (event, cellValues) => {
        setBlackList(cellValues.row.site_hostname);
        setId(cellValues.row.id);
        setOpen(true);
    };

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [tsAccount, setTsAccount] = useState("");

    const [blackList, setBlackList] = useState("");
    const [id, setId] = useState("");
    const [selectedBlackListZone, setBlackListZone] = useState(null);
    const [trafficSource, setTrafficSource] = useState("");

    useEffect(() => {
        getBlackListSites(tsAccount, trafficSource)(blackListZoneDispatch);
    }, [blackListZoneDispatch, trafficSource, tsAccount]);
    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);
    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);
    const handleClose = () => {
        setOpen(false);
        setBlackList("");
        setId("");
    };

    const handleDelete = () => {
        deleteBlackListSite(id)(blackListZoneDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setBlackListZone(null);
    };

    const handleCloseCreateEditDialog = () => {
        setOpenCreateEditDialog(false);
        setBlackListZone(null);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const create = (props) => {
        setOpenCreateEditDialog(false);
        setBlackListZone(null);
        createBlackListSites(props)(blackListZoneDispatch);
    };

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };
    const handleChangeTs = (event) => {
        setTrafficSource(event.target.value);
    };
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Blacklist Site{" "}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button
                        sx={{mb: 5}}
                        variant="contained"
                        color="success"
                        onClick={handleOpenCreateDialog}
                    >
                        Create Blacklist Site
                    </Button>
                </Grid>
                <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                    <TrafficSourceAccountDropdown
                        tsAccount={tsAccount}
                        data={tsAccountsList.data}
                        width={300}
                        handleChangeTsAccount={handleChangeTsAccount}
                    />
                </Grid>
                <Grid item xs={4} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <FormControl fullWidth>
                        <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                        <Select
                            required
                            labelId="ts-account-label"
                            id="ts-account-label-select"
                            value={trafficSource}
                            label="Traffic Source"
                            onChange={handleChangeTs}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {(trafficSourceList.data || []).map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateBlockedSiteZone
                    create={create}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                    tsAccounts={tsAccountsList.data}
                    trafficSourceList={trafficSourceList.data}
                    isZone={false}
                >
                    <AlertDialog
                        open={openDeleteDialog}
                        handleClose={alertInCreateDialog}
                        name={selectedBlackListZone?.name}
                        handleDelete={handleDelete}
                    />
                </CreateBlockedSiteZone>
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={blackList}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {GlobalSiteZoneList};
