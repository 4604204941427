import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    ASSIGN_ALGORITHM_TO_CAMPAIGNS_ERROR,
    ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING,
    ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS,
    ASSIGN_ALGORITHMS_ERROR,
    ASSIGN_ALGORITHMS_LOADING,
    ASSIGN_ALGORITHMS_SUCCESS,
    CHANGE_BID_CAMPAIGN_ERROR,
    CHANGE_BID_CAMPAIGN_LOADING,
    CHANGE_BID_CAMPAIGN_SUCCESS,
    CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR,
    CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING,
    CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS,
    CHECK_AUTO_BIDDING_DATA_ERROR,
    CHECK_AUTO_BIDDING_DATA_LOADING,
    CHECK_AUTO_BIDDING_DATA_SUCCESS,
    CREATE_ALGO_BIDDING_ERROR,
    CREATE_ALGO_BIDDING_LOADING,
    CREATE_ALGO_BIDDING_SUCCESS,
    CREATE_CAMPAIGN_ERROR,
    CREATE_CAMPAIGN_LOAD,
    CREATE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_ERROR,
    DELETE_CAMPAIGN_LOADING,
    DELETE_CAMPAIGN_SUCCESS,
    DUPLICATE_CAMPAIGN_ERROR,
    DUPLICATE_CAMPAIGN_LOADING,
    DUPLICATE_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_BY_ID_ERROR,
    GET_CAMPAIGN_BY_ID_LOADING,
    GET_CAMPAIGN_BY_ID_SUCCESS,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING,
    GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS,
    GET_CAMPAIGN_TREE_ERROR,
    GET_CAMPAIGN_TREE_LOADING,
    GET_CAMPAIGN_TREE_SUCCESS,
    GET_CAMPAIGNS_FOR_ALGORITHM_ERROR,
    GET_CAMPAIGNS_FOR_ALGORITHM_LOADING,
    GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS,
    GET_MANUAL_BIDDING_ERROR,
    GET_MANUAL_BIDDING_LOADING,
    GET_MANUAL_BIDDING_SUCCESS,
    GET_REMOTE_DATA_CAMPAIGN_ERROR,
    GET_REMOTE_DATA_CAMPAIGN_LOADING,
    GET_REMOTE_DATA_CAMPAIGN_SUCCESS,
    GET_SEED_CAMPAIGN_ERROR,
    GET_SEED_CAMPAIGN_LOAD,
    GET_SEED_CAMPAIGN_SUCCESS,
    PAUSE_CAMPAIGN_ERROR,
    PAUSE_CAMPAIGN_LOADING,
    PAUSE_CAMPAIGN_SUCCESS,
    RESET_ASSIGN_ALGORITHMS_LOADING,
    RESET_ERROR_CAMPAIGN_ERROR,
    RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR,
    RESET_ERROR_MESSAGE_TREE_ERROR,
    RESET_REMOTE_DATA_CAMPAIGN,
    UPDATE_CAMPAIGN_ERROR,
    UPDATE_CAMPAIGN_LOADING,
    UPDATE_CAMPAIGN_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createCampaignAction = (campaign, assignAlgo) => (dispatch) => {
    dispatch({
        type: CREATE_CAMPAIGN_LOAD,
    });

    axiosInstance()
        .put(`/api/${version}/campaign`, campaign)
        .then((res) => {
            dispatch({
                type: CREATE_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
            assignAlgorithms(res.data.id, assignAlgo);
        })
        .catch((err) => {
            dispatch({
                type: CREATE_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

const assignAlgorithms = (id, assignAlgo, algorithm_type_id) => {
    const algo_type_id = algorithm_type_id || undefined;
    axiosInstance()
        .put(`/api/${version}/campaign/${id}/algorithms`, assignAlgo, algo_type_id)
        .then(() => {
        })
        .catch((err) => {
        });
};
export const assignAlgorithmsBidding =
    (id, assignAlgo, algorithm_type_id) => (dispatch) => {
        dispatch({
            type: CREATE_ALGO_BIDDING_LOADING,
        });
        const algo_type_id = algorithm_type_id || null;
        axiosInstance()
            .put(
                `/api/${version}/campaign/${id}/algorithm_for_type`,
                assignAlgo,
                algo_type_id
            )
            .then((res) => {
                dispatch({
                    type: CREATE_ALGO_BIDDING_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: CREATE_ALGO_BIDDING_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const updateCampaignAction =
    (campaign, assignAlgo, algorithm_type_id) => (dispatch) => {
        dispatch({
            type: UPDATE_CAMPAIGN_LOADING,
        });

        axiosInstance()
            .put(`/api/${version}/campaign`, campaign)
            .then((res) => {
                dispatch({
                    type: UPDATE_CAMPAIGN_SUCCESS,
                    payload: res.data,
                });
                assignAlgorithms(campaign.id, assignAlgo, algorithm_type_id);
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_CAMPAIGN_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const duplicateCampaign = (campaign, tsAccount) => (dispatch) => {
    const tsId = tsAccount ? tsAccount : null;
    const campaignId = campaign ? campaign : null;
    dispatch({
        type: DUPLICATE_CAMPAIGN_LOADING,
    });

    axiosInstance()
        .put(
            `/api/${version}/campaign/duplicate?campaign_id=${campaignId}&ts_account_id=${tsId}`,
            {}
        )
        .then((res) => {
            dispatch({
                type: DUPLICATE_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: DUPLICATE_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const changeBid = (campaign, tsAccount, bid) => (dispatch) => {
    dispatch({
        type: CHANGE_BID_CAMPAIGN_LOADING,
    });

    axiosInstance()
        .put(
            `/api/${version}/campaign/bid?campaign_id=${campaign}&ts_account_id=${tsAccount}&bid=${bid}`,
            {}
        )
        .then((res) => {
            dispatch({
                type: CHANGE_BID_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CHANGE_BID_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const changeMaxDailyBudget =
    (campaign, tsAccount, daily) => (dispatch) => {
        const daily_budget_reset = parseFloat(daily);
        dispatch({
            type: CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING,
        });

        axiosInstance()
            .put(
                `/api/${version}/campaign/max_daily_budget?campaign_id=${campaign}&ts_account_id=${tsAccount}&max_daily_budget=${daily_budget_reset}`,
                {}
            )
            .then((res) => {
                dispatch({
                    type: CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const pauseCampaign = (campaign, tsAccount) => (dispatch) => {
    const tsId = tsAccount ? tsAccount : null;
    const campaignId = campaign ? campaign : null;
    dispatch({
        type: PAUSE_CAMPAIGN_LOADING,
    });

    axiosInstance()
        .put(
            `/api/${version}/campaign/pause?campaign_id=${campaignId}&ts_account_id=${tsId}`,
            {}
        )
        .then((res) => {
            dispatch({
                type: PAUSE_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: PAUSE_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const resumeCampaign = (campaign, tsAccount) => (dispatch) => {
    const tsId = tsAccount ? tsAccount : null;
    const campaignId = campaign ? campaign : null;
    dispatch({
        type: PAUSE_CAMPAIGN_LOADING,
    });

    axiosInstance()
        .put(
            `/api/${version}/campaign/restart?campaign_id=${campaignId}&ts_account_id=${tsId}`,
            {}
        )
        .then((res) => {
            dispatch({
                type: PAUSE_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: PAUSE_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getCampaigns =
    (tsAccount, costModel, campaignType, forAlgorithms) => (dispatch) => {
        const ts_account_id = tsAccount ? tsAccount : null;
        const cost_model = costModel ? costModel : null;
        const campaign_type = campaignType ? campaignType : null;
        const for_algorithms = forAlgorithms ? forAlgorithms : false;
        dispatch({
            type: GET_SEED_CAMPAIGN_LOAD,
        });
        axiosInstance()
            .get(`/api/${version}/campaign`, {
                params: {
                    ts_account_id: ts_account_id,
                    cost_model: cost_model,
                    campaign_type: campaign_type,
                    for_algorithms: for_algorithms
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_SEED_CAMPAIGN_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_SEED_CAMPAIGN_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const getCampaignTree = (tsAccount, parent) => (dispatch) => {
    const id = tsAccount ? tsAccount : null;
    const parentId = parent ? parent : null;
    dispatch({
        type: GET_CAMPAIGN_TREE_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/campaign/tree/`, {
            params: {ts_account_id: id, parent_remote_id: parentId},
        })
        .then((res) => {
            dispatch({
                type: GET_CAMPAIGN_TREE_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_CAMPAIGN_TREE_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};
export const getAssignAlgorithms = (campaignId) => (dispatch) => {
    const id = campaignId ? campaignId : null;
    dispatch({
        type: ASSIGN_ALGORITHMS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/campaign/${id}/algorithms`)
        .then((res) => {
            dispatch({
                type: ASSIGN_ALGORITHMS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: ASSIGN_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteSeedCampaign = (id) => (dispatch) => {
    dispatch({
        type: DELETE_CAMPAIGN_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/campaign/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_CAMPAIGN_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};

export const getRemoteDataCampaign = (tsAccount, campaign_id) => (dispatch) => {
    const id = tsAccount ? tsAccount : null;
    const campaignId = campaign_id ? campaign_id : null;

    dispatch({
        type: GET_REMOTE_DATA_CAMPAIGN_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/campaign/remote_data`, {
            params: {ts_account_id: id, campaign_id: campaignId},
        })
        .then((res) => {
            dispatch({
                type: GET_REMOTE_DATA_CAMPAIGN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_REMOTE_DATA_CAMPAIGN_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};

export const assignAlgorithmToCampaigns =
    (campaignIds, id, tsAccount) => (dispatch) => {
        dispatch({
            type: ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING,
        });

        axiosInstance()
            .put(
                `/api/${version}/campaign/algorithm/${id}?ts_account_id=${tsAccount}`,
                campaignIds
            )
            .then((res) => {
                dispatch({
                    type: ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: ASSIGN_ALGORITHM_TO_CAMPAIGNS_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const getCampaignsForAlgorithm = (id) => (dispatch) => {
    dispatch({
        type: GET_CAMPAIGNS_FOR_ALGORITHM_LOADING,
    });

    axiosInstance()
        .get(`/api/${version}/campaign/algorithm/${id}`)
        .then((res) => {
            dispatch({
                type: GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_CAMPAIGNS_FOR_ALGORITHM_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getCampaignById = (id) => (dispatch) => {
    dispatch({
        type: GET_CAMPAIGN_BY_ID_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/campaign/${id}`)
        .then((res) => {
            dispatch({
                type: GET_CAMPAIGN_BY_ID_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_CAMPAIGN_BY_ID_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getCampaignDefaultAlgorithms =
    (costModel, campaignType, tsAccount) => (dispatch) => {
        dispatch({
            type: GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING,
        });
        axiosInstance()
            .get(`/api/${version}/campaign/default_algorithms`, {
                params: {
                    cost_model: costModel,
                    campaign_type: campaignType,
                    ts_account_id: tsAccount,
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const resetRemoteData = () => (dispatch) => {
    dispatch({
        type: RESET_REMOTE_DATA_CAMPAIGN,
        payload: null,
    });
};

export const resetAssignAlgorithmsCampaign = () => (dispatch) => {
    dispatch({
        type: RESET_ASSIGN_ALGORITHMS_LOADING,
        payload: [],
    });
};

export const resetMessageRemoteData = () => (dispatch) => {
    dispatch({
        type: RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR,
        payload: null,
    });
};

export const resetMessageCampaignTree = () => (dispatch) => {
    dispatch({
        type: RESET_ERROR_MESSAGE_TREE_ERROR,
        payload: null,
    });
};
export const resetErrorDataCampaign = () => (dispatch) => {
    dispatch({
        type: RESET_ERROR_CAMPAIGN_ERROR,
        payload: null,
    });
};
export const getManualBiddingReports = (campaignId) => (dispatch) => {
    dispatch({
        type: GET_MANUAL_BIDDING_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/report/manual-bidding/${campaignId}`)
        .then((res) => {
            dispatch({
                type: GET_MANUAL_BIDDING_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_MANUAL_BIDDING_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
export const checkAutoBiddingEnabled = (campaign_id, typeId) => (dispatch) => {
    dispatch({
        type: CHECK_AUTO_BIDDING_DATA_LOADING,
    });

    axiosInstance()
        .get(`/api/${version}/campaign/${campaign_id}/algorithm_type/${typeId}`)
        .then((res) => {
            dispatch({
                type: CHECK_AUTO_BIDDING_DATA_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CHECK_AUTO_BIDDING_DATA_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
