import {CustomToolbar} from "../../components/customToolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import {GlobalContext} from "../../context/Provider";
import {useContext, useEffect} from "react";
import Box from "@mui/material/Box";
import {dateToStr, getBackgroundColor, getHoverBackgroundColor} from "./common";
import {DataGrid} from "@mui/x-data-grid";
import {getReport} from "../../context/actions/reports/reports";

export default function ReportTable(props) {
    const {
        columns,
        showAssignedOnly,
        dateRange,
        tsAccountId,
        campaignId,
        groupBy,
    } = props;

    const {
        reportsState,
        reportsDispatch,
    } = useContext(GlobalContext);

    const {
        report: {data, loading},
    } = reportsState;

    useEffect(() => {
        getReport(
            dateToStr(dateRange.from),
            dateToStr(dateRange.to),
            tsAccountId,
            showAssignedOnly,
            groupBy,
            campaignId,
        )(reportsDispatch);
    }, [campaignId, groupBy, dateRange, reportsDispatch, showAssignedOnly, tsAccountId]);

    return !loading && data && data.reports ? (
        <Box>
            <Box
                sx={{
                    minHeight: 100,
                    width: "100%",
                    "& .super-app-theme--positive": {
                        bgcolor: (theme) =>
                            getBackgroundColor(
                                theme.palette.success.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    "& .super-app-theme--neutral": {
                        bgcolor: (theme) =>
                            getBackgroundColor(
                                theme.palette.warning.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    "& .super-app-theme--negative": {
                        bgcolor: (theme) =>
                            getBackgroundColor(
                                theme.palette.error.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                }}
            >
                <DataGrid
                    rows={data.reports}
                    rowCount={data.total}
                    columns={columns}
                    getRowClassName={(params) => {
                        if (params.row.profit < 0) {
                            return `super-app-theme--negative`;
                        }
                        if (params.row.profit > 0) {
                            return `super-app-theme--positive`;
                        }
                    }}
                    density="compact"
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    pagination={true}
                    autoHeight={true}
                />
            </Box>
        </Box>
    ) : (
        <LoadingSpinner/>
    )
}
