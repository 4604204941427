import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_BIDDING_BY_ID_ERROR,
    CREATE_BIDDING_BY_ID_LOADING,
    CREATE_BIDDING_BY_ID_SUCCESS,
    DELETE_BID_SCHEDULING_LOADING,
    DELETE_BID_SCHEDULING_SUCCESS,
    UPDATE_BID_SCHEDULING_ERROR,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getSchedulingByID = (id) => (dispatch) => {
    dispatch({
        type: CREATE_BIDDING_BY_ID_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/bid_scheduling/campaign/${id}`)
        .then((res) => {
            dispatch({
                type: CREATE_BIDDING_BY_ID_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_BIDDING_BY_ID_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const createSchedulingAction = async (scheduling, campaign_id) => {
    return await axiosInstance()
        .put(
            `/api/${version}/bid_scheduling/?campaign_id=${campaign_id}`,
            scheduling
        )
        .catch(function (error) {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return error.request;
            } else {
                return error.message;
            }
        });
};
export const deleteBiddingById = (schedulingId) => (dispatch) => {
    dispatch({
        type: DELETE_BID_SCHEDULING_LOADING,
    });

    axiosInstance()
        .delete(`/api/${version}/bid_scheduling/${schedulingId}`)
        .then((res) => {
            dispatch({
                type: DELETE_BID_SCHEDULING_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_BID_SCHEDULING_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
                loading: false,
            });
        });
};
