import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../context/Provider";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {getPerformanceMonitor} from "../context/actions/reports/reports";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";
import MaterialUIPickers from "../components/MaterialUIPickers";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import LoadingSpinner from "../components/LoadingSpinner";

function PerformanceMonitor() {
    const columns = [
        {
            field: "ts_account_id",
            headerName: "TS Account",
            type: "text",
            headerAlign: "left",
            align: "left",
            width: 100,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
            filterable: false,
        },
        {
            field: "year",
            headerName: "Year",
            width: 80,
            type: "text",
            headerAlign: "left",
            align: "left",
        },

        {
            field: "week",
            headerName: "Week",
            width: 80,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "num_created_campaigns",
            headerName: "Created Campaigns",
            width: 160,
            type: "number",
            headerAlign: "left",
            align: "left",
            valueFormatter: (params) => {
                return params ? (params.value ? params.value : 0) : 0;
            },
        },
        {
            field: "num_avg_active_variations",
            headerName: "Avg Num Variations",
            width: 160,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "num_added_variations_active_camp",
            headerName: "Added Variations",
            width: 150,
            type: "number",
            headerAlign: "left",
            align: "left",
            valueFormatter: (params) => {
                return params ? (params.value ? params.value : 0) : 0;
            },
        },
        {
            field: "num_active",
            headerName: "Active campaigns",
            width: 150,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "num_paused",
            headerName: "Paused campaigns",
            width: 150,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "num_campaigns_with_one_variation",
            headerName: "Camp. with 1 Variation",
            width: 180,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "avg_spend",
            headerName: "Avg Spend",
            width: 100,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "total_cost",
            headerName: "Total Spend",
            width: 100,
            type: "number",
            headerAlign: "left",
            align: "left",
        },
    ];
    const {tsAccountsDispatch, tsAccountsState, reportsDispatch, reportsState} =
        useContext(GlobalContext);
    const {tsAccountsList} = tsAccountsState;

    const today = new Date();
    const startDate = new Date(new Date().getFullYear(), 0, 1);

    const [dateFrom, setDateFrom] = React.useState(startDate);
    const [dateTo, setDateTo] = React.useState(today);
    const [tsAccount, setTsAccount] = useState("");

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };
    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        let offset = dateFrom.getTimezoneOffset();
        let yourDate = new Date(dateFrom.getTime() - offset * 60 * 1000);

        const dateFromParam = yourDate.toISOString().split("T")[0];

        offset = dateTo.getTimezoneOffset();
        yourDate = new Date(dateTo.getTime() - offset * 60 * 1000);
        const dateToParam = yourDate.toISOString().split("T")[0];
        getPerformanceMonitor(
            dateFromParam,
            dateToParam,
            tsAccount
        )(reportsDispatch);
    }, [dateTo, dateFrom, tsAccount, reportsDispatch]);

    const {
        performanceMonitor: {data, loading},
    } = reportsState;

    const handleDateFromChange = (date) => {
        if (date > dateTo) {
            setDateFrom(dateTo);
            return;
        }
        setDateFrom(date);
    };
    const handleDateToChange = (date) => {
        if (date < dateFrom) {
            setDateTo(dateFrom);
            return;
        }
        setDateTo(date);
    };
    const tableData1 = data?.map((item, index) => {
        return {
            ...item,
            id: index,
            ts_account: tsAccountsList.data.find((x) => x.id === item.ts_account_id)
                .name,
        };
    });

    return (
        <>
            <Typography component="h1" variant="h5" sx={{ mb: 5}}>
                Performance Monitor
            </Typography>

            <Grid container spacing={2} sx={{
                mb: 1, display: "flex", '@media (max-width: 800px)': {
                    flexDirection: "column",
                    alignItems: "center"
                },
            }}>
                <Grid item xs={4} justifyContent="inherit" sx={{ display: "flex", '@media (max-width: 800px)': {maxWidth: "50%"} }}>
                    <TrafficSourceAccountDropdown
                        tsAccount={tsAccount}
                        data={tsAccountsList.data}
                        width={300}
                        handleChangeTsAccount={handleChangeTsAccount}
                    />
                </Grid>
                <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "50%"}}}>
                    <MaterialUIPickers
                        handleDateChange={handleDateFromChange}
                        value={dateFrom}
                        label="From"
                    />
                </Grid>
                <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "50%"}}}>
                    <MaterialUIPickers
                        handleDateChange={handleDateToChange}
                        value={dateTo}
                        label="To"
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                ></Grid>
            </Grid>

            {tableData1 && !loading ? (
                <div style={{ width: "100%" }}>
                    <DataGrid rows={tableData1} columns={columns} autoHeight={true} />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
}

export {PerformanceMonitor};
