import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    GET_BID_HISTORY_REPORT_ERROR,
    GET_BID_HISTORY_REPORT_LOADING,
    GET_BID_HISTORY_REPORT_SUCCESS,
    GET_ERRORS_ERROR,
    GET_ERRORS_LOADING,
    GET_ERRORS_SUCCESS,
    GET_PERFORMANCE_MONITOR_REPORT_ERROR,
    GET_PERFORMANCE_MONITOR_REPORT_LOADING,
    GET_PERFORMANCE_MONITOR_REPORT_SUCCESS,
    GET_REPORT_ERROR, GET_REPORT_LOADING, GET_REPORT_SUCCESS,
    GET_SUCCESSES_ERROR,
    GET_SUCCESSES_LOADING,
    GET_SUCCESSES_SUCCESS,
    GET_TOTAL_REPORT_ERROR,
    GET_TOTAL_REPORT_LOADING,
    GET_TOTAL_REPORT_SUCCESS,
    GET_VARIATIONS_HISTORY_REPORT_ERROR,
    GET_VARIATIONS_HISTORY_REPORT_LOADING,
    GET_VARIATIONS_HISTORY_REPORT_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";
import {COMPLETE_REPORT_GROUP_BY} from "../../../reports/components/common";

const createReportQueryFn = (loadingType, successType, errorType) => {
    return (dateFrom, dateTo, tsAccount, assignedOnly, groupBy, campaignId) => (dispatch) => {
        const id = tsAccount ? tsAccount : null;
        let reportType;
        switch (groupBy) {
            case COMPLETE_REPORT_GROUP_BY.SITE:
                reportType = "site"
                break;
            case COMPLETE_REPORT_GROUP_BY.ZONE:
                reportType = "zone"
                break;
            case COMPLETE_REPORT_GROUP_BY.COUNTRY:
                reportType = "country"
                break;
            case COMPLETE_REPORT_GROUP_BY.CAMPAIGN:
            default:
                reportType = "campaign"
                break;
        }
        dispatch({
            type: loadingType,
        });
        axiosInstance()
            .get(`/api/${version}/report/${reportType}`, {
                params: {
                    date_from: dateFrom,
                    date_to: dateTo,
                    ts_account_id: id,
                    assigned_only: assignedOnly,
                    campaign_id: campaignId,
                },
            })
            .then((res) => {
                dispatch({
                    type: successType,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: errorType,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };
}

export const getReport = createReportQueryFn(
    GET_REPORT_LOADING, GET_REPORT_SUCCESS, GET_REPORT_ERROR
)

export const getTotalReport = (dateFrom, dateTo, tsAccount) => (dispatch) => {
    const id = tsAccount ? tsAccount : null;

    dispatch({
        type: GET_TOTAL_REPORT_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/report/total`, {
            params: {date_from: dateFrom, date_to: dateTo, ts_account_id: id},
        })
        .then((res) => {
            dispatch({
                type: GET_TOTAL_REPORT_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_TOTAL_REPORT_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getBidHistory = (dateFrom, dateTo, tsAccount) => (dispatch) => {
    const id = tsAccount ? tsAccount : null;

    dispatch({
        type: GET_BID_HISTORY_REPORT_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/report/bid_history`, {
            params: {date_from: dateFrom, date_to: dateTo, ts_account_id: id},
        })
        .then((res) => {
            dispatch({
                type: GET_BID_HISTORY_REPORT_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_BID_HISTORY_REPORT_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
export const getVariationsHistory =
    (dateFrom, dateTo, tsAccount) => (dispatch) => {
        const id = tsAccount ? tsAccount : null;

        dispatch({
            type: GET_VARIATIONS_HISTORY_REPORT_LOADING,
        });
        axiosInstance()
            .get(`/api/${version}/report/variations_history`, {
                params: {date_from: dateFrom, date_to: dateTo, ts_account_id: id},
            })
            .then((res) => {
                dispatch({
                    type: GET_VARIATIONS_HISTORY_REPORT_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_VARIATIONS_HISTORY_REPORT_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const getSuccessReport =
    (dateTo, tsAccount, campaignId, runner, gridOffset, gridLimit) =>
        (dispatch) => {
            const id = tsAccount ? tsAccount : null;
            const campaignExternalId = campaignId ? campaignId : null;
            const runnerType = runner ? runner : null;
            const offset = gridOffset ? gridOffset : null;
            const limit = gridLimit ? gridLimit : null;
            dispatch({
                type: GET_SUCCESSES_LOADING,
            });
            axiosInstance()
                .get(`/api/${version}/report/successes`, {
                    params: {
                        report_date: dateTo,
                        ts_account_id: id,
                        campaign_external_id: campaignExternalId,
                        runner_type: runnerType,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then((res) => {
                    dispatch({
                        type: GET_SUCCESSES_SUCCESS,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: GET_SUCCESSES_ERROR,
                        payload: err.response ? err.response.data : "COULD NOT CONNECT",
                    });
                });
        };

export const getErrorsReport =
    (dateTo, tsAccount, campaignId, runner, gridOffset, gridLimit) =>
        (dispatch) => {
            const id = tsAccount ? tsAccount : null;
            const campaignExternalId = campaignId ? campaignId : null;
            const runnerType = runner ? runner : null;
            const offset = gridOffset ? gridOffset : null;
            const limit = gridLimit ? gridLimit : null;
            dispatch({
                type: GET_ERRORS_LOADING,
            });
            axiosInstance()
                .get(`/api/${version}/report/errors`, {
                    params: {
                        report_date: dateTo,
                        ts_account_id: id,
                        campaign_external_id: campaignExternalId,
                        runner_type: runnerType,
                        offset: offset,
                        limit: limit,
                        exec_status: "error_in_calc",
                    },
                })
                .then((res) => {
                    dispatch({
                        type: GET_ERRORS_SUCCESS,
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: GET_ERRORS_ERROR,
                        payload: err.response ? err.response.data : "COULD NOT CONNECT",
                    });
                });
        };

export const getPerformanceMonitor =
    (dateFrom, dateTo, tsAccount) => (dispatch) => {
        const id = tsAccount ? tsAccount : null;
        dispatch({
            type: GET_PERFORMANCE_MONITOR_REPORT_LOADING,
        });
        axiosInstance()
            .get(`/api/${version}/report/performance_monitor`, {
                params: {date_from: dateFrom, date_to: dateTo, ts_account_id: id},
            })
            .then((res) => {
                dispatch({
                    type: GET_PERFORMANCE_MONITOR_REPORT_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_PERFORMANCE_MONITOR_REPORT_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };
