import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import {SlackDataDropdown} from "../components/slackDataDropdown";
import { Alert } from "@mui/material";

function CreateEditUser(props) {
    const {
        recordCreated,
        apiError,
        addOrEdit,
        recordForEdit,
        handleCloseCreateEditDialog,
        children,
        handleCloseDeleteDialog,
        tsAccounts,
        slackUsers
    } = props;


    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };
    const handleChangeSlackName = (event) => {
        setSlackUserId(event.target.value);
    };
    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const [role, setRole] = useState("");
    const [tsAccount, setTsAccount] = useState("");
    const [slackUserId, setSlackUserId] = useState("");

    useEffect(() => {
        if (recordForEdit) {
            const {id, role, ts_account_id, slack_id, ...rest} = recordForEdit;
            setUser((prevState) => {
                return {...prevState, ...rest};
            });
            if (role) {
                setRole(role);
            }
            if (ts_account_id) {
                setTsAccount(ts_account_id);
            }
            if (slack_id) {
                setSlackUserId(slack_id)
            }
        }
    }, [recordForEdit]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUser((inputs) => ({...inputs, [name]: value}));
    };

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data;
        if (recordForEdit) {
            if(recordCreated){
                data = {
                    id: recordForEdit.id,
                     email: user.email,
                     password: user.password,
                     role: role
                 };
            }
            else{
                data = {
                   id: recordForEdit.id,
                    email: user.email,
                    role: role
                };
            };
        } else {
            data = {
                email: user.email,
                password: user.password,
                role: role
            };
        }

        if (tsAccount !== "") {
            data.ts_account_id = tsAccount
        }

        if (slackUserId !== "") {
            data.slack_id = slackUserId
        }

        addOrEdit(data);
    };

    var errorMessage ='An error occurred: ' + apiError

    if(apiError.errorType === 'VALIDATION_ERROR'){
        errorMessage = 'Please fill the following fields: ' + apiError.errors
    }

    return (
        <Container component="main" maxWidth="xs">
            {recordForEdit && recordCreated === 'update' ? (
                <Typography component="h1" variant="h5">
                    Update User
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create User
                </Typography>
                )
            }
            {apiError && <Alert severity="error">{errorMessage}</Alert>}
            <Box
                sx={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={handleChange}
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                value={user.email}
                            />
                        </Grid>
                        {(!recordForEdit || recordCreated === 'create') && (
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handleChange}
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="text"
                                    id="password"
                                    value={user.password}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={role}
                                    label="Role"
                                    onChange={handleChangeRole}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="SUPERADMIN">Super Admin</MenuItem>
                                    <MenuItem value="ADMIN">Admin</MenuItem>
                                    <MenuItem value="MEDIABUYER">Mediabuyer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TrafficSourceAccountDropdown
                                tsAccount={tsAccount}
                                data={tsAccounts}
                                width={"100%"}
                                handleChangeTsAccount={handleChangeTsAccount}
                            />
                        </Grid>{" "}
                        <Grid item xs={12}>
                            <SlackDataDropdown
                                slackName={slackUserId}
                                data={slackUsers}
                                width={"100%"}
                                handleChangeSlackName={handleChangeSlackName}
                            />
                        </Grid>{" "}
                    </Grid>
                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            {children}
        </Container>
    );
}

export {CreateEditUser};
