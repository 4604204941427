import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";

function CreateBlockedSiteZone(props) {
    const {
        create,
        handleCloseCreateEditDialog,
        children,
        tsAccounts,
        trafficSourceList,
        isZone,
    } = props;

    const [blackList, setBlackList] = useState({
        zone_id: "",
        traffic_source_id: "",
    });
    const [tsAccount, setTsAccount] = useState("");

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setBlackList((inputs) => ({...inputs, [name]: value}));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isZone) {
            const data = {
                traffic_source_id: blackList.traffic_source_id,
                zone_id: blackList.zone_id,
                ts_account_id: tsAccount,
            };
            const dataTs = {
                traffic_source_id: blackList.traffic_source_id,
                zone_id: blackList.zone_id,
            };

            const createProp = tsAccount ? data : dataTs;

            create(createProp);
        } else {
            const data = {
                traffic_source_id: blackList.traffic_source_id,
                site_hostname: blackList.zone_id,
                ts_account_id: tsAccount,
            };
            const dataTs = {
                traffic_source_id: blackList.traffic_source_id,
                site_hostname: blackList.zone_id,
            };

            const createProp = tsAccount ? data : dataTs;

            create(createProp);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            {isZone ? (
                <Typography component="h1" variant="h5">
                    Create Blacklist Zone
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create Blacklist Site
                </Typography>
            )}
            <Box
                sx={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        {isZone ? (
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    id="zone_id"
                                    label="Zone Id"
                                    name="zone_id"
                                    type="text"
                                    value={blackList.zone_id}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    id="zone_id"
                                    label="Site Hostname"
                                    name="zone_id"
                                    type="text"
                                    value={blackList.zone_id}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    name="traffic_source_id"
                                    id="ts-account-label-select"
                                    value={blackList.traffic_source_id}
                                    label="Traffic Source"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {(trafficSourceList || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TrafficSourceAccountDropdown
                                tsAccount={tsAccount}
                                data={tsAccounts}
                                width={396}
                                handleChangeTsAccount={handleChangeTsAccount}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            {children}
        </Container>
    );
}

export {CreateBlockedSiteZone};
