import React, {useContext, useEffect, useState} from "react";
import {Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Grid from "@mui/material/Grid";
import {GlobalContext} from "../context/Provider";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import DateRangeFilter from "./components/dateRangeFilter";
import ReportTable from "./components/commonReportsTable";
import {COMPLETE_REPORT_GROUP_BY} from "./components/common";

function CountryReports() {
    const user = JSON.parse(localStorage.getItem("user"));


    const {tsAccountsDispatch, tsAccountsState} =
        useContext(GlobalContext);

    const [dateRange, setDateRange] = useState({
        from: new Date(),
        to: new Date(),
    })
    const [title, setTitle] = useState(
        `Country reports from ${dateRange.from.toISOString().substring(0, 10)} to ${dateRange.to
            .toISOString()
            .substring(0, 10)}`
    );
    const [tsAccount, setTsAccount] = useState("");

    const {tsAccountsList} = tsAccountsState;

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        setTitle(`Country reports from ${dateRange.from.toISOString().substring(0, 10)} to ${dateRange.to
            .toISOString()
            .substring(0, 10)}`)
    }, [dateRange]);
    const columns = [
        {
            field: "ts_account_id",
            headerName: "Ts Account",
            width: 100,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
            filterable: false,
        },
        {field: "country_code", headerName: "Country code", width: 120},
        {field: "country_name", headerName: "Country name", width: 200},
        {
            field: "impressions",
            headerName: "Impressions",
            width: 120,
        },
        {
            field: "clicks",
            headerName: "Clicks",
            width: 90,
        },
        {
            field: "cost",
            headerName: "Cost",
            width: 80,
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "conversions",
            headerName: "Conversions",
            width: 100,
            type: "number",
        },
        {
            field: "revenue",
            headerName: "Revenue",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "profit",
            headerName: "Profit",
            width: 80,
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "roi",
            headerName: "ROI",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                return params?.value + "%";
            },
        },
        {
            field: "ap",
            headerName: "AP",
            width: 70,
            type: "number",
        },
        {
            field: "G1",
            headerName: "G1",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G1 ? params.row.G1 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G1}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G2",
            headerName: "G2",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G2 ? params.row.G2 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}>
            {params.row.G2}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G3",
            headerName: "G3",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G3 ? params.row.G3 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G3}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G4",
            headerName: "G4",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G4 ? params.row.G4 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G4}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G5",
            headerName: "G5",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G5 ? params.row.G5 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G5}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G6",
            headerName: "G6",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G6 ? params.row.G6 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G6}
          </span>
                </Tooltip>
            ),
        },
    ];
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                {title}
            </Typography>
            <Grid container sx={{mb: 5}}>
                {(user.role === "ADMIN" || user.role === "SUPERADMIN") && (
                    <Grid item xs={3} justifyContent="left" sx={{display: "flex"}}>
                        <TrafficSourceAccountDropdown
                            tsAccount={tsAccount}
                            data={tsAccountsList.data}
                            width={300}
                            handleChangeTsAccount={handleChangeTsAccount}
                        />
                    </Grid>
                )}
            </Grid>
            <DateRangeFilter onDateRangeChange={setDateRange} dateRange={dateRange}/>
            <ReportTable
                columns={columns}
                tsAccountId={tsAccount}
                groupBy={COMPLETE_REPORT_GROUP_BY.COUNTRY}
                dateRange={dateRange}
            />
        </>
    );
}

export {CountryReports};
