const initialCampaignState = {
    campaigns: {
        loading: false,
        error: null,
        data: [],
    },
    addCampaign: {
        loading: false,
        error: null,
        data: null,
    },
    campaign: {
        loading: false,
        error: null,
        data: null,
    },
    remoteData: {
        loading: false,
        data: null,
        error: false,
        message: null,
    },
    assignAlgoCampaign: {
        loading: false,
        error: null,
        data: [],
    },
    treeCampaign: {
        loading: false,
        error: null,
        data: null,
        message: null,
    },
    assignAlgorithmsCampaign: {
        loading: false,
        error: null,
        data: [],
    },
    getAssignAlgorithmsCampaign: {
        loading: false,
        error: null,
        data: [],
    },
    getCampaignDefaultAlgorithmsState: {
        loading: false,
        error: null,
        data: [],
    },
    changeBid: {
        loading: false,
        data: null,
        error: false,
        message: null,
    },
    changeDailyBudget: {
        loading: false,
        data: null,
        error: false,
        message: null,
    },
    manualBidding: {
        loading: false,
        data: [],
        error: null,
        message: null,
    },
    checkSelectedAutoBiddingAlgorithm: {
        loading: false,
        data: null,
        error: null,
        message: null,
    },
    campaignTypes: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },
    updateCampaignTypes: {
        loading:false,
        error: false,
        message: null,
        data: {}
    }
};

export default initialCampaignState;
