import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../context/Provider";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";
import {loginUser} from "../context/actions/auth/login";
import TransitionAlerts from "../components/alert";
import CircularProgress from "@mui/material/CircularProgress";
import {errorReset} from "../context/actions/auth/logout";

function Login() {
    const [login, setLogin] = useState({
        email: "",
        password: "",
    });

    const {
        authDispatch,
        authState: {
            auth: {error, data, loading},
        },
    } = useContext(GlobalContext);
    const [openAlertEmail, setOpenAlertEmail] = useState(false);
    const [alertError, setAlertError] = useState(false);

    const navigate = useNavigate();

    const {username, password} = login;

    const handleSubmit = (event) => {
        event.preventDefault();
        const isEmailValid = validateEmail(username);
        setOpenAlertEmail(!isEmailValid);
        if (isEmailValid) {
            loginUser(password, username)(authDispatch);
        }
    };

    useEffect(() => {
        if (error) {
            setAlertError(true);
        }
    }, [alertError, error]);

    useEffect(() => {
        if (data) {
            navigate("/campaign");
        }
    }, [data, navigate]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setLogin((inputs) => ({...inputs, [name]: value}));
    };
    const handleAlertDialog = () => {
        setOpenAlertEmail(!openAlertEmail);
    };

    const handleAlertDialogError = () => {
        setAlertError(false);
        errorReset()(authDispatch);
    };

    const validateEmail = (email) => {
        const re =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return re.test(email);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 15,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {openAlertEmail && (
                    <TransitionAlerts
                        title={"Wrong email address"}
                        handleAlertDialog={handleAlertDialog}
                    />
                )}

                {alertError && (
                    <TransitionAlerts
                        title={"Invalid username or password"}
                        handleAlertDialog={handleAlertDialogError}
                    />
                )}

                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={handleChange}
                                id="username"
                                label="Email Address"
                                name="username"
                                autoComplete="username"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                onChange={handleChange}
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                    </Grid>

                    {loading && (
                        <Box sx={{display: "flex", justifyContent: "center", mt: 3}}>
                            <CircularProgress/>
                        </Box>
                    )}

                    {!loading && (
                        <Button
                            color="primary"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2, color: green}}
                        >
                            Sign In
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    );
}

export {Login};
