import React, {useContext, useEffect} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Tooltip} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";

import MaterialUIPickers from "../components/MaterialUIPickers";
import Skeleton from "@mui/material/Skeleton";
import {CustomToolbar} from "./customToolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {GlobalContext} from "../context/Provider";
import {getVariationsHistory} from "../context/actions/reports/reports";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {format} from "date-fns";
import Container from "@mui/material/Container";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export function AlertDialogVariationsHistory(props) {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const {reportsDispatch, reportsState} = useContext(GlobalContext);

    const {
        variationsReport: {data},
    } = reportsState;

    const initialDateFrom = new Date(today);
    initialDateFrom.setDate(today.getDate() - 7);

    const [dateFrom, setDateFrom] = React.useState(initialDateFrom);
    const [dateTo, setDateTo] = React.useState(today);

    const title = "Variations History Report"
    const tsAccount = ""

    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);

    const {tsAccountsList} = tsAccountsState;

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        document.title = title;
        getVariationsHistory(
            dateFrom.toISOString().substring(0, 10),
            dateTo.toISOString().substring(0, 10),
            tsAccount
        )(reportsDispatch);
    }, [title, dateTo, reportsDispatch, dateFrom, tsAccount]);

    const handleDateFromChange = (date) => {
        if (date > dateTo) {
            setDateFrom(dateTo);
            return;
        }
        setDateFrom(date);
    };

    const handleDateToChange = (date) => {
        if (date < dateFrom) {
            setDateTo(dateFrom);
            return;
        }
        setDateTo(date);
    };

    const columns = [
        {
            field: "added_on",
            headerName: "Added On",
            width: 200,
            valueFormatter: (params) => {
                return format(new Date(params?.value), "yyyy-MM-dd HH:MM:mm");
            },
        },
        {
            field: "variations",
            headerName: "Variations",
            width: 700,
            renderCell: (params) => (
                <Tooltip title={JSON.stringify(params.row.variations)}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {JSON.stringify(params.row.variations)}
          </span>
                </Tooltip>
            ),
        }
    ];

    const reportsData = data.filter(
        (item) => item.campaign_id === props.campaignId
    );
    const tsAccountName = tsAccountsList.data.find(
        (x) => x.id === props.campaignNameReport
    );

    return (
        <Container>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Variations History Report for {props.campaignId} ({tsAccountName?.name})
            </Typography>
            <Grid container>
                <Grid item xs={5}>
                    <MaterialUIPickers
                        handleDateChange={handleDateFromChange}
                        value={dateFrom}
                        label="From"
                    />
                </Grid>
                <Grid item xs={5}>
                    <MaterialUIPickers
                        handleDateChange={handleDateToChange}
                        value={dateTo}
                        label="To"
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>
            <Grid container sx={{mb: 5}}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                        size="small"
                        onClick={() => {
                            setDateFrom(today);
                            setDateTo(today);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - 7);
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        Last 7 days
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1) - 7);
                            const dateTo = new Date(dateFrom);
                            dateTo.setDate(dateFrom.getDate() + 6);
                            setDateFrom(dateFrom);
                            setDateTo(dateTo);
                        }}
                    >
                        Last Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1));
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(1);
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Month
                    </Button>
                </ButtonGroup>
            </Grid>
            {data ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--positive": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.success.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--neutral": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.warning.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--negative": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid
                        rows={reportsData}
                        columns={columns}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                    />
                </Box>
            ) : (
                <Skeleton variant="rectangular" height={210}/>
            )}
            <Button
                sx={{mt: 3, mb: 2, mr: 5}}
                onClick={props.handleCloseHistoryDialog}
                color="warning"
            >
                Cancel
            </Button>
        </Container>
    );
}
