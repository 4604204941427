export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";
export const ERROR_RESET = "ERROR_RESET";

export const CREATE_CAMPAIGN_LOAD = "CREATE_CAMPAIGN_LOAD";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";
export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_ERROR = "DELETE_CAMPAIGN_ERROR";

export const GET_SEED_CAMPAIGN_LOAD = "GET_SEED_CAMPAIGN_LOAD";
export const GET_SEED_CAMPAIGN_SUCCESS = "GET_SEED_CAMPAIGN_SUCCESS";
export const GET_SEED_CAMPAIGN_ERROR = "GET_SEED_CAMPAIGN_ERROR";
export const RESET_ERROR_CAMPAIGN_ERROR = "RESET_ERROR__CAMPAIGN_ERROR";

export const GET_REMOTE_DATA_CAMPAIGN_LOADING =
    "GET_REMOTE_DATA_CAMPAIGN_LOADING";
export const GET_REMOTE_DATA_CAMPAIGN_SUCCESS =
    "GET_REMOTE_DATA_CAMPAIGN_SUCCESS";
export const GET_REMOTE_DATA_CAMPAIGN_ERROR = "GET_REMOTE_DATA_CAMPAIGN_ERROR";
export const RESET_REMOTE_DATA_CAMPAIGN = "RESET_REMOTE_DATA_CAMPAIGN";
export const RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR =
    "RESET_ERROR_MESSAGE_REMOTE_DATA_CAMPAIGN_ERROR";
export const UPDATE_CAMPAIGN_LOADING = "UPDATE_CAMPAIGN_LOADING";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_ERROR = "UPDATE_CAMPAIGN_ERROR";

export const PAUSE_CAMPAIGN_LOADING = "PAUSE_CAMPAIGN_LOADING";
export const PAUSE_CAMPAIGN_SUCCESS = "PAUSE_CAMPAIGN_SUCCESS";
export const PAUSE_CAMPAIGN_ERROR = "PAUSE_CAMPAIGN_ERROR";

export const DUPLICATE_CAMPAIGN_LOADING = "DUPLICATE_CAMPAIGN_LOADING";
export const DUPLICATE_CAMPAIGN_SUCCESS = "DUPLICATE_CAMPAIGN_SUCCESS";
export const DUPLICATE_CAMPAIGN_ERROR = "DUPLICATE_CAMPAIGN_ERROR";

export const CHANGE_BID_CAMPAIGN_LOADING = "CHANGE_BID_CAMPAIGN_LOADING";
export const CHANGE_BID_CAMPAIGN_SUCCESS = "CHANGE_BID_CAMPAIGN_SUCCESS";
export const CHANGE_BID_CAMPAIGN_ERROR = "CHANGE_BID_CAMPAIGN_ERROR";

export const CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING =
    "CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING";
export const CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS =
    "CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS";
export const CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR =
    "CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR";

export const GET_CAMPAIGN_BY_ID_LOADING = "GET_CAMPAIGN_BY_ID_LOADING";
export const GET_CAMPAIGN_BY_ID_SUCCESS = "GET_CAMPAIGN_BY_ID_SUCCESS";
export const GET_CAMPAIGN_BY_ID_ERROR = "GET_CAMPAIGN_BY_ID_ERROR";

export const GET_REPORT_LOADING = "GET_REPORT_LOADING";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";

export const GET_TOTAL_REPORT_LOADING = "GET_TOTAL_REPORT_LOADING";
export const GET_TOTAL_REPORT_SUCCESS = "GET_TOTAL_REPORT_SUCCESS";
export const GET_TOTAL_REPORT_ERROR = "GET_TOTAL_REPORT_ERROR";

export const GET_SUCCESSES_LOADING = "GET_SUCCESSES_LOADING";
export const GET_SUCCESSES_SUCCESS = "GET_SUCCESSES_SUCCESS";
export const GET_SUCCESSES_ERROR = "GET_SUCCESSES_ERROR";

export const GET_ERRORS_LOADING = "GET_ERRORS_LOADING";
export const GET_ERRORS_SUCCESS = "GET_ERRORS_SUCCESS";
export const GET_ERRORS_ERROR = "GET_ERRORS_ERROR";

export const GET_BID_HISTORY_REPORT_LOADING = "GET_BID_HISTORY_REPORT_LOADING";
export const GET_BID_HISTORY_REPORT_SUCCESS = "GET_BID_HISTORY_REPORT_SUCCESS";
export const GET_BID_HISTORY_REPORT_ERROR = "GET_BID_HISTORY_REPORT_ERROR";

export const GET_VARIATIONS_HISTORY_REPORT_LOADING =
    "GET_VARIATIONS_HISTORY_REPORT_LOADING";
export const GET_VARIATIONS_HISTORY_REPORT_SUCCESS =
    "GET_VARIATIONS_HISTORY_REPORT_SUCCESS";
export const GET_VARIATIONS_HISTORY_REPORT_ERROR =
    "GET_VARIATIONS_HISTORY_REPORT_ERROR";

export const CREATE_USER_LOADING = "CREATE_USER_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const GET_SLACK_USERS_LOADING = "GET_SLACK_USERS_LOADING";
export const GET_SLACK_USERS_SUCCESS = "GET_SLACK_USERS_SUCCESS";
export const GET_SLACK_USERS_ERROR = "GET_SLACK_USERS_ERROR";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const CREATE_TASK_LOADING = "CREATE_TASK_LOADING";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR";

export const DELETE_TASK_LOADING = "DELETE_TASK_LOADING";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_ERROR = "DELETE_TASK_ERROR";

export const GET_TASK_LOADING = "GET_TASK_LOADING";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_ERROR = "GET_TASK_ERROR";

export const UPDATE_TASK_LOADING = "UPDATE_TASK_LOADING";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR";

export const GET_DOMAIN_LOADING = "GET_DOMAIN_LOADING";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_ERROR = "GET_DOMAIN_ERROR";

export const GET_LANDER_LOADING = "GET_LANDER_LOADING";
export const GET_LANDER_SUCCESS = "GET_LANDER_SUCCESS";
export const GET_LANDER_ERROR = "GET_LANDER_ERROR";

export const GET_OFFER_LOADING = "GET_OFFER_LOADING";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_ERROR = "GET_OFFER_ERROR";

export const TOGGLE_SHOULD_IGNORE_LANDER_LOADING = "TOGGLE_SHOULD_IGNORE_LANDER_LOADING";
export const TOGGLE_SHOULD_IGNORE_LANDER_SUCCESS = "TOGGLE_SHOULD_IGNORE_LANDER_SUCCESS";
export const TOGGLE_SHOULD_IGNORE_LANDER_ERROR = "TOGGLE_SHOULD_IGNORE_LANDER_ERROR";

export const TOGGLE_SHOULD_IGNORE_OFFER_LOADING = "TOGGLE_SHOULD_IGNORE_OFFER_LOADING";
export const TOGGLE_SHOULD_IGNORE_OFFER_SUCCESS = "TOGGLE_SHOULD_IGNORE_OFFER_SUCCESS";
export const TOGGLE_SHOULD_IGNORE_OFFER_ERROR = "TOGGLE_SHOULD_IGNORE_OFFER_ERROR";

export const REPLACE_DOMAIN_LOADING = "GET_DOMAIN_LOADING";
export const REPLACE_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const REPLACE_DOMAIN_ERROR = "GET_DOMAIN_ERROR";

export const FETCH_DOMAIN_LOADING = "FETCH_DOMAIN_LOADING";
export const FETCH_DOMAIN_SUCCESS = "FETCH_DOMAIN_SUCCESS";
export const FETCH_DOMAIN_ERROR = "FETCH_DOMAIN_ERROR";

export const GET_DOMAIN_TASKS_LOADING = "GET_DOMAIN_TASKS_LOADING";
export const GET_DOMAIN_TASKS_SUCCESS = "GET_DOMAIN_TASKS_SUCCESS";
export const GET_DOMAIN_TASKS_ERROR = "GET_DOMAIN_TASKS_ERROR";

export const DELETE_DOMAIN_TASK_LOADING = "DELETE_DOMAIN_TASK_LOADING";
export const DELETE_DOMAIN_TASK_SUCCESS = "DELETE_DOMAIN_TASK_SUCCESS";
export const DELETE_DOMAIN_TASK_ERROR = "DELETE_DOMAIN_TASK_ERROR";

export const CREATE_TS_ACCOUNTS_LOADING = "CREATE_TS_ACCOUNTS_LOADING";
export const CREATE_TS_ACCOUNTS_SUCCESS = "CREATE_TS_ACCOUNTS_SUCCESS";
export const CREATE_TS_ACCOUNTS_ERROR = "CREATE_TS_ACCOUNTS_ERROR";

export const DELETE_TS_ACCOUNTS_LOADING = "DELETE_TS_ACCOUNTS_LOADING";
export const DELETE_TS_ACCOUNTS_SUCCESS = "DELETE_TS_ACCOUNTS_SUCCESS";
export const DELETE_TS_ACCOUNTS_ERROR = "DELETE_TS_ACCOUNTS_ERROR";

export const GET_TS_ACCOUNTS_LOADING = "GET_TS_ACCOUNTS_LOADING";
export const GET_TS_ACCOUNTS_SUCCESS = "GET_TS_ACCOUNTS_SUCCESS";
export const GET_TS_ACCOUNTS_ERROR = "GET_TS_ACCOUNTS_ERROR";

export const GET_TS_LOADING = "GET_TS_LOADING";
export const GET_TS_SUCCESS = "GET_TS_SUCCESS";
export const GET_TS_ERROR = "GET_TS_ERROR";

export const UPDATE_TS_ACCOUNTS_LOADING = "UPDATE_TS_ACCOUNTS_LOADING";
export const UPDATE_TS_ACCOUNTS_SUCCESS = "UPDATE_TS_ACCOUNTS_SUCCESS";
export const UPDATE_TS_ACCOUNTS_ERROR = "UPDATE_TS_ACCOUNTS_ERROR";

export const CREATE_VOLUUM_LOADING = "CREATE_VOLUUM_LOADING";
export const CREATE_VOLUUM_SUCCESS = "CREATE_VOLUUM_SUCCESS";
export const CREATE_VOLUUM_ERROR = "CREATE_VOLUUM_ERROR";

export const DELETE_VOLUUM_LOADING = "DELETE_VOLUUM_LOADING";
export const DELETE_VOLUUM_SUCCESS = "DELETE_VOLUUM_SUCCESS";
export const DELETE_VOLUUM_ERROR = "DELETE_VOLUUM_ERROR";

export const GET_VOLUUM_LOADING = "GET_VOLUUM_LOADING";
export const GET_VOLUUM_SUCCESS = "GET_VOLUUM_SUCCESS";
export const GET_VOLUUM_ERROR = "GET_VOLUUM_ERROR";

export const UPDATE_VOLUUM_LOADING = "UPDATE_VOLUUM_LOADING";
export const UPDATE_VOLUUM_SUCCESS = "UPDATE_VOLUUM_SUCCESS";
export const UPDATE_VOLUUM_ERROR = "UPDATE_VOLUUM_ERROR";

export const GET_PERFORMANCE_MONITOR_REPORT_LOADING =
    "GET_PERFORMANCE_MONITOR_REPORT_LOADING";
export const GET_PERFORMANCE_MONITOR_REPORT_SUCCESS =
    "GET_PERFORMANCE_MONITOR_REPORT_SUCCESS";
export const GET_PERFORMANCE_MONITOR_REPORT_ERROR =
    "GET_PERFORMANCE_MONITOR_REPORT_ERROR";

export const GET_ALGORITHMS_TYPES_LOADING = "GET_ALGORITHMS_TYPES_LOADING";
export const GET_ALGORITHMS_TYPES_SUCCESS = "GET_ALGORITHMS_TYPES_SUCCESS";
export const GET_ALGORITHMS_TYPES_ERROR = "GET_ALGORITHMS_TYPES_ERROR";

export const GET_ALGORITHMS_LOADING = "GET_ALGORITHMS_LOADING";
export const GET_ALGORITHMS_SUCCESS = "GET_ALGORITHMS_SUCCESS";
export const GET_ALGORITHMS_ERROR = "GET_ALGORITHMS_ERROR";

export const GET_DEFAULT_ALGORITHMS_LOADING = "GET_DEFAULT_ALGORITHMS_LOADING";
export const GET_DEFAULT_ALGORITHMS_SUCCESS = "GET_DEFAULT_ALGORITHMS_SUCCESS";
export const GET_DEFAULT_ALGORITHMS_ERROR = "GET_DEFAULT_ALGORITHMS_ERROR";

export const GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING =
    "GET_CAMPAIGN_DEFAULT_ALGORITHMS_LOADING";
export const GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS =
    "GET_CAMPAIGN_DEFAULT_ALGORITHMS_SUCCESS";
export const GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR =
    "GET_CAMPAIGN_DEFAULT_ALGORITHMS_ERROR";

export const TURN_ON_OFF_ALGORITHMS_LOADING = "TURN_ON_OFF_ALGORITHMS_LOADING";
export const TURN_ON_OFF_ALGORITHMS_SUCCESS = "TURN_ON_OFF_ALGORITHMS_SUCCESS";
export const TURN_ON_OFF_ALGORITHMS_ERROR = "TURN_ON_OFF_ALGORITHMS_ERROR";

export const CREATE_DEFAULT_ALGORITHMS_LOADING =
    "CREATE_DEFAULT_ALGORITHMS_LOADING";
export const CREATE_DEFAULT_ALGORITHMS_SUCCESS =
    "CREATE_DEFAULT_ALGORITHMS_SUCCESS";
export const CREATE_DEFAULT_ALGORITHMS_ERROR =
    "CREATE_DEFAULT_ALGORITHMS_ERROR";

export const DELETE_DEFAULT_ALGORITHMS_LOADING =
    "DELETE_DEFAULT_ALGORITHMS_LOADING";
export const DELETE_DEFAULT_ALGORITHMS_SUCCESS =
    "DELETE_DEFAULT_ALGORITHMS_SUCCESS";
export const DELETE_DEFAULT_ALGORITHMS_ERROR =
    "DELETE_DEFAULT_ALGORITHMS_ERROR";

export const CREATE_ALGORITHMS_LOADING = "CREATE_ALGORITHMS_LOADING";
export const CREATE_ALGORITHMS_SUCCESS = "CREATE_ALGORITHMS_SUCCESS";
export const CREATE_ALGORITHMS_ERROR = "CREATE_ALGORITHMS_ERROR";

export const DELETE_ALGORITHMS_LOADING = "DELETE_ALGORITHMS_LOADING";
export const DELETE_ALGORITHMS_SUCCESS = "DELETE_ALGORITHMS_SUCCESS";
export const DELETE_ALGORITHMS_ERROR = "DELETE_ALGORITHMS_ERROR";

export const DELETE_ALGORITHMS_ERROR_MASSAGE =
    "DELETE_ALGORITHMS_ERROR_MASSAGE";

export const ASSIGN_ALGORITHMS_LOADING = "ASSIGN_ALGORITHMS_LOADING";
export const ASSIGN_ALGORITHMS_SUCCESS = "ASSIGN_ALGORITHMS_SUCCESS";
export const ASSIGN_ALGORITHMS_ERROR = "ASSIGN_ALGORITHMS_ERROR";
export const RESET_ASSIGN_ALGORITHMS_LOADING =
    "RESET_ASSIGN_ALGORITHMS_LOADING";

export const GET_ALGORITHMS_NAME_LOADING = "GET_ALGORITHMS_NAME_LOADING";
export const GET_ALGORITHMS_NAME_SUCCESS = "GET_ALGORITHMS_NAME_SUCCESS";
export const GET_ALGORITHMS_NAME_ERROR = "GET_ALGORITHMS_NAME_ERROR";

export const GET_CAMPAIGN_TREE_LOADING = "GET_CAMPAIGN_TREE_LOADING";
export const GET_CAMPAIGN_TREE_SUCCESS = "GET_CAMPAIGN_TREE_SUCCESS";
export const GET_CAMPAIGN_TREE_ERROR = "GET_CAMPAIGN_TREE_ERROR";

export const RESET_ERROR_MESSAGE_TREE_ERROR = "RESET_ERROR_MESSAGE_TREE_ERROR";

export const CREATE_BLACK_LIST_ZONE_LOADING = "CREATE_BLACK_LIST_ZONE_LOADING";
export const CREATE_BLACK_LIST_ZONE_SUCCESS = "CREATE_BLACK_LIST_ZONE_SUCCESS";
export const CREATE_BLACK_LIST_ZONE_ERROR = "CREATE_BLACK_LIST_ZONE_ERROR";

export const DELETE_BLACK_LIST_ZONE_LOADING = "DELETE_BLACK_LIST_ZONE_LOADING";
export const DELETE_BLACK_LIST_ZONE_SUCCESS = "DELETE_BLACK_LIST_ZONE_SUCCESS";
export const DELETE_BLACK_LIST_ZONE_ERROR = "DELETE_BLACK_LIST_ZONE_ERROR";

export const GET_BLACK_LIST_ZONE_LOADING = "GET_BLACK_LIST_ZONE_LOADING";
export const GET_BLACK_LIST_ZONE_SUCCESS = "GET_BLACK_LIST_ZONE_SUCCESS";
export const GET_BLACK_LIST_ZONE_ERROR = "GET_BLACK_LIST_ZONE_ERROR";

export const CREATE_BLACK_LIST_SITE_LOADING = "CREATE_BLACK_LIST_SITE_LOADING";
export const CREATE_BLACK_LIST_SITE_SUCCESS = "CREATE_BLACK_LIST_SITE_SUCCESS";
export const CREATE_BLACK_LIST_SITE_ERROR = "CREATE_BLACK_LIST_SITE_ERROR";

export const DELETE_BLACK_LIST_SITE_LOADING = "DELETE_BLACK_LIST_SITE_LOADING";
export const DELETE_BLACK_LIST_SITE_SUCCESS = "DELETE_BLACK_LIST_SITE_SUCCESS";
export const DELETE_BLACK_LIST_SITE_ERROR = "DELETE_BLACK_LIST_SITE_ERROR";

export const GET_BLACK_LIST_SITE_LOADING = "GET_BLACK_LIST_SITE_LOADING";
export const GET_BLACK_LIST_SITE_SUCCESS = "GET_BLACK_LIST_SITE_SUCCESS";
export const GET_BLACK_LIST_SITE_ERROR = "GET_BLACK_LIST_SITE_ERROR";

export const ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING =
    "ASSIGN_ALGORITHM_TO_CAMPAIGNS_LOADING";
export const ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS =
    "ASSIGN_ALGORITHM_TO_CAMPAIGNS_SUCCESS";
export const ASSIGN_ALGORITHM_TO_CAMPAIGNS_ERROR =
    "ASSIGN_ALGORITHM_TO_CAMPAIGNS_ERROR";

export const GET_CAMPAIGNS_FOR_ALGORITHM_LOADING =
    "GET_CAMPAIGNS_FOR_ALGORITHM_LOADING";
export const GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS =
    "GET_CAMPAIGNS_FOR_ALGORITHM_SUCCESS";
export const GET_CAMPAIGNS_FOR_ALGORITHM_ERROR =
    "GET_CAMPAIGNS_FOR_ALGORITHM_ERROR";

export const GET_EVENTS_LOADING = "GET_EVENTS_LOADING";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const GET_EVENTS_REMOTE_TS_LOADING = "GET_EVENTS_REMOTE_TS_LOADING";
export const GET_EVENTS_REMOTE_TS_SUCCESS = "GET_EVENTS_REMOTE_TS_SUCCESS";
export const GET_EVENTS_REMOTE_TS_ERROR = "GET_EVENTS_REMOTE_TS_ERROR";

export const GET_EVENTS_REMOTE_VOLUUM_LOADING =
    "GET_EVENTS_REMOTE_VOLUUM_LOADING";
export const GET_EVENTS_REMOTE_VOLUUM_SUCCESS =
    "GET_EVENTS_REMOTE_VOLUUM_SUCCESS";
export const GET_EVENTS_REMOTE_VOLUUM_ERROR = "GET_EVENTS_REMOTE_VOLUUM_ERROR";

export const CREATE_EVENTS_LOADING = "CREATE_EVENTS_LOADING";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const CREATE_EVENTS_ERROR = "CREATE_EVENTS_ERROR";

export const DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";
export const GET_CAMPAIGN_REPORT_BY_ID_LOADING =
    "GET_CAMPAIGN_REPORT_BY_ID_LOADING";
export const GET_CAMPAIGN_REPORT_BY_ID_SUCCESS =
    "GET_CAMPAIGN_REPORT_BY_ID_SUCCESS";
export const GET_CAMPAIGN_REPORT_BY_ID_ERROR =
    "GET_CAMPAIGN_REPORT_BY_ID_ERROR";
export const GET_MANUAL_BIDDING_LOADING = "GET_MANUAL_BIDDING_LOADING";
export const GET_MANUAL_BIDDING_SUCCESS = "GET_MANUAL_BIDDING_SUCCESS";
export const GET_MANUAL_BIDDING_ERROR = "GET_MANUAL_BIDDING_ERROR";

export const CREATE_BIDDING_BY_ID_LOADING = "CREATE_BIDDING_BY_ID_LOADING";
export const CREATE_BIDDING_BY_ID_SUCCESS = "CREATE_BIDDING_BY_ID_SUCCESS";
export const CREATE_BIDDING_BY_ID_ERROR = "CREATE_BIDDING_BY_ID_ERROR";
export const UPDATE_BID_SCHEDULING_LOADING = "UPDATE_BID_SCHEDULING_LOADING";
export const UPDATE_BID_SCHEDULING_SUCCESS = "UPDATE_BID_SCHEDULING_SUCCESS";
export const UPDATE_BID_SCHEDULING_ERROR = "UPDATE_BID_SCHEDULING_ERROR";
export const DELETE_BID_SCHEDULING_LOADING = "DELETE_BID_SCHEDULING_LOADING";
export const DELETE_BID_SCHEDULING_SUCCESS = "DELETE_BID_SCHEDULING_SUCCESS";
export const DELETE_BID_SCHEDULING_ERROR = "DELETE_BID_SCHEDULING_ERROR";
export const CREATE_ALGO_BIDDING_LOADING = "CREATE_ALGO_BIDDING_LOADING";
export const CREATE_ALGO_BIDDING_SUCCESS = "CREATE_ALGO_BIDDING_SUCCESS";
export const CREATE_ALGO_BIDDING_ERROR = "CREATE_ALGO_BIDDING_ERROR";
export const CHECK_AUTO_BIDDING_DATA_LOADING =
    "CHECK_AUTO_BIDDING_DATA_LOADING";
export const CHECK_AUTO_BIDDING_DATA_SUCCESS =
    "CHECK_AUTO_BIDDING_DATA_SUCCESS";
export const CHECK_AUTO_BIDDING_DATA_ERROR = "CHECK_AUTO_BIDDING_DATA_ERROR";

// BULK UPDATE CAMPAIGN TYPE
export const GET_CAMPAIGN_TYPES_LOADING = "GET_CAMPAIGN_TYPES_LOADING";
export const GET_CAMPAIGN_TYPES_SUCCESS = "GET_CAMPAIGN_TYPES_SUCCESS";
export const GET_CAMPAIGN_TYPES_ERROR = "GET_CAMPAIGN_TYPES_ERROR";
export const UPDATE_TYPE_TO_CAMPAIGNS_LOADING = "UPDATE_TYPE_TO_CAMPAIGNS_LOADING";
export const UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS = "UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS";
export const UPDATE_TYPE_TO_CAMPAIGNS_ERROR = "UPDATE_TYPE_TO_CAMPAIGNS_ERROR";
// END BULK UPDATE CAMPAIGN TYPE
