import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";

import MaterialUIPickers from "../components/MaterialUIPickers";
import Skeleton from "@mui/material/Skeleton";
import {CustomToolbar} from "./customToolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {GlobalContext} from "../context/Provider";
import {getBidHistory} from "../context/actions/reports/reports";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {format} from "date-fns";
import Container from "@mui/material/Container";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function AlertDialogBidHistory({
                                   campaignId,
                                   handleClose,
                                   campaignBidReport,
                               }) {
    const today = new Date();
    const initialDateFrom = new Date(today);
    initialDateFrom.setDate(today.getDate() - 7);

    const {reportsDispatch, reportsState} = useContext(GlobalContext);

    const {
        bidReport: {data},
    } = reportsState;
    const [dateFrom, setDateFrom] = React.useState(initialDateFrom);
    const [dateTo, setDateTo] = React.useState(today);
    const [title, setTitle] = useState("Bid History Report");
    const tsAccount = "";
    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);

    const {tsAccountsList} = tsAccountsState;

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        document.title = title;
        getBidHistory(
            dateFrom.toISOString().substring(0, 10),
            dateTo.toISOString().substring(0, 10),
            tsAccount
        )(reportsDispatch);
    }, [title, dateTo, reportsDispatch, dateFrom, tsAccount]);

    const handleDateFromChange = (date) => {
        if (date > dateTo) {
            setDateFrom(dateTo);
            return;
        }
        setDateFrom(date);
        setTitle(
            `Bid History reports from ${dateFrom
                .toISOString()
                .substring(0, 10)} to ${dateTo.toISOString().substring(0, 10)}`
        );
    };

    const handleDateToChange = (date) => {
        if (date < dateFrom) {
            setDateTo(dateFrom);
            return;
        }
        setDateTo(date);
        setTitle(
            `Bid History  from ${dateFrom.toISOString().substring(0, 10)} to ${dateTo
                .toISOString()
                .substring(0, 10)}`
        );
    };

    const columns = [
        {
            field: "added_on",
            headerName: "Added On",
            width: 200,
            valueFormatter: (params) => {
                return format(new Date(params?.value), "yyyy-MM-dd HH:MM:mm");
            },
        },
        {
            field: "bid",
            headerName: "Bid",
            width: 300,
            type: "number",
            headerAlign: "left",
            align: "left",
            renderCell: (cellValues) => {
                const tsAccount = tsAccountsList.data.find(
                    (x) => x.id === cellValues.row.ts_account_id
                );
                const currency = tsAccount.currency;
                let formattedPrice = "";
                if (currency === "USD") {
                    formattedPrice += "$";
                } else if (currency === "EUR") {
                    formattedPrice += "€";
                }
                formattedPrice += cellValues.row.bid;
                return formattedPrice;
            },
        },
    ];
    const tsAccountName = tsAccountsList.data.find(
        (x) => x.id === campaignBidReport
    );

    const BidCampaignsData = data.filter(
        (item) => item.campaign_id === campaignId
    );
    return (
        <Container>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Bid History Report for {campaignId} ({tsAccountName?.name})
            </Typography>
            <Grid container>
                <Grid item xs={3}>
                    <MaterialUIPickers
                        handleDateChange={handleDateFromChange}
                        value={dateFrom}
                        label="From"
                    />
                </Grid>
                <Grid item xs={3}>
                    <MaterialUIPickers
                        handleDateChange={handleDateToChange}
                        value={dateTo}
                        label="To"
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>
            <Grid container sx={{mb: 5}}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                        size="small"
                        onClick={() => {
                            setDateFrom(today);
                            setDateTo(today);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - 7);
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        Last 7 days
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1) - 7);
                            const dateTo = new Date(dateFrom);
                            dateTo.setDate(dateFrom.getDate() + 6);
                            setDateFrom(dateFrom);
                            setDateTo(dateTo);
                        }}
                    >
                        Last Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1));
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(1);
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Month
                    </Button>
                </ButtonGroup>
            </Grid>
            {data ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--positive": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.success.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--neutral": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.warning.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--negative": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid
                        rows={BidCampaignsData}
                        columns={columns}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                    />
                </Box>
            ) : (
                <Skeleton variant="rectangular" height={210}/>
            )}
            <Button
                sx={{mt: 3, mb: 2, mr: 5}}
                onClick={handleClose}
                color="warning"
            >
                Cancel
            </Button>
        </Container>
    );
}

export {AlertDialogBidHistory};
