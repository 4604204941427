import React, {useContext, useEffect, useState} from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {SelectTSAccount} from "./components/selectTSAccount";
import {SelectCampaigns} from "./components/selectCampaigns";
import {GlobalContext} from "../context/Provider";
import {getCampaignTypes, updateTypeToCampaigns} from "../context/actions/campaign/campaign_types";
import {getCampaigns,} from "../context/actions/campaign/campaign";

import {getTrafficSource} from "../context/actions/traffic/trafficSource";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import Alert from "@mui/material/Alert";

const steps = ["Select TS Account", "Select Campaigns"];

export function BulkCampaignTypeUpdate() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [campaignType, setCampaignType] = useState("")
    const [campaignTypeToSet, setCampaignTypeToSet] = useState("");
    const [tsAccount, setTsAccount] = useState("");
    const [filterByName, setFilterByName] = useState("");
    const [filterCampaign, setFilterCampaign] = useState([]);
    const [costModel, setCostModel] = useState("");
    const [selectedCampaignsIdsList, setSelectedCampaignsIdsList] = useState([]);
    const [allCampaignsChecked, setAllCampaignsChecked] = useState(false);

    const {
        tsAccountsDispatch,
        tsAccountsState,
        campaignDispatch,
        campaignState,
        campaignTypesState,
        campaignTypesDispatch,
        updateCampaignTypesState,
        updateCampaignTypesDispatch,
    } = useContext(GlobalContext);

    const {trafficSourceList, tsAccountsList} = tsAccountsState;
    const {campaigns} = campaignState;
    const [trafficSource, setTrafficSource] = useState("");

    const {campaignTypes} = campaignTypesState;
    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch, campaignTypesDispatch]);

    useEffect(() => {
        getCampaigns(tsAccount, costModel, campaignType)(campaignDispatch);
    }, [campaignDispatch, tsAccount, costModel, campaignType]);

    useEffect(() => {
        getCampaignTypes(trafficSource)(campaignTypesDispatch);
    }, [trafficSource, campaignTypesDispatch]);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    useEffect(() => {
        if (campaigns.data) {
            setFilterCampaign(campaigns.data);
        }
    }, [campaigns.data]);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            updateTypeToCampaigns(selectedCampaignsIdsList, campaignTypeToSet, tsAccount)(updateCampaignTypesDispatch);
        }
    };

    const selectCampaign = (e) => {
        const {checked} = e.target;
        const selectedCampaignsIds = [];

        if (checked && campaigns.data) {
            for (const campaign of campaigns.data) {
                selectedCampaignsIds.push(campaign.id);
            }
        }

        setSelectedCampaignsIdsList(selectedCampaignsIds);
        setAllCampaignsChecked(checked);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    const handleReset = () => {
        setActiveStep(0);
        setTrafficSource("");
        setCampaignType("");
        setTsAccount("");
        setCampaignTypeToSet("");
        setSelectedCampaignsIdsList([]);
        setCostModel("");
        setAllCampaignsChecked(false);
        setFilterByName("");
        setFilterCampaign([]);
    };

    const handleChangeTs = (event) => {
        setTrafficSource(event.target.value);
    };

    const handleChangeCampaignType = (e) => {
        setCampaignType(e.target.value);
    };

    const handleChangeCampaignTypeToSet = (e) => {
        setCampaignTypeToSet(e.target.value)
    };

    const handleCostModel = (e) => {
        setCostModel(e.target.value);
    };

    const handleCheckboxClick = (e) => {
        const {value, checked} = e.target;

        if (checked) {
            setSelectedCampaignsIdsList([...selectedCampaignsIdsList, value * 1]);
        } else {
            setSelectedCampaignsIdsList(selectedCampaignsIdsList.filter((item) => item !== parseInt(value)));
        }
    };

    const handleFilterCampaignByName = (e) => {
        setFilterByName(e.target.value);
        if (e.target.value) {
            const a = campaigns.data.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilterCampaign(a);
        } else {
            return setFilterCampaign(campaigns.data);
        }
    };
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <SelectTSAccount
                        tsAccount={tsAccount}
                        handleChangeTsAccount={handleChangeTsAccount}
                        tsAccountsList={tsAccountsList.data}
                        trafficSource={trafficSource}
                        trafficSourceList={trafficSourceList.data}
                        handleChangeTs={handleChangeTs}
                    />
                );
            case 1:
                return (
                    <SelectCampaigns
                        filterByName={filterByName}
                        handleCostModel={handleCostModel}
                        costModel={costModel}
                        selectedCampaignsIdsList={selectedCampaignsIdsList}
                        selectCampaign={selectCampaign}
                        handleCheckboxClick={handleCheckboxClick}
                        campaignsList={filterCampaign}
                        handleFilterCampaignByName={handleFilterCampaignByName}
                        campaignType={campaignType}
                        handleChangeCampaignType={handleChangeCampaignType}
                        campaignTypes={campaignTypes.data}
                        allCampaignsChecked={allCampaignsChecked}
                        campaignTypeToSet={campaignTypeToSet}
                        handleChangeCampaignTypeToSet={handleChangeCampaignTypeToSet}
                    />
                );
            default:
                return "Unknown step";
        }
    };
    return (
        <Box sx={{width: "100%"}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <Box style={{padding: "20px"}}>
                    {updateCampaignTypesState.error
                        ? <Alert severity="error">
                            Unable to update the campaign types: <b>{updateCampaignTypesState.error.detail}</b>
                        </Alert>
                        : <Alert severity="success">
                            Updated successfully!
                        </Alert>
                    }

                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        {updateCampaignTypesState.error
                        && <Button color="inherit"
                                   disabled={activeStep === 0}
                                   onClick={handleBack}
                                   sx={{mr: 1}}>Back</Button>}
                        <Box sx={{flex: "1 1 auto"}}/>
                        <Button onClick={handleReset}>Select New</Button>
                    </Box>
                </Box>
            ) : (
                <>
                    {getStepContent(activeStep)}
                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: "1 1 auto"}}/>

                        {activeStep !== steps.length - 1 && (
                            <Button onClick={handleNext}>Next</Button>
                        )}
                        {activeStep === steps.length - 1 && (
                            <Button onClick={handleNext}
                                    disabled={!campaignTypeToSet || selectedCampaignsIdsList.length === 0}>Finish</Button>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}
