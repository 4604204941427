import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Provider";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getLandersDomains } from "../context/actions/domains/landers";
import { getDomains, fetchDomains } from "../context/actions/domains/domains";
import { replaceDomain, getDomainTasks } from "../context/actions/domains/domainsTasks";

function ReplaceDomains() {
    const { landersDispatch, landersState } = useContext(GlobalContext);
    const { domainsDispatch, domainsState } = useContext(GlobalContext);
    const { domainTasksDispatch, domainTasksState } = useContext(GlobalContext);

    const {
        landersList
    } = landersState;

    const {
        domainsList
    } = domainsState;

    const {
        domainTasksList
    } = domainTasksState;

    useEffect(() => {
        getLandersDomains()(landersDispatch);
    }, [landersDispatch]);

    useEffect(() => {
        getDomains()(domainsDispatch);
    }, [domainsDispatch]);

    useEffect(() => {
        getDomainTasks()(domainTasksDispatch);
    }, [domainTasksDispatch]);

    const [replaceFrom, setreplaceFrom] = useState('');
    const [replaceTo, setreplaceTo] = useState('');
    const [isSame, setIsSame] = useState(false);
    const [apiError, setApiError] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);
    const [domainCreated, setDomainCreated] = useState(false);


    useEffect(() => {
        const isSameDomains = replaceFrom !== '' && replaceTo !== '' && replaceFrom === replaceTo;
        setIsSame(isSameDomains);
        setPostSuccess(false);
        setDomainCreated(false)
        setIsEmpty(false)
    }, [replaceFrom, replaceTo]);

    const handleFetchDomains = () => {
        setFetchIsLoading(true);
        fetchDomains(() => {
            setFetchIsLoading(false);
        })(domainsDispatch);
    };

    const handleSubmit = () => {
        setDomainCreated(false)
        setPostSuccess(false)

        if (replaceFrom === '' || replaceTo === '') {
            setIsEmpty(true)
            setErrorMessage('Please fill both fields')
            return;
        }
        else if (domainTasksList.data.some(item => item.replace_from === replaceFrom && item.replace_to === replaceTo)) {
            setDomainCreated(true);
            setErrorMessage('Domain task with these values already created');
            return;
          }
          

        const data = {
            replace_from: replaceFrom,
            replace_to: replaceTo
        };

        replaceDomain(data)((action) => {
            if (action.type === 'GET_DOMAIN_ERROR') {
                setApiError(action.payload.details);
            }
            else if (action.type === 'GET_DOMAIN_SUCCESS') {
                setPostSuccess(true)
                setSuccessMessage('Domain Task successfully created')
            }
        });

        setIsEmpty(false)
        setApiError(false);
        setreplaceFrom('');
        setreplaceTo('');
    };
    useEffect(() => {
        setErrorMessage(isSame ? 'New Domain is same as Old Domain' : apiError || '');
    }, [isSame, apiError]);

    return (
        <Grid container spacing={2} sx={{
            maxWidth: '500px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ paddingBottom: '1rem' }}>
                    Replace Domain
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="contained" color="secondary" onClick={handleFetchDomains} disabled={fetchIsLoading} sx={{ marginBottom: '10px' }}>
                    {fetchIsLoading ? 'Refreshing...' : 'Refresh'}
                </Button>
            </Grid>
            {isSame || apiError || isEmpty || domainCreated ? (
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            ) : null}
            {postSuccess && <Alert severity="success">
                {successMessage}
            </Alert>}
            <Grid item xs={12}>
                <Autocomplete
                    value={replaceFrom}
                    onChange={(event, newValue) => setreplaceFrom(newValue)}
                    options={landersList.data.map((option) => option.domain)}
                    getOptionLabel={(option) => option || ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Lander"
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    value={replaceTo}
                    onChange={(event, newValue) => setreplaceTo(newValue)}
                    options={domainsList.data.map((option) => option.name)}
                    getOptionLabel={(option) => option || ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Domain"
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button disabled={isSame} variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: '5%', padding: '5%' }}>
                    Submit
                </Button>
            </Grid>

        </Grid>

    );
}

export { ReplaceDomains };
