import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_BLACK_LIST_SITE_ERROR,
    CREATE_BLACK_LIST_SITE_LOADING,
    CREATE_BLACK_LIST_SITE_SUCCESS,
    DELETE_BLACK_LIST_SITE_ERROR,
    DELETE_BLACK_LIST_SITE_LOADING,
    DELETE_BLACK_LIST_SITE_SUCCESS,
    GET_BLACK_LIST_SITE_ERROR,
    GET_BLACK_LIST_SITE_LOADING,
    GET_BLACK_LIST_SITE_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createBlackListSites = (item) => (dispatch) => {
    dispatch({
        type: CREATE_BLACK_LIST_SITE_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/blacklisted/sites/`, item)
        .then((res) => {
            dispatch({
                type: CREATE_BLACK_LIST_SITE_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_BLACK_LIST_SITE_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getBlackListSites =
    (tsAccountId, trafficSourceId) => (dispatch) => {
        const id = tsAccountId ? tsAccountId : null;
        const traffic_SourceId = trafficSourceId ? trafficSourceId : null;

        dispatch({
            type: GET_BLACK_LIST_SITE_LOADING,
        });
        axiosInstance()
            .get(`/api/${version}/blacklisted/sites/`, {
                params: {ts_account_id: id, traffic_source_id: traffic_SourceId},
            })
            .then((res) => {
                dispatch({
                    type: GET_BLACK_LIST_SITE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_BLACK_LIST_SITE_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const deleteBlackListSite = (id) => (dispatch) => {
    dispatch({
        type: DELETE_BLACK_LIST_SITE_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/blacklisted/sites/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_BLACK_LIST_SITE_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_BLACK_LIST_SITE_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};
