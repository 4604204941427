import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {green} from "@mui/material/colors";

import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {GlobalContext} from "../context/Provider";
import {getManualBiddingReports} from "../context/actions/campaign/campaign";
import LoadingSpinner from "./LoadingSpinner";

const keyOfRows = [
    "TS Account Name",
    "Campaign Name",
    "Campaign Status",
    "Campaign Type",
    "Number Of Variations",
    "Added Variations",
    "Cost Model",
    "Daily Budget",
    "Daily Budget Reset",
    "Traffic Estimate",
    "Impressions",
    "Cost",
    "Clicks",
    "Conversions",
    "Profit",
    "Revenue",
    "CPM",
    "Campaign Age",
    "EPC",
    "ROI",
    "AP",
    "G1",
    "G2",
    "BID",
    "",
];

export function ManualBiddingDialog(props) {
    const {handleCloseManualDialog, handleChangeBidValue, dataForEdit} = props;

    const {campaignState, campaignDispatch, tsAccountsState} =
        useContext(GlobalContext);

    const {
        manualBidding: {data, loading},
    } = campaignState;
    const {tsAccountsList} = tsAccountsState;

    const formattedData = swap(data?.three_days);
    const [dataRows, setDataRows] = React.useState(formattedData);
    const [selectedColor, setSelectedColor] = React.useState("three");

    const [manualData, setData] = useState({
        bid: "",
    });

    useEffect(() => {
        getManualBiddingReports(dataForEdit.campaign_id)(campaignDispatch);
        setDataRows(formattedData);
    }, [campaignDispatch, dataForEdit.campaign_id, formattedData]);

    useEffect(() => {
        setDataRows(formattedData);
    }, [formattedData]);
    useEffect(() => {
        if (dataForEdit) {
            setData((prevState) => {
                return {...prevState, ...dataForEdit};
            });
        }
    }, [dataForEdit]);

    function swap(result) {
        let user = tsAccountsList.data.find((x) => x.id === result?.ts_account_id);
        delete result?.goals;
        delete result?.date_from;
        delete result?.date_to;
        delete result?.id;
        delete result?.external_date_created;
        return {
            "TS Account Name": user?.name,
            "Campaign Name": result?.campaign_name,
            "Campaign Status": result?.campaign_status,
            "Campaign Type": result?.campaign_type,
            "Number Of Variations": result?.number_of_variations,
            "Added Variations": result?.added_variations,
            "Cost Model": result?.cost_model,
            "Daily Budget": result?.daily_budget,
            "Daily Budget Reset": result?.daily_budget_reset,
            "Traffic Estimate": result?.traffic_estimate,
            Impressions: result?.impressions,
            Cost: result?.cost,
            Clicks: result?.clicks,
            Conversions: result?.conversions,
            Profit: result?.profit + "$",
            Revenue: result?.revenue,
            CPM: result?.cpm,
            "Campaign Age": result?.campaign_age,
            EPC: result?.epc,
            ROI: result?.roi,
            AP: result?.ap,
            G1: result?.G1,
            G2: result?.G2,
        };
    }

    const handleChangeSave = () => {
        handleChangeBidValue(manualData.bid);
    };

    const handleChangeBid = (e) => {
        setData({bid: e.target.value});
    };

    const percentage = (num, per) => {
        return (num / 100) * per;
    };

    const handleAdd10Percent = () => {
        const dataBid = Number(manualData.bid);
        const percent = percentage(dataBid, 10);
        const res = dataBid + percent;
        const fixedBid = res.toFixed(4);

        setData({bid: fixedBid});
    };

    const formatOrderData = (result) => {
        return swap(result);
    };

    const handleRemove10Percent = () => {
        const dataBid = Number(manualData.bid);
        const percent = percentage(dataBid, 10);
        const res = dataBid - percent;
        const fixedBid = res.toFixed(4);

        setData({bid: fixedBid});
    };

    function createData(name, calories) {
        return {name, calories};
    }

    const handleChangeFormat = (formatDate) => {
        switch (formatDate) {
            case "three_days":
                setSelectedColor("three");
                const threeDays = formatOrderData(data?.three_days);
                setDataRows(threeDays);
                break;
            case "thirty_days":
                setSelectedColor("thirty");
                const thirtyDays = formatOrderData(data?.thirty_days);
                setDataRows(thirtyDays);
                break;
            case "two_weeks":
                setSelectedColor("two-weeks");
                const twoWeeks = formatOrderData(data?.two_weeks);
                setDataRows(twoWeeks);
                break;
            case "seven_days":
                setSelectedColor("seven");
                const sevenDays = formatOrderData(data?.seven_days);
                setDataRows(sevenDays);
                break;
            default:
                setSelectedColor("seven");
                const sevenDays2 = formatOrderData(data?.seven_days);
                setDataRows(sevenDays2);
                break;
        }
    };

    const rows = [];

    let index = 0;
    for (const key in dataRows) {
        if (dataRows.hasOwnProperty(key)) {
            rows.push(createData(keyOfRows[index], dataRows[key]));
            index++;
        }
    }

    return (
        <div>
            <Button
                sx={{mb: 5}}
                onClick={handleCloseManualDialog}
                color="warning"
                startIcon={<ChevronLeftSharpIcon/>}
            >
                Back
            </Button>
            <Grid item sx={{width: "100%"}}>
                <Button
                    size="small"
                    onClick={() => {
                        handleChangeFormat("three_days");
                    }}
                    style={
                        selectedColor === "three"
                            ? {backgroundColor: "#87CEFA", padding: "5px 20px"}
                            : {border: "1px solid cadetblue", padding: "5px 20px"}
                    }
                >
                    Last 3 Days
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        handleChangeFormat("seven_days");
                    }}
                    style={
                        selectedColor === "seven"
                            ? {backgroundColor: "#87CEFA", padding: "5px 20px"}
                            : {border: "1px solid cadetblue", padding: "5px 20px"}
                    }
                >
                    Last 7 days
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        handleChangeFormat("two_weeks");
                    }}
                    style={
                        selectedColor === "two-weeks"
                            ? {backgroundColor: "#87CEFA", padding: "5px 20px"}
                            : {border: "1px solid cadetblue", padding: "5px 20px"}
                    }
                >
                    Last 2 Weeks
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        handleChangeFormat("thirty_days");
                    }}
                    style={
                        selectedColor === "thirty"
                            ? {backgroundColor: "#87CEFA", padding: "5px 20px"}
                            : {border: "1px solid cadetblue", padding: "5px 20px"}
                    }
                >
                    Last 30 Days
                </Button>
            </Grid>

            <TableContainer component={Paper}>
                {loading ? (
                    <Box style={{justifyContent: "flex-end"}}>
                        <LoadingSpinner/>
                    </Box>
                ) : (
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell style={{width: "200px"}} align="right">
                                        {row.calories}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <Grid item sx={{width: "100%"}}>
                <Grid item xs={12}>
                    <TextField
                        sx={{mt: 5}}
                        required
                        fullWidth
                        onChange={handleChangeBid}
                        id="changeBid"
                        label="Change Campaign Bid"
                        name="changeBid"
                        value={manualData.bid}
                    />
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleAdd10Percent(false)}
                    >
                        +
                    </Button>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleRemove10Percent(false)}
                    >
                        -
                    </Button>
                </Grid>
                <Box>
                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseManualDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        //   disabled={error}
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                        onClick={handleChangeSave}
                    >
                        Set BId
                    </Button>
                </Box>
            </Grid>
        </div>
    );
}
