export const initialEventState = {
    eventsList: {
        loading: false,
        error: false,
        data: [],
    },
    eventsTsList: {
        loading: false,
        error: false,
        data: [],
    },
    eventsVoluumList: {
        loading: false,
        error: false,
        data: [],
    },
    addEvent: {
        loading: false,
        error: false,
        data: null,
    }
};
