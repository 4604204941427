import {GET_OFFER_SUCCESS, GET_OFFER_LOADING, GET_OFFER_ERROR} from "../../constants/actionTypes";

const offers = (state, {payload, type}) => {
    switch (type) {
        case GET_OFFER_LOADING: {
            return {
                ...state,
                offersList: {
                    ...state.offersList,
                    loading: true,
                },
            };
        }

        case GET_OFFER_SUCCESS: {
            return {
                ...state,
                offersList: {
                    ...state.offersList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_OFFER_ERROR: {
            return {
                ...state,
                offersList: {
                    ...state.offersList,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default offers;
