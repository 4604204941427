import {axiosInstance} from "../../../helpers/axiosInstance";
import {GET_TS_ERROR, GET_TS_LOADING, GET_TS_SUCCESS,} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getTrafficSource = () => (dispatch) => {
    dispatch({
        type: GET_TS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/ts/`)
        .then((res) => {
            dispatch({
                type: GET_TS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_TS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
