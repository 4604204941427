import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/DialogContent";
import Box from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import List from "@mui/material/List";

const Demo = styled("div")(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const OpenModalMenu = ({children, handleCloseDialog, open}) => {
    const dense = false;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Grid xs={12} md={6}>
                        <Box>
                            <Typography sx={{mt: 4, mb: 2}} variant="h6" component="div">
                                Change Campaign Bid
                            </Typography>
                        </Box>

                        <Demo>
                            <List dense={dense}>{children}</List>
                        </Demo>
                    </Grid>
                </DialogContent>
            </Dialog>
            ;
        </div>
    );
};
