import {
    CREATE_TS_ACCOUNTS_ERROR,
    CREATE_TS_ACCOUNTS_LOADING,
    CREATE_TS_ACCOUNTS_SUCCESS,
    DELETE_TS_ACCOUNTS_ERROR,
    DELETE_TS_ACCOUNTS_LOADING,
    DELETE_TS_ACCOUNTS_SUCCESS,
    GET_TS_ACCOUNTS_ERROR,
    GET_TS_ACCOUNTS_LOADING,
    GET_TS_ACCOUNTS_SUCCESS,
    GET_TS_ERROR,
    GET_TS_LOADING,
    GET_TS_SUCCESS,
    UPDATE_TS_ACCOUNTS_ERROR,
    UPDATE_TS_ACCOUNTS_LOADING,
    UPDATE_TS_ACCOUNTS_SUCCESS,
} from "../../constants/actionTypes";

const tsAccounts = (state, {payload, type}) => {
    switch (type) {
        case GET_TS_ACCOUNTS_LOADING: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: true,
                    error: false,
                    message: null,
                },
            };
        }

        case GET_TS_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_TS_ACCOUNTS_ERROR: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_TS_ACCOUNTS_LOADING: {
            return {
                ...state,
                addTsAccount: {
                    ...state.addTsAccount,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_TS_ACCOUNTS_ERROR: {
            return {
                ...state,
                addTsAccount: {
                    ...state.addTsAccount,
                    loading: false,
                },
            };
        }

        case CREATE_TS_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                addTsAccount: {
                    ...state.addTsAccount,
                    loading: false,
                    data: payload,
                },

                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    data: [payload, ...state.tsAccountsList.data],
                },
            };
        }
        case UPDATE_TS_ACCOUNTS_LOADING: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: true,
                },
            };
        }
        case UPDATE_TS_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    data: state.tsAccountsList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        case UPDATE_TS_ACCOUNTS_ERROR: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    data: state.tsAccountsList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }

        case DELETE_TS_ACCOUNTS_LOADING: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: true,
                    error: false,
                    message: null,
                    data: state.tsAccountsList.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_TS_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    data: state.tsAccountsList.data.filter((item) => item.id !== payload),
                },
            };
        }

        case DELETE_TS_ACCOUNTS_ERROR: {
            return {
                ...state,
                tsAccountsList: {
                    ...state.tsAccountsList,
                    loading: false,
                    error: true,
                    message: payload,
                },
            };
        }
        case GET_TS_LOADING: {
            return {
                ...state,
                trafficSourceList: {
                    ...state.trafficSourceList,
                    loading: true,
                    error: false,
                    message: null,
                },
            };
        }

        case GET_TS_SUCCESS: {
            return {
                ...state,
                trafficSourceList: {
                    ...state.trafficSourceList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_TS_ERROR: {
            return {
                ...state,
                trafficSourceList: {
                    ...state.trafficSourceList,
                    loading: false,
                    error: payload,
                },
            };
        }


        default:
            return state;
    }
};

export default tsAccounts;
