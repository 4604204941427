import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import DateRangeFilter from "./components/dateRangeFilter";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

import {GlobalContext} from "../context/Provider";

import {getVoluumCampaigns} from "../context/actions/voluum/voluum";
import {COMPLETE_REPORT_GROUP_BY, dateToStr} from "./components/common";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SiteReportsTable from "./components/siteReportsTable";
import ZoneReportsTable from "./components/zoneReportsTable";
import {useLocation} from "react-router-dom";

function CompleteReports() {
    const sentState = useLocation()?.state;
    const {
        campaignId, tsAccountId, tsAccountName,
    } = useLocation()?.state || {
        campaignId: 0,
        tsAccountId: 0,
        dateRange: {
            from: new Date(),
            to: new Date()
        }
    };

    const {
        voluumDispatch,
    } = useContext(GlobalContext);

    const [showAssignedOnly, setShowAssignedOnly] = useState(false);

    const [groupBy, setGroupBy] = useState(COMPLETE_REPORT_GROUP_BY.SITE);

    const [dateRange, setDateRange] = useState(sentState?.dateRange || {
        from: new Date(),
        to: new Date(),
    });

    useEffect(() => {
        getVoluumCampaigns()(voluumDispatch);
    }, [voluumDispatch]);

    return (
        <>
            <Box>
                <h3>
                    <a sx={{mg: 5}} href="/reports/campaign">
                        ↩ Back to all campaigns
                    </a>
                </h3>
                <Typography component="h1" variant="h5" sx={{mb: 5}}>
                    <b>{groupBy}</b> report for TS <b>{tsAccountName}</b> campaign <b>{campaignId}</b><br/>
                    from <b>${dateToStr(dateRange.from)}</b> to <b>${dateToStr(dateRange.to)}</b>
                </Typography>
                <Grid container sx={{mb: 5}}>
                    <Grid item xs={3} justifyContent="left" sx={{display: "flext"}}>
                        <FormControl sx={{width: 300}}>
                            <InputLabel id="ts-account-label">Group By</InputLabel>
                            <Select
                                labelId="ts-account-label"
                                id="ts-account-label-select"
                                value={groupBy}
                                label="Group By"
                                onChange={(event) => setGroupBy(event.target.value)}
                            >
                                <MenuItem key={COMPLETE_REPORT_GROUP_BY.SITE}
                                          value={COMPLETE_REPORT_GROUP_BY.SITE}>
                                    {COMPLETE_REPORT_GROUP_BY.SITE}
                                </MenuItem>
                                <MenuItem key={COMPLETE_REPORT_GROUP_BY.ZONE}
                                          value={COMPLETE_REPORT_GROUP_BY.ZONE}>
                                    {COMPLETE_REPORT_GROUP_BY.ZONE}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <DateRangeFilter onDateRangeChange={setDateRange} dateRange={dateRange}/>
                <Grid container>
                    <Grid item xs={3} sx={{display: "flex"}}>
                        <FormControlLabel
                            value="3"
                            control={
                                <Checkbox
                                    checked={showAssignedOnly}
                                    onClick={(e) => setShowAssignedOnly(e.target.checked)}
                                    name="c3"
                                />
                            }
                            label="Show assigned only"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{display: "flex", justifyContent: "flex-end"}}
                    ></Grid>
                </Grid>
                {
                    groupBy === COMPLETE_REPORT_GROUP_BY.SITE
                    && (<SiteReportsTable tsAccountId={tsAccountId}
                                          campaignId={campaignId}
                                          showAssignedOnly={showAssignedOnly}
                                          dateRange={dateRange}
                    />)
                }
                {
                    groupBy === COMPLETE_REPORT_GROUP_BY.ZONE
                    && (<ZoneReportsTable tsAccountId={tsAccountId}
                                          campaignId={campaignId}
                                          showAssignedOnly={showAssignedOnly}
                                          dateRange={dateRange}
                    />)
                }
            </Box>

        </>
    );
}

export {CompleteReports};
