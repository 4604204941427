import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export function UsersDropdown({
                                  userId,
                                  tsAccountId,
                                  handleChangeUser,
                                  width,
                                  data,
                              }) {
    return (
        <>
            <FormControl sx={{width: width}}>
                <InputLabel id="user-email-label">USER</InputLabel>
                <Select
                    labelId="user-email-label"
                    id="user-name-label-select"
                    value={userId}
                    label="User's Email"
                    onChange={handleChangeUser}
                >
                    <MenuItem value={""}>
                        {" "}
                        <em>None</em>
                    </MenuItem>
                    {(data || []).filter(item => item.ts_account_id === tsAccountId).map((item, i) => {
                        return (
                            <MenuItem key={i} value={item.id}>
                                {item.email} ({item.role})
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
}
