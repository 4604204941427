import React from "react";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";

export function SelectCampaigns(props) {
    const {
        campaignsList,
        handleCheckboxClick,
        selectCampaign,
        allCampaignsChecked,
        checkedList,
        campaignType,
        handleCampaignType,
        costModel,
        handleCostModel,
        filter,
        handleFilterCampaignByName,
        selectedCampaignType,
        handleChangeAlgo,
        algorithms,
    } = props;
    return (
        <>
            <Grid container spacing={2} sx={{mb: 5, mt: 3}}>
                <Grid item xs={4}>
                    <FormControl required fullWidth>
                        <InputLabel id="campaign-type-select-label">Algorithm</InputLabel>
                        <Select
                            required
                            labelId="campaign-type-select-label"
                            id="campaign-type-select"
                            value={selectedCampaignType}
                            defaultValue=""
                            label="Algorithm Type"
                            onChange={handleChangeAlgo}
                        >
                            <MenuItem value={""}>
                                {" "}
                                <em>None</em>
                            </MenuItem>
                            {(algorithms || []).map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2} justifyContent="center" sx={{display: "flex"}}>
                    <FormControl fullWidth>
                        <InputLabel id="campaign-type-select-label">
                            Campaign Type
                        </InputLabel>
                        <Select
                            required
                            labelId="campaign-type-select-label"
                            id="campaign-type-select"
                            value={campaignType}
                            label="Campaign Type"
                            onChange={handleCampaignType}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="SEED">SEED</MenuItem>
                            <MenuItem value="WL">WL</MenuItem>
                            <MenuItem value="SL">SL</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} justifyContent="center" sx={{display: "flex"}}>
                    <FormControl fullWidth>
                        <InputLabel id="cost-model-select-label">Cost Model</InputLabel>
                        <Select
                            required
                            labelId="cost-model-select-label"
                            id="cost-model"
                            value={costModel}
                            label="Cost Model"
                            onChange={handleCostModel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="CPC">CPC</MenuItem>
                            <MenuItem value="CPM">CPM</MenuItem>
                            <MenuItem value="UNKNOWN">UNKNOWN</MenuItem>
                            <MenuItem value="NONE">NONE</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{display: "flex"}}>
                    <Grid item xs={4} sx={{display: "flex", mr: 46}}>
                        <TextField
                            fullWidth
                            onChange={handleFilterCampaignByName}
                            id="filter"
                            label="Filter campaign by name"
                            name="filter"
                            value={filter}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{display: "flex"}}>
                        <FormControlLabel
                            control={<Checkbox checked={allCampaignsChecked} onClick={selectCampaign}/>}
                            label=" Select all"
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>

                {campaignsList ? (
                    campaignsList.map((item) => (
                        <Grid key={item.id} item xs={12} sx={{display: "flex"}}>
                            <Tooltip title={item.name} placement="top">
                                <Box
                                    sx={{
                                        pt: 1,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: 940,
                                        whiteSpace: "nowrap",
                                    }}
                                    variant="h5"
                                    color="text.secondary"
                                >
                                    {item.name}
                                </Box>
                            </Tooltip>

                            <Checkbox
                                value={item.id}
                                name={item.id.toString()}
                                checked={checkedList.includes(item.id)}
                                onChange={handleCheckboxClick}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        </Grid>
                    ))
                ) : (
                    <Skeleton variant="rectangular" height={210}/>
                )}
            </Grid>
        </>
    );
}
