export const initialBlackListZoneState = {
    blackListZone: {
        loading: false,
        error: null,
        data: [],
    },
    addBlackListZone: {
        loading: false,
        error: null,
        data: null,
    },

    blackListSites: {
        loading: false,
        error: null,
        data: [],
    },
    addBlackListSite: {
        loading: false,
        error: null,
        data: null,
    },
};
