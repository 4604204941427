import * as React from "react";
import {Link, useMatch, useResolvedPath,} from "react-router-dom";
import ListItem from "@mui/material/ListItem";

export function CustomLink({children, to, ...props}) {
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname, end: true});
    return (
        <div>
            <ListItem
                button
                component={Link}
                style={{textDecoration: match ? "underline" : "none"}}
                to={to}
                {...props}
            >
                {children}
            </ListItem>
        </div>
    );
}
