export const initialAlgorithmsState = {
    algorithmsTypes: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },

    algorithmsList: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },

    addAlgorithms: {
        loading: false,
        error: null,
        data: null,
    },

    algorithmsName: {
        loading: false,
        error: false,
        message: null,
        data: null,
    },
    defaultAlgorithms: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },

    addDefaultAlgorithms: {
        loading: false,
        error: null,
        data: null,
    },
};
