import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {GlobalContext} from "../context/Provider";
import {getRemoteTS, getRemoteVoluum,} from "../context/actions/events/events";

function CreateEvent(props) {
    const {
        recordForEdit,
        listEvents,
        handleCloseCreateEditDialog,
        handleCloseDeleteDialog,
        tsAccounts,
        createAndEditEvents,
        tsAccountSelected
    } = props;

    const {
        eventDispatch,
        eventState,
    } = useContext(GlobalContext);

    const {eventsTsList, eventsVoluumList} = eventState

    const [name, setName] = useState("");

    const [tsAccount, setTsAccount] = useState("");

    const [eventVoluum, setEventVoluum] = useState("");

    const [source, setSource] = useState("");
    const [eventTs, setEventTs] = useState("");

    function findMissingNumber(arr) {
        const N = arr.length;
        let i;
        let temp = [];
        for (i = 0; i <= N; i++) {
            temp[i] = 0;
        }

        for (i = 0; i < N; i++) {
            temp[arr[i] - 1] = 1;
        }

        let ans = 0;
        for (i = 0; i <= N; i++) {
            if (temp[i] === 0)
                ans = i + 1;
        }
        return ans;
    }

    const nextGNumberForAccount = (requiredTsAccountId) => {
        // let toReturn = {};
        const existingGNumbersForAccount = listEvents
            .filter(element => element.ts_account_id === requiredTsAccountId)
            .map(element => parseInt(element.name?.replace(/[^0-9]/g, '')));

        if (existingGNumbersForAccount.length === 0) {
            return 1;
        }

        return findMissingNumber(existingGNumbersForAccount);
    }


    useEffect(() => {
        if (tsAccountSelected !== null) {
            setTsAccount(tsAccountSelected)
        }
    }, [tsAccountSelected]);

    useEffect(() => {
        if (recordForEdit) {
            if (recordForEdit.name !== null) {
                setName(recordForEdit.name)
            }
            if (recordForEdit.source !== null) {
                setSource(recordForEdit.source)
            }
            if (recordForEdit.ts_account_id !== null) {
                setTsAccount(recordForEdit.ts_account_id)
            }
            if (recordForEdit.remote_id !== null) {
                if (source === 'TS') {
                    setEventTs(recordForEdit.remote_id)
                }
                if (source === 'VOLUUM') {
                    setEventVoluum(recordForEdit.remote_id)
                }
            }
        }
    }, [recordForEdit, source]);


    useEffect(() => {
        if (source === 'TS') {
            getRemoteTS(tsAccount)(eventDispatch)
        }
        if (source === "VOLUUM") {
            getRemoteVoluum()(eventDispatch)
        }
    }, [tsAccount, source, eventDispatch]);


    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
        let nextGNumber = nextGNumberForAccount(event.target.value);
        setName(`G${nextGNumber}`)
    };

    const handleChangeEventVoluum = (event) => {
        setEventVoluum(event.target.value);
    };

    const handleChangeEventTs = (event) => {
        setEventTs(event.target.value);
    };

    const handleChangeSource = (event) => {
        setSource(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let remoteId
        if (source === 'TS') {
            remoteId = eventTs
        } else {
            remoteId = eventVoluum
        }

        let data

        if (!!recordForEdit) {
            data = {
                id: recordForEdit.id,
                name: name,
                remote_id: remoteId,
                ts_account_id: tsAccount,
                source: source,
                created_on: recordForEdit.created_on,
                created_by: recordForEdit.created_by
            }
        } else {
            data = {
                name: name,
                remote_id: remoteId,
                ts_account_id: tsAccount,
                source: source
            }
        }

        createAndEditEvents(data)
    };

    return (
        <Container component="main" maxWidth="xs">
            {recordForEdit ? (
                <Typography component="h1" variant="h5">
                    Update Event
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create Event
                </Typography>
            )}

            <Box
                sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ts-account-label">TS ACCOUNT</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    id="ts-account-label-select"
                                    value={tsAccount}
                                    label="TS ACCOUNT"
                                    onChange={handleChangeTsAccount}
                                >
                                    {(tsAccounts || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                onChange={handleChangeName}
                                name="name"
                                label="Name"
                                type="text"
                                id="name"
                                value={name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="campaign-type-select-label">
                                    Source
                                </InputLabel>
                                <Select
                                    required
                                    labelId="campaign-type-select-label"
                                    id="campaign-type-select"
                                    value={source}
                                    label="Campaign Type"
                                    onChange={handleChangeSource}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="TS">TS</MenuItem>
                                    <MenuItem value="VOLUUM">VOLUUM</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            source === "TS" && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="ts-account-label">Event Ts</InputLabel>
                                        <Select
                                            required
                                            labelId="ts-account-label"
                                            id="ts-account-label-select"
                                            value={eventTs}
                                            label="Event Ts"
                                            onChange={handleChangeEventTs}
                                        >
                                            {(eventsTsList.data || []).map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }
                        {
                            source === "VOLUUM" && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="ts-account-label">Event volumm</InputLabel>
                                        <Select
                                            required
                                            labelId="ts-account-label"
                                            id="ts-account-label-select"
                                            value={eventVoluum}
                                            label="Event volumm"
                                            onChange={handleChangeEventVoluum}
                                        >
                                            {(eventsVoluumList.data || []).map((item, i) => {
                                                return (
                                                    <MenuItem key={i} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }
                    </Grid>


                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export {CreateEvent};
