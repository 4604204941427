import MaterialUIPickers from "../../components/MaterialUIPickers";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

export default function DateRangeFilter(props) {
    const {
        dateRange,
        onDateRangeChange
    } = props;
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);


    const handleDateFromChange = (date) => {
        if (date > dateRange.to) {
            date=dateRange.to;
        }
        const newRange ={
            from: date,
            to: dateRange.to,
        };
         onDateRangeChange(newRange)
    };
    const handleDateToChange = (date) => {
        if (date < dateRange.from) {
            date=dateRange.from;
        }
        const newRange ={
            from: dateRange.from,
            to: date,
        };
        onDateRangeChange(newRange)
    };

    return (<Grid container>
        <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "40%", flexBasis: "30%"}}}>
            <MaterialUIPickers
                handleDateChange={handleDateFromChange}
                value={dateRange.from}
                label="Date From in UTC"
            />
        </Grid>
        <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "40%", flexBasis: "30%"}}}>
            <MaterialUIPickers
                handleDateChange={handleDateToChange}
                value={dateRange.to}
                label="Date To in UTC"
            />
        </Grid>
        <Grid container sx={{mb: 5}}>
            <ButtonGroup variant="text" aria-label="text button group">
                <Button
                    sx={{'@media (max-width: 1000px)': {minWidth: "50px !important"}}}
                    size="small"
                    onClick={() => {
                        onDateRangeChange({
                            from: today,
                            to: today,
                        })
                    }}
                >
                    Today
                </Button>
                <Button
                    sx={{'@media (max-width: 1000px)': {minWidth: "80px !important"}}}
                    size="small"
                    onClick={() => {
                        onDateRangeChange({
                            from: yesterday,
                            to: yesterday,
                        })
                    }}
                >
                    Yesterday
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(yesterday);
                        newDateFrom.setDate(yesterday.getDate() - 3);
                        onDateRangeChange({
                            from: newDateFrom,
                            to: yesterday,
                        })
                    }}
                >
                    Last 3 days
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(yesterday);
                        newDateFrom.setDate(yesterday.getDate() - 7);
                        onDateRangeChange({
                            from: newDateFrom,
                            to: yesterday,
                        });
                    }}
                >
                    Last 7 days
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(yesterday);
                        newDateFrom.setDate(yesterday.getDate() - 30);
                        onDateRangeChange({
                            from: newDateFrom,
                            to: yesterday,
                        });
                    }}
                >
                    Last 30 days
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(today);
                        newDateFrom.setDate(today.getDate() - (today.getDay() - 1) - 7);
                        const newDateTo = new Date(newDateFrom);
                        newDateTo.setDate(newDateFrom.getDate() + 6);
                        onDateRangeChange({
                            from: newDateFrom,
                            to: newDateTo,
                        });
                    }}
                >
                    Last Week
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(today);
                        newDateFrom.setDate(today.getDate() - (today.getDay() - 1));
                        onDateRangeChange({
                            from: newDateFrom,
                            to: today,
                        });
                    }}
                >
                    This Week
                </Button>
                <Button
                    size="small"
                    onClick={() => {
                        const newDateFrom = new Date(today);
                        newDateFrom.setDate(1);
                        onDateRangeChange({
                            from: newDateFrom,
                            to: today,
                        });
                    }}
                >
                    This Month
                </Button>
            </ButtonGroup>
        </Grid>
    </Grid>)
}
