import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {GlobalContext} from "../../context/Provider";
import {getTrafficSource} from "../../context/actions/traffic/trafficSource";

function CreateEditTsAccount(props) {
    const {
        addOrEdit,
        recordForEdit,
        handleCloseCreateEditDialog,
        children,
        handleCloseDeleteDialog,
    } = props;
    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);
    const {trafficSourceList} = tsAccountsState;

    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    const [tsAccount, setTsAccount] = useState({
        api_key: "",
        name: "",
        traffic_source_id: "",
    });

    useEffect(() => {
        if (recordForEdit) {
            const {id, ...rest} = recordForEdit;
            setTsAccount((prevState) => {
                return {...prevState, ...rest};
            });
        }
    }, [recordForEdit]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setTsAccount((inputs) => ({...inputs, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data;
        if (recordForEdit) {
            data = {
                id: recordForEdit.id,
                api_key: tsAccount.api_key,
                name: tsAccount.name,
                traffic_source_id: tsAccount.traffic_source_id,
                created_by: recordForEdit.created_by,
            };
        } else {
            data = {
                api_key: tsAccount.api_key,
                name: tsAccount.name,
                traffic_source_id: tsAccount.traffic_source_id,
            };
        }
        addOrEdit(data);
    };
    return (
        <Container component="main" maxWidth="xs">
            {recordForEdit ? (
                <Typography component="h1" variant="h5">
                    Update TS Account
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create TS Account
                </Typography>
            )}
            <Box
                sx={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={handleChange}
                                id="name"
                                label="Name"
                                name="name"
                                type="text"
                                value={tsAccount.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                onChange={handleChange}
                                fullWidth
                                name="api_key"
                                label="Api Key"
                                type="text"
                                id="api_key"
                                value={tsAccount.api_key}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    name="traffic_source_id"
                                    id="ts-account-label-select"
                                    value={tsAccount.traffic_source_id}
                                    label="Traffic Source"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {(trafficSourceList.data || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            {children}
        </Container>
    );
}

export {CreateEditTsAccount};
