export const initialVoluumState = {
    voluumCampaigns: {
        loading: false,
        error: null,
        data: [],
    },
    addVoluum: {
        loading: false,
        error: null,
        data: null,
    },
};
