import React, {useContext, useEffect} from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import {GlobalContext} from "../context/Provider";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {createSchedulingAction, deleteBiddingById, getSchedulingByID,} from "../context/actions/scheduling/scheduling";
import {ModalDelete} from "./bidSchedulingDeleteModal";
import TransitionAlerts from "../components/alert";
import LoadingSpinner from "./LoadingSpinner";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const boxStyle = {
    display: "flex",
};

const daysWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Every Day",
];
const enumWeek = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
};

function getStyles(name, daysInWeek, theme) {
    return {
        fontWeight:
            daysInWeek.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function BidScheduling({dataForEdit, handleCloseSchedulingModal}) {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [errorResponse, setErrorResponse] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [indexModal, setIndexModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [openAlertError, setOpenAlertError] = React.useState(false);

    const [dataTablesField, setDataTablesFields] = React.useState([]);

    const {tsAccountsState, reportsBidding, biddingDispatch} =
        useContext(GlobalContext);
    const handleAlertDialog = () => {
        setOpenAlertError((prevCheck) => !prevCheck);
    };
    const daysInWeek = [];
    const theme = useTheme();
    const {tsAccountsList} = tsAccountsState;

    const {biddingReport} = reportsBidding;
    useEffect(() => {
        getSchedulingByID(dataForEdit?.campaign_id)(biddingDispatch);
    }, [biddingDispatch, dataForEdit?.campaign_id]);

    useEffect(() => {
        setDataTablesFields(handleLoadData);
    }, [biddingReport?.data]);

    const tsAccount = tsAccountsList.data.find(
        (x) => x.id === dataForEdit?.ts_account_id
    );
    // Grab Last id
    const handleLoadData = () => {
        const fieldsData = [];
        let initData = {};
        biddingReport?.data?.forEach((item) => {
            let timeFrom = "2023-01-01 " + item?.time_from.substring(0, 5);
            let timeTo = "2023-01-01 " + item?.time_to.substring(0, 5);
            const weeksDays = [];
            item.days.forEach((day) => {
                weeksDays.push(daysWeek[day]);
            });
            if (item.type === "SET") {
                initData.bid = item.value;
                initData.increase = 0;
                initData.decrease = 0;
                initData.index = 0;
            } else if (item.type === "DECREASE") {
                initData.bid = 0;
                initData.index = 2;
                initData.increase = 0;
                initData.decrease = item.value;
            } else if (item.type === "INCREASE") {
                initData.bid = 0;
                initData.index = 1;
                initData.increase = item.value;
                initData.decrease = 0;
            }

            initData.created_by = item.created_by;
            initData.created_on = item.created_on;
            initData.id = item.id;
            initData.timeFrom = dayjs(timeFrom);
            initData.timeTo = dayjs(timeTo);
            initData.is_active = item.is_active;
            initData.is_enabled = item.is_enabled;
            initData.remote_campaign_id = item.remote_campaign_id;
            initData.ts_account_id = item.ts_account_id;
            initData.type = item.type;
            initData.weekDays = weeksDays;
            fieldsData.push(initData);
            initData = {};
        });

        return fieldsData;
    };

    const handleChangeDays = (event, index) => {
        const {
            target: {value},
        } = event;
        const days = typeof value === "string" ? value.split(",") : value;
        const copyArr = [...dataTablesField];
        copyArr[index].weekDays = days;
        if (copyArr[index].weekDays.length === 0) {
            copyArr[index].weekDays = ["Every Day"];
        }
        if (copyArr[index].weekDays.includes("Every Day")) {
            copyArr[index].weekDays = ["Every Day"];
        }
        if (days[0] === "Every Day" && days.length >= 2) {
            copyArr[index].weekDays = [days[1]];
            setDataTablesFields(copyArr);
        }

        setDataTablesFields(copyArr);
    };

    const handleChangeBid = (e, index) => {
        const copyArr = [...dataTablesField];
        copyArr[index].bid = Number(e.target.value) || "";
        setDataTablesFields(copyArr);
    };
    const percentage = (num) => {
        if (num === 0) {
            num = 1;
        }
        return num / 10;
    };

    const handleDecreaseInput = (e, index) => {
        const resUpdate = [...dataTablesField];
        resUpdate[index].decrease = Number(e.target.value);
        resUpdate[index].type = "DECREASE";
        setDataTablesFields(resUpdate);
    };
    const handlePercentPriceInput = (e, index) => {
        const resUpdate = [...dataTablesField];
        resUpdate[index].increase = Number(e.target.value);
        resUpdate[index].type = "INCREASE";

        setDataTablesFields(resUpdate);
    };

    const handleSetBid = (item, index) => (
        <Box style={{display: "flex", flexDirection: "column"}}>
            <TextField
                sx={{mt: 5, maxWidth: 150, mr: 1}}
                fullWidth
                type="number"
                onChange={(e) => handleChangeBid(e, index)}
                id={`changeBid${index}`}
                label="Change Campaign Bid"
                name="changeBid"
                value={item.bid}
            />
            <Box>
                <Button
                    variant="text"
                    style={{fontSize: "25px"}}
                    onClick={() => handleAdd10Percent(index)}
                >
                    +
                </Button>
                <Button
                    variant="text"
                    style={{fontSize: "25px"}}
                    onClick={() => handleRemove10Percent(index)}
                >
                    -
                </Button>
            </Box>
        </Box>
    );
    const increasePercent = (item, index) => {
        return (
            <Box style={{display: "flex", flexDirection: "column"}}>
                <TextField
                    sx={{mt: 5, maxWidth: 150, mr: 1}}
                    type="number"
                    fullWidth
                    onChange={(e) => handlePercentPriceInput(e, index)}
                    id={`changeBid${index}`}
                    label="Increase Percent"
                    name="changeBid"
                    value={item.increase}
                />
                <Box>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleAdd10PercentPrice(index, false)}
                    >
                        +
                    </Button>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleRemove10PercentPrice(index, false)}
                    >
                        -
                    </Button>
                </Box>
            </Box>
        );
    };
    const decreasePercent = (item, index) => {
        return (
            <Box style={{display: "flex", flexDirection: "column"}}>
                <TextField
                    sx={{mt: 5, maxWidth: 150, mr: 1}}
                    type="number"
                    fullWidth
                    onChange={(e) => handleDecreaseInput(e, index)}
                    id={`changeBid${index}`}
                    label="Decrease Percent"
                    name="changeBid"
                    value={item.decrease}
                />
                <Box>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleAdd10PercentDecrease(index, false)}
                    >
                        +
                    </Button>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => handleRemove10PercentDecrease(index, false)}
                    >
                        -
                    </Button>
                </Box>
            </Box>
        );
    };

    const handleAdd10Percent = (index) => {
        const dataBid = parseFloat(dataTablesField[index]?.bid);
        const percent = percentage(dataBid);
        const res = parseFloat(dataBid + percent);

        const resUpdate = [...dataTablesField];
        const updateBid = (resUpdate[index].bid = res);
        resUpdate[index].bid = updateBid.toFixed(4);

        setDataTablesFields(resUpdate);
    };

    const handleRemove10Percent = (index) => {
        const dataBid = dataTablesField[index]?.bid;

        const percent = percentage(dataBid);
        const res = parseFloat(dataBid - percent);
        const resUpdate = [...dataTablesField];
        const updateBid = (resUpdate[index].bid = res);

        resUpdate[index].bid = updateBid.toFixed(4);

        setDataTablesFields(resUpdate);
    };

    const handleAdd10PercentPrice = (index) => {
        const resUpdate = [...dataTablesField];

        const updatePercent = (resUpdate[index].increase += 10);
        if (updatePercent < 0) {
            resUpdate[index].increase = 0;
        } else {
            resUpdate[index].increase = updatePercent;
        }
        setDataTablesFields(resUpdate);
    };
    const handleRemove10PercentPrice = (index) => {
        const resUpdate = [...dataTablesField];

        const updatePercent = (resUpdate[index].increase -= 10);

        if (updatePercent < 0) {
            resUpdate[index].increase = 0;
            setDataTablesFields(resUpdate);
            return;
        }
        resUpdate[index].increase = updatePercent;
        setDataTablesFields(resUpdate);
    };
    const handleAdd10PercentDecrease = (index) => {
        const resUpdate = [...dataTablesField];
        const updatePercent = (resUpdate[index].decrease += 10);
        if (updatePercent < 0) {
            resUpdate[index].decrease = 0;
            setDataTablesFields(resUpdate);
            return;
        }
        resUpdate[index].decrease = updatePercent;
        setDataTablesFields(resUpdate);
    };
    const handleRemove10PercentDecrease = (index) => {
        const resUpdate = [...dataTablesField];
        const updatePercent = (resUpdate[index].decrease -= 10);
        if (updatePercent < 0) {
            resUpdate[index].decrease = 0;
            setDataTablesFields(resUpdate);
            return;
        }
        resUpdate[index].decrease = updatePercent;
        setDataTablesFields(resUpdate);
    };
    const handleTabMenu = (selected, item, index) => {
        const resUpdate = [...dataTablesField];

        if (selected === 0) {
            resUpdate[index].type = "SET";
            return handleSetBid(item, index);
        }
        if (selected === 1) {
            resUpdate[index].type = "INCREASE";
            return increasePercent(item, index);
        }
        if (selected === 2) {
            resUpdate[index].type = "DECREASE";
            return decreasePercent(item, index);
        }
    };
    const handleSelectedIndexTab = (index, val) => {
        const resUpdate = [...dataTablesField];
        resUpdate[index].index = val;
        setDataTablesFields(resUpdate);
    };
    const handleTimeFrom = (timeFrom, index) => {
        const resUpdate = [...dataTablesField];
        resUpdate[index].timeFrom = timeFrom;
        setDataTablesFields(resUpdate);
    };
    const handleTimeTo = (timeTo, index) => {
        const resUpdate = [...dataTablesField];
        resUpdate[index].timeTo = timeTo;
        setDataTablesFields(resUpdate);
    };
    const handlePutSchedulingBid = () => {
        let tableFiled = {};
        let apiData = [];
        dataTablesField.forEach((tableData) => {
            let daysArr = [];
            tableData.weekDays.forEach((item) => {
                if (item === "Every Day") {
                    daysArr.push(0, 1, 2, 3, 4, 5, 6);
                } else {
                    daysArr.push(enumWeek[item]);
                }
            });
            if (tableData.type === "SET") {
                tableFiled.value = tableData.bid;
                tableFiled.type = tableData.type;
            } else if (tableData.type === "INCREASE") {
                tableFiled.value = tableData.increase;
                tableFiled.type = tableData.type;
            } else if (tableData.type === "DECREASE") {
                tableFiled.value = tableData.decrease;
                tableFiled.type = tableData.type;
            }
            tableFiled.remote_campaign_id = tableData.remote_campaign_id;
            tableFiled.ts_account_id = tableData.ts_account_id;
            tableFiled.days = daysArr;
            tableFiled.time_from = String(tableData.timeFrom.$d).substring(16, 21);
            tableFiled.time_to = String(tableData.timeTo.$d).substring(16, 21);
            tableFiled.id = tableData?.id;
            tableFiled.is_enabled = tableData.is_enabled;
            tableFiled.is_active = tableData.is_active;
            tableFiled.timezone_offset_in_minutes = 0;
            apiData.push(tableFiled);
            daysArr = [];
            tableFiled = {};
        });
        const putData = createSchedulingAction(apiData, dataForEdit?.campaign_id);
        putData.then((response) => {
            if (response?.detail) {
                if (response?.detail[0]?.msg) {
                    setErrorResponse(response?.detail[0]?.msg);
                    setOpenAlertError(true);
                } else {
                    setOpenAlertError(true);
                    setErrorResponse(response?.detail);
                }
            } else {
                setErrorResponse(response);
                handleCloseSchedulingModal();
            }
        });
    };
    const handleRemoveRecord = () => {
        const resUpdate = [...dataTablesField];
        resUpdate.splice(indexModal, 1);
        setDataTablesFields(resUpdate);
    };
    return (
        <div>
            <Button
                sx={{mt: 2, mb: 2}}
                onClick={handleCloseSchedulingModal}
                color="warning"
                startIcon={<ChevronLeftSharpIcon/>}
            >
                Back
            </Button>
            <Alert severity="info">
                Campaign: {dataForEdit.campaign_id}, Current Bid: {dataForEdit?.bid}, TS
                Account: {tsAccount?.name}
            </Alert>
            {openAlertError ? (
                <TransitionAlerts
                    title={errorResponse}
                    handleAlertDialog={handleAlertDialog}
                />
            ) : null}
            {biddingReport?.loading ? <LoadingSpinner/> : null}

            {dataTablesField.length === 0 ? (
                <InputLabel style={{textAlign: "center", margin: "3rem"}} id="nodata">
                    There's nothing scheduled for this campaign yet
                </InputLabel>
            ) : null}
            {dataTablesField.map((item, index) => {
                return (
                    <Box
                        style={{
                            border: "1px solid cornflowerblue",
                            borderRadius: "5px",
                            padding: "10px 40px",
                        }}
                        key={index}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "end",
                            }}
                        >
                            <Box>
                                <Button
                                    sx={{mt: 2, mb: 2, mr: 1}}
                                    onClick={() => {
                                        handleSelectedIndexTab(index, 0);
                                    }}
                                    style={{
                                        backgroundColor: item.index === 0 ? "lightblue" : "inherit",
                                    }}
                                >
                                    Set Value
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: item.index === 1 ? "lightblue" : "inherit",
                                    }}
                                    sx={{mt: 2, mb: 2}}
                                    onClick={() => {
                                        handleSelectedIndexTab(index, 1);
                                    }}
                                >
                                    Increase
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: item.index === 2 ? "lightblue" : "inherit",
                                    }}
                                    sx={{mt: 2, mb: 2}}
                                    onClick={() => {
                                        handleSelectedIndexTab(index, 2);
                                    }}
                                >
                                    Decrease
                                </Button>
                            </Box>
                        </Grid>
                        <Box sx={{display: "flex"}}>
                            <Grid>{handleTabMenu(item.index, item, index)}</Grid>

                            <Box style={boxStyle}>
                                <Grid sx={{mt: 5, mr: 1}}>
                                    <FormControl sx={{width: 300}}>
                                        <InputLabel id="demo-multiple-chip-label">
                                            Days in Week
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={item?.weekDays || []}
                                            onChange={(e) => handleChangeDays(e, index)}
                                            input={
                                                <OutlinedInput
                                                    id="select-multiple-chip"
                                                    label="Days in Week"
                                                />
                                            }
                                            renderValue={(selected) => {
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            gap: 0.5,
                                                        }}
                                                    >
                                                        {selected.map((value) => (
                                                            <Box key={value}>
                                                                <Chip key={value} label={value}/>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                );
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {daysWeek.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                    style={getStyles(name, daysInWeek, theme)}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid sx={{mt: 5, mr: 1}} style={{width: "150px"}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            renderInput={(params) => <TextField {...params} />}
                                            label="Time From in UTC"
                                            value={item.timeFrom}
                                            onChange={(newValue) => {
                                                handleTimeFrom(newValue, index);
                                            }}
                                            shouldDisableTime={(timeValue, clockType) => {
                                                if (clockType === "minutes" && timeValue % 3) {
                                                    return true;
                                                }
                                                return false;
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid sx={{mt: 5, mr: 1}} style={{width: "150px"}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            renderInput={(params) => <TextField {...params} />}
                                            label="Time To in UTC"
                                            value={item.timeTo}
                                            onChange={(newValue) => {
                                                handleTimeTo(newValue, index);
                                            }}
                                            shouldDisableTime={(timeValue, clockType) => {
                                                if (clockType === "minutes" && timeValue % 3) {
                                                    return true;
                                                }
                                                return false;
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Box>
                            <Grid
                                sx={{mt: 5}}
                                style={{
                                    width: "150px",
                                    marginLeft: "auto",
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: "indianred",
                                        color: "#fff",
                                        float: "right",
                                    }}
                                    onClick={() => {
                                        handleOpenModal();
                                        setIndexModal(index);
                                    }}
                                >
                                    {" "}
                                    Delete
                                </Button>
                            </Grid>
                        </Box>
                        <ModalDelete
                            key={index}
                            openModal={openModal}
                            handleCloseModal={handleCloseModal}
                            handleRemoveRecords={() => handleRemoveRecord(index)}
                            deleteBiddingById={deleteBiddingById}
                            item={item}
                            biddingDispatch={biddingDispatch}
                        />
                    </Box>
                );
            })}
            <Box sx={{mt: 5, mb: 5}}>
                <Button
                    onClick={() => {
                        setDataTablesFields((current) => [
                            ...current,

                            {
                                bid: dataForEdit?.bid || 1,
                                increase: 0,
                                id: null,
                                decrease: 0,
                                remote_campaign_id: dataForEdit?.campaign_id,
                                weekDays: ["Monday"],
                                index: 0,
                                type: "SET",
                                is_active: false,
                                ts_account_id: dataForEdit.ts_account_id,
                                timeTo: dayjs("2023-01-01 12:00"),
                                timeFrom: dayjs("2023-01-01 12:00"),
                            },
                        ]);
                    }}
                >
                    Add New Scheduling
                </Button>
                <Button
                    style={{float: "right", width: "100px"}}
                    sx={{mt: 2, mb: 2}}
                    onClick={() => {
                        handlePutSchedulingBid();
                    }}
                    color="success"
                >
                    Save
                </Button>
                <Button
                    style={{float: "right"}}
                    sx={{mt: 2, mb: 2}}
                    onClick={handleCloseSchedulingModal}
                    color="warning"
                >
                    Cancel
                </Button>
            </Box>
        </div>
    );
}

export default BidScheduling;
