import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import {Tooltip} from "@mui/material";

import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";

import MaterialUIPickers from "../components/MaterialUIPickers";
import {CustomToolbar} from "../components/customToolbar";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {GlobalContext} from "../context/Provider";
import {getTotalReport} from "../context/actions/reports/reports";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";

import Container from "@mui/material/Container";
import LoadingSpinner from "../components/LoadingSpinner";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function CustomReports() {
    const user = JSON.parse(localStorage.getItem("user"));

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const {reportsDispatch, reportsState} = useContext(GlobalContext);

    const {
        totalReport: {data, loading},
    } = reportsState;
    const [dateFrom, setDateFrom] = React.useState(today);
    const [dateTo, setDateTo] = React.useState(today);
    const [title, setTitle] = useState(
        `Total Report from ${dateFrom.toISOString().substring(0, 10)} to ${dateTo
            .toISOString()
            .substring(0, 10)}`
    );
    const [tsAccount, setTsAccount] = useState("");

    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);

    const {tsAccountsList} = tsAccountsState;

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        document.title = title;
        getTotalReport(
            dateFrom.toISOString().substring(0, 10),
            dateTo.toISOString().substring(0, 10),
            tsAccount
        )(reportsDispatch);
    }, [title, dateTo, reportsDispatch, dateFrom, tsAccount]);

    const handleDateFromChange = (date) => {
        if (date > dateTo) {
            setDateFrom(dateTo);
            return;
        }
        setDateFrom(date);
        setTitle(
            `Total reports from ${dateFrom.toISOString().substring(0, 10)} to ${dateTo
                .toISOString()
                .substring(0, 10)}`
        );
    };

    const handleDateToChange = (date) => {
        if (date < dateFrom) {
            setDateTo(dateFrom);
            return;
        }
        setDateTo(date);
        setTitle(
            `Total reports from ${dateFrom.toISOString().substring(0, 10)} to ${dateTo
                .toISOString()
                .substring(0, 10)}`
        );
    };

    const columns = [
        {
            field: "ts_account_id",
            headerName: "TS Account",
            width: 120,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
            filterable: false,
        },
        {
            field: "date",
            headerName: "Date",
            width: 100,
        },
        {
            field: "cost",
            headerName: "Cost",
            width: 80,
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "revenue",
            headerName: "Revenue",
            width: 90,
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "profit",
            headerName: "Profit",
            width: 80,
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "conversions",
            headerName: "Conversions",
            width: 80,
            type: "number",
        },
        {
            field: "impressions",
            headerName: "Impressions",
            width: 110,
            type: "number",
        },
        {
            field: "clicks",
            headerName: "Clicks",
            width: 80,
            type: "number",
        },
        {
            field: "roi",
            headerName: "ROI",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                return params?.value + "%";
            },
        },
        {
            field: "ap",
            headerName: "AP",
            width: 70,
            type: "number",
        },
        {
            field: "new_campaigns_assigned",
            headerName: "Assigned Campaigns",
            width: 170,
            type: "number",
        },
        {
            field: "new_campaigns_created",
            headerName: "Created Campaigns",
            width: 170,
            type: "number",
        },
        {
            field: "G1",
            headerName: "G1",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G1 ? params.row.G1 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G1}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G2",
            headerName: "G2",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G2 ? params.row.G2 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G2}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G3",
            headerName: "G3",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G3 ? params.row.G3 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G3}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G4",
            headerName: "G4",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G4 ? params.row.G4 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G4}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G5",
            headerName: "G5",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G5 ? params.row.G5 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G5}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "G6",
            headerName: "G6",
            width: 30,
            type: "number",
            renderCell: (params) => (
                <Tooltip title={params.row.G6 ? params.row.G6 : ""}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.G6}
          </span>
                </Tooltip>
            ),
        },
    ];

    return (
        <Container>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Total Report
            </Typography>
            <Grid container sx={{mb: 5}}>
                {(user.role === "ADMIN" || user.role === "SUPERADMIN") && (
                    <Grid item xs={3} justifyContent="left" sx={{display: "flex"}}>
                        <TrafficSourceAccountDropdown
                            tsAccount={tsAccount}
                            data={tsAccountsList.data}
                            width={300}
                            handleChangeTsAccount={handleChangeTsAccount}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "40%", flexBasis: "30%"}}}>
                    <MaterialUIPickers
                        handleDateChange={handleDateFromChange}
                        value={dateFrom}
                        label="Date From in UTC"
                    />
                </Grid>
                <Grid item xs={3} sx={{'@media (max-width: 800px)': {maxWidth: "40%", flexBasis: "30%"}}}>
                    <MaterialUIPickers
                        handleDateChange={handleDateToChange}
                        value={dateTo}
                        label="Date To in UTC"
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>
            <Grid container sx={{mb: 5}}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                        sx={{'@media (max-width: 1000px)' : {minWidth: "50px !important"}}}
                        size="small"
                        onClick={() => {
                            setDateFrom(today);
                            setDateTo(today);
                        }}
                    >
                        Today
                    </Button>
                    <Button
                        sx={{'@media (max-width: 1000px)' : {minWidth: "80px !important"}}}
                        size="small"
                        onClick={() => {
                            setDateFrom(yesterday);
                            setDateTo(yesterday);
                        }}
                    >
                        Yesterday
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const dateFrom = new Date(yesterday);
                            dateFrom.setDate(yesterday.getDate() - 2);
                            setDateFrom(dateFrom);
                            setDateTo(yesterday);
                        }}
                    >
                        Last 3 days
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const dateFrom = new Date(yesterday);
                            dateFrom.setDate(yesterday.getDate() - 7);
                            setDateFrom(dateFrom);
                            setDateTo(yesterday);
                        }}
                    >
                        Last 7 days
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const dateFrom = new Date(yesterday);
                            dateFrom.setDate(yesterday.getDate() - 30);
                            setDateFrom(dateFrom);
                            setDateTo(yesterday);
                        }}
                    >
                        Last 30 days
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1) - 7);
                            const dateTo = new Date(dateFrom);
                            dateTo.setDate(dateFrom.getDate() + 6);
                            setDateFrom(dateFrom);
                            setDateTo(dateTo);
                        }}
                    >
                        Last Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(today.getDate() - (today.getDay() - 1));
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Week
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            const today = new Date();
                            const dateFrom = new Date(today);
                            dateFrom.setDate(1);
                            setDateFrom(dateFrom);
                            setDateTo(today);
                        }}
                    >
                        This Month
                    </Button>
                </ButtonGroup>
            </Grid>
            {data && !loading ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--positive": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.success.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--neutral": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.warning.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--negative": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid
                        rows={data}
                        columns={columns}
                        getRowClassName={(params) => {
                            if (params.row.profit < 0) {
                                return `super-app-theme--negative`;
                            }
                            if (params.row.profit > 0) {
                                return `super-app-theme--positive`;
                            }
                        }}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                    />
                </Box>
            ) : (
                <LoadingSpinner/>
            )}
        </Container>
    );
}

export {CustomReports};
