import React from "react";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";

export function SelectCampaigns(props) {
    const {
        campaignsList,
        handleCheckboxClick,
        selectCampaign,
        allCampaignsChecked,
        selectedCampaignsIdsList,
        costModel,
        handleCostModel,
        filterByName,
        handleFilterCampaignByName,
        campaignType,
        handleChangeCampaignType,
        campaignTypes,
        campaignTypeToSet,
        handleChangeCampaignTypeToSet
    } = props;
    return (
        <>
            <Grid container spacing={2} sx={{mb: 5, mt: 3}}>
                <Grid item xs={12}>
                    <FormControl required fullWidth>
                        <InputLabel id="campaign-type-select-label">Campaign Type To Set</InputLabel>
                        <Select
                            required
                            labelId="campaign-type-select-label"
                            id="campaign-type-select"
                            value={campaignTypeToSet}
                            defaultValue=""
                            label="Campaign Type"
                            onChange={handleChangeCampaignTypeToSet}
                        >
                            {(campaignTypes || []).map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <h3>Filters</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="campaign-type-select-label">Campaign Type</InputLabel>
                        <Select
                            labelId="campaign-type-select-label"
                            id="campaign-type-select"
                            value={campaignType}
                            defaultValue=""
                            label="Campaign Type"
                            onChange={handleChangeCampaignType}
                        >
                            <MenuItem value={""}>
                                {" "}
                                <em>None</em>
                            </MenuItem>
                            {(campaignTypes || []).map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="cost-model-select-label">Cost Model</InputLabel>
                        <Select
                            required
                            labelId="cost-model-select-label"
                            id="cost-model"
                            value={costModel}
                            label="Cost Model"
                            onChange={handleCostModel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="CPC">CPC</MenuItem>
                            <MenuItem value="CPM">CPM</MenuItem>
                            <MenuItem value="UNKNOWN">UNKNOWN</MenuItem>
                            <MenuItem value="NONE">NONE</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        onChange={handleFilterCampaignByName}
                        id="filter"
                        label="Filter campaign by name"
                        name="filter"
                        value={filterByName}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 5, mt: 3}}  justifyContent="flex-end">
                <Grid item xs={7.5}>
                    <h3>Select Campaigns</h3>
                </Grid>
                <Grid item xs={4.5}>
                    <FormControlLabel
                        control={<Checkbox checked={allCampaignsChecked} onClick={selectCampaign}/>}
                        label="All"
                        labelPlacement="start"
                    />
                </Grid>
                {campaignsList ? (
                    campaignsList.map((item) => (
                        <Grid key={item.id} item xs={12} sx={{display: "flex"}}>
                            <Grid item xs={8}>
                                <Tooltip title={item.name} placement="top">
                                    <Box
                                        sx={{
                                            pt: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: 940,
                                            whiteSpace: "nowrap",
                                        }}
                                        variant="h5"
                                        color="text.secondary"
                                    >
                                        {item.name}
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    value={item.id}
                                    name={item.id.toString()}
                                    checked={selectedCampaignsIdsList.includes(item.id)}
                                    onChange={handleCheckboxClick}
                                    inputProps={{"aria-label": "controlled"}}
                                />
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Skeleton variant="rectangular" height={210}/>
                )}
            </Grid>
        </>
    );
}
