export const initialTsAccountsState = {
    tsAccountsList: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },
    addTsAccount: {
        loading: false,
        error: null,
        message: null,
        data: null,
    },
    trafficSourceList: {
        loading: false,
        error: false,
        message: null,
        data: [],
    },
};
