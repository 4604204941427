import React, {useContext, useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import {
    getAssignAlgorithms,
    getCampaignById,
    getCampaignDefaultAlgorithms,
    getRemoteDataCampaign,
    resetAssignAlgorithmsCampaign,
    resetMessageRemoteData,
} from "../context/actions/campaign/campaign";
import {GlobalContext} from "../context/Provider";
import useDebounce from "../helpers/debounce";
import TransitionAlerts from "../components/alert";
import {getAlgorithms} from "../context/actions/algorithms/algorithms";
import AlgoCPC from "../components/algoCPC";
import AlgoCPM from "../components/algoCPM";
import {Alert} from "@mui/material";

function CreateCampaign(props) {
    const {
        recordCreated,
        apiError,
        addOrEdit,
        recordForEdit,
        handleCloseCreateEditDialog,
        children,
        handleCloseDeleteDialog,
        tsAccounts,
        voluumCampaigns,
        isFromReport,
    } = props;
    const {
        campaignDispatch,
        campaignState,
        algorithmsState,
        algorithmsDispatch,
    } = useContext(GlobalContext);

    const {
        remoteData: {data, message, error},
        assignAlgoCampaign,
        getCampaignDefaultAlgorithmsState,
        campaign,
    } = campaignState;

    const {algorithmsList} = algorithmsState;
    const [epa, setEpa] = useState(4);
    const [remoteData, setRemoteData] = useState({
        name: "",
        status: "",
        cost_model: "",
        daily_budget: "",
        daily_budget_reset: "",
    });
    const [dropdownsCPC, setDropdownsCPC] = useState({
        BLACKLIST_ZONE_CPC: "",
        BLACKLIST_SITE_CPC: "",
        WHITELIST_ZONE_CPC: "",
        WHITELIST_SITE_CPC: "",
        SHORTLIST_ZONE_CPC: "",
        SHORTLIST_SITE_CPC: "",
        BLACKLIST_VARIATION_CPC: "",
        PAUSE_CAMPAIGN_LAST_7_DAYS_CPC: "",
        PAUSE_CAMPAIGN_LAST_30_DAYS_CPC: "",
        ADD_MORE_VARIATIONS_CPC: "",
        INCREASE_DAILY_BUDGET_CPC: "",
        DECREASE_DAILY_BUDGET_CPC: "",
        BIDDING_OPTIMIZATION: "",
        FACTOR_BROWSERS_BLACKLISTING: "",
        FACTOR_OS_BLACKLISTING: "",
        FACTOR_DEVICES_BLACKLISTING: "",
        FACTOR_DEVICE_TYPES_BLACKLISTING: ""
    });
    const [dropdownsCPM, setDropdownsCPM] = useState({
        BLACKLIST_ZONE_CPM: "",
        BLACKLIST_SITE_CPM: "",
        WHITELIST_ZONE_CPM: "",
        WHITELIST_SITE_CPM: "",
        SHORTLIST_ZONE_CPM: "",
        SHORTLIST_SITE_CPM: "",
        BLACKLIST_VARIATION_CPM: "",
        PAUSE_CAMPAIGN_LAST_7_DAYS_CPM: "",
        PAUSE_CAMPAIGN_LAST_30_DAYS_CPM: "",
        ADD_MORE_VARIATIONS_CPM: "",
        INCREASE_DAILY_BUDGET_CPM: "",
        DECREASE_DAILY_BUDGET_CPM: "",
        BIDDING_OPTIMIZATION: "",
        FACTOR_BROWSERS_BLACKLISTING: "",
        FACTOR_OS_BLACKLISTING: "",
        FACTOR_DEVICES_BLACKLISTING: "",
        FACTOR_DEVICE_TYPES_BLACKLISTING: ""
    });

    const [tsAccountId, setTsAccountId] = useState("");
    const [remote_id, setRemote_id] = useState("");
    const [formattedData, setFormattedData] = useState("");

    const [voluum, setVoluum] = useState("");

    const [campaignType, setCampaignType] = useState("");
    const [maxBid, setMaxBid] = useState("");

    const [showError, setShowError] = useState(false);
    const [isCreated, setIsCreated] = useState(true);
    const [filtered, setFiltered] = useState([]);
    const [add_banners_to_children, setAdd_banners_to_children] = useState(true);
    const [bidPrice, setBidPrice] = useState("");
    const [checkbox, setCheckbox] = useState({
        FACTOR_BROWSERS_OPTIMIZATION: false,
        FACTOR_OS_OPTIMIZATION: false,
        FACTOR_DEVICES_OPTIMIZATION: false,
        FACTOR_DEVICE_TYPES_OPTIMIZATION: false
    });

    const populateCampaignData = function (campaignData) {
        const {
            id,
            date_created,
            date_updated,
            external_date_created,
            owner,
            parent_id,
            created_by,
            campaign_type,
            ts_account_id,
            voluum_id,
            remote_id,
            epa,
            add_banners_to_children,
            bid_price,
            max_bid,
            ...rest
        } = campaignData;
        if (campaign_type !== "TRANSIT") {
            getAssignAlgorithms(id)(campaignDispatch);
        }
        setRemote_id(remote_id);
        setRemoteData((prevState) => {
            return {...prevState, ...rest};
        });

        setEpa(epa);
        setAdd_banners_to_children(add_banners_to_children);
        setCampaignType(campaign_type);
        setTsAccountId(ts_account_id);
        setVoluum(voluum_id);
        setIsCreated(false);
        if (bid_price) {
            setBidPrice(bid_price);
        }
        if (max_bid) {
            setMaxBid(max_bid);
        }
    }

    useEffect(() => {
        getAlgorithms(null)(algorithmsDispatch);
    }, [algorithmsDispatch]);

    useEffect(() => {
        if (recordForEdit && !isFromReport) {
            const {campaign_id} = recordForEdit;
            getCampaignById(campaign_id)(campaignDispatch);
            populateCampaignData(recordForEdit)
        }
    }, [recordForEdit, campaignDispatch, isFromReport]);

    useEffect(() => {
        if (isFromReport && campaign.data) {
            populateCampaignData(campaign.data);
        }
        if (tsAccountId) {
            const tsAccountData = tsAccounts.find((x) => x.id === tsAccountId);
            const currency = tsAccountData.currency;
            let formattedPrice = "";
            if (currency === "USD") {
                formattedPrice += "$";
            } else if (currency === "EUR") {
                formattedPrice += "€";
            }
            setFormattedData(formattedPrice);
        }
    }, [isFromReport, campaign, campaignDispatch, tsAccountId, tsAccounts]);

    useEffect(() => {
        if (remoteData.cost_model && isCreated) {
            let filteredAlgorithmsList = algorithmsList?.data.filter(
                (item) =>
                    item.type.cost_model === remoteData.cost_model ||
                    item.type.cost_model === "NONE"
            );
            setFiltered(filteredAlgorithmsList);
            assignFactorsAlgorithms(getCampaignDefaultAlgorithmsState.data);
            if (remoteData.cost_model === "CPM") {
                assignCPM(getCampaignDefaultAlgorithmsState.data, false);
            } else {
                assignCPC(getCampaignDefaultAlgorithmsState.data, false);
            }
        }
    }, [
        remoteData.cost_model,
        recordForEdit,
        getCampaignDefaultAlgorithmsState.data,
        algorithmsList?.data,
        isCreated,
    ]);
    useEffect(() => {
        setRemoteData((prevState) => {
            if (data) {
                if (data.bid_price) {
                    setBidPrice(data.bid_price);
                }
                if (data.max_bid) {
                    setMaxBid(data.max_bid);
                }
            }
            return {...prevState, ...data};
        });
    }, [data]);

    useEffect(() => {
        if (campaignType !== "TRANSIT") {
            let filteredAlgorithmsList = algorithmsList?.data.filter(
                (item) =>
                    item.type.cost_model === remoteData.cost_model ||
                    item.type.cost_model === "NONE"
            );
            setFiltered(filteredAlgorithmsList);
            if (assignAlgoCampaign?.data.length > 0) {
                assignFactorsAlgorithms(assignAlgoCampaign?.data);
                if (remoteData.cost_model === "CPM") {
                    assignCPM(assignAlgoCampaign?.data, true);
                } else {
                    assignCPC(assignAlgoCampaign?.data, true);
                }
            }
        }
    }, [assignAlgoCampaign, remoteData.cost_model, algorithmsList?.data, campaignType]);

    useEffect(() => {
        setShowError(error);
    }, [error]);

    const debouncedValueExo = useDebounce(remote_id, 1000);

    useEffect(() => {
        if (debouncedValueExo && tsAccountId && isCreated) {
            getRemoteDataCampaign(tsAccountId, debouncedValueExo)(campaignDispatch);
        }
    }, [debouncedValueExo, tsAccountId, campaignDispatch, isCreated]);

    useEffect(() => {
        if (isCreated && remoteData.cost_model && campaignType && tsAccountId) {
            getCampaignDefaultAlgorithms(
                remoteData.cost_model,
                campaignType,
                tsAccountId
            )(campaignDispatch);

            const tsAccountData = tsAccounts.find((x) => x.id === tsAccountId);
            const currency = tsAccountData.currency;
            let formattedPrice = "";
            if (currency === "USD") {
                formattedPrice += "$";
            } else if (currency === "EUR") {
                formattedPrice += "€";
            }
            setFormattedData(formattedPrice);
        }
    }, [
        campaignDispatch,
        tsAccountId,
        isCreated,
        remoteData.cost_model,
        campaignType,
        tsAccounts,
    ]);

    const assignAlgo = (array, algoArray) => {
        let checkboxId = [];
        let filteredArray = [...array];
        let objectKeys =
            remoteData.cost_model === "CPM"
                ? Object.values(dropdownsCPM)
                : Object.values(dropdownsCPC);
        for (const [key, value] of Object.entries(checkbox)) {
            if (value) {
                let item = array.find((x) => x.type.algorithm_type === key);
                checkboxId.push(item.id);
            }
        }
        let algoId = [...objectKeys, ...checkboxId];

        for (const element of algoId) {
            if (!!element) {
                let item = filteredArray.find((x) => x.id === element);
                let object = {
                    type_id: item.type_id,
                    algorithm_id: item.id,
                };
                algoArray.push(object);
            }
        }
    };
    const handleChange = (e) => {
        setRemote_id(e.target.value);
        if (recordForEdit) {
            setIsCreated(true);
        }
    };
    const handleChangeEpa = (e) => {
        setEpa(e.target.value);
    };
    const handleChangeBitPrice = (e) => {
        setBidPrice(e.target.value);
    };
    const handleChangeMaxBid = (e) => {
        setMaxBid(e.target.value);
    };
    const handleChangeTsAccount = (event) => {
        setTsAccountId(event.target.value);
        if (recordForEdit) {
            setIsCreated(true);
        }
    };

    const handleChangeVoluum = (event) => {
        setVoluum(event.target.value);
    };

    const handleChangeType = (event) => {
        setCampaignType(event.target.value);
    };

    const handleChangeCheckBox = (event) => {
        setAdd_banners_to_children(event.target.checked);
    };

    const handleChangeAlgorithmCPM = (event) => {
        const {name, value} = event.target;
        if (name === "SHORTLIST_SITE_CPM" || name === "SHORTLIST_ZONE_CPM") {
            setDropdownsCPM((inputs) => ({...inputs, [name]: value}));
            setDropdownsCPM((inputs) => ({...inputs, WHITELIST_ZONE_CPM: ""}));
            setDropdownsCPM((inputs) => ({...inputs, WHITELIST_SITE_CPM: ""}));
        } else if (name === "WHITELIST_ZONE_CPM" || name === "WHITELIST_SITE_CPM") {
            setDropdownsCPM((inputs) => ({...inputs, SHORTLIST_ZONE_CPM: ""}));
            setDropdownsCPM((inputs) => ({...inputs, SHORTLIST_SITE_CPM: ""}));
            setDropdownsCPM((inputs) => ({...inputs, [name]: value}));
        } else {
            setDropdownsCPM((inputs) => ({...inputs, [name]: value}));
        }
    };
    const handleChangeAlgorithmCPC = (event) => {
        const {name, value} = event.target;
        if (name === "SHORTLIST_SITE_CPC" || name === "SHORTLIST_ZONE_CPC") {
            setDropdownsCPC((inputs) => ({...inputs, [name]: value}));
            setDropdownsCPC((inputs) => ({...inputs, WHITELIST_ZONE_CPC: ""}));
            setDropdownsCPC((inputs) => ({...inputs, WHITELIST_SITE_CPC: ""}));
        } else if (name === "WHITELIST_ZONE_CPC" || name === "WHITELIST_SITE_CPC") {
            setDropdownsCPC((inputs) => ({...inputs, SHORTLIST_ZONE_CPC: ""}));
            setDropdownsCPC((inputs) => ({...inputs, SHORTLIST_SITE_CPC: ""}));
            setDropdownsCPC((inputs) => ({...inputs, [name]: value}));
        } else {
            setDropdownsCPC((inputs) => ({...inputs, [name]: value}));
        }
    };

    const assignCPM = (array, isEdit) => {
        if (array.length >= 1) {
            for (const element of array) {
                if (element.type.algorithm_type === "BLACKLIST_ZONE_CPM") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_ZONE_CPM: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BLACKLIST_SITE_CPM") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_SITE_CPM: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "WHITELIST_ZONE_CPM") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            WHITELIST_ZONE_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "WHITELIST_SITE_CPM" &&
                    isEdit
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            WHITELIST_SITE_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "SHORTLIST_ZONE_CPM" &&
                    isEdit
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            SHORTLIST_ZONE_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "SHORTLIST_SITE_CPM" &&
                    isEdit
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            SHORTLIST_SITE_CPM: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BLACKLIST_VARIATION_CPM") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_VARIATION_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "PAUSE_CAMPAIGN_LAST_7_DAYS_CPM"
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            PAUSE_CAMPAIGN_LAST_7_DAYS_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "PAUSE_CAMPAIGN_LAST_30_DAYS_CPM"
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            PAUSE_CAMPAIGN_LAST_30_DAYS_CPM: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "ADD_MORE_VARIATIONS_CPM") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            ADD_MORE_VARIATIONS_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "INCREASE_DAILY_BUDGET_CPM"
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            INCREASE_DAILY_BUDGET_CPM: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "DECREASE_DAILY_BUDGET_CPM"
                ) {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            DECREASE_DAILY_BUDGET_CPM: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BIDDING_OPTIMIZATION") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            BIDDING_OPTIMIZATION: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_OS_BLACKLISTING") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_OS_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_BROWSERS_BLACKLISTING") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_BROWSERS_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICES_BLACKLISTING") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICES_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICE_TYPES_BLACKLISTING") {
                    setDropdownsCPM((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICE_TYPES_BLACKLISTING: element.id,
                        };
                    });
                }
            }
        }
    };

    const assignCPC = (array, isEdit) => {
        if (array.length >= 1) {
            for (const element of array) {
                if (element.type.algorithm_type === "BLACKLIST_ZONE_CPC") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_ZONE_CPC: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BLACKLIST_SITE_CPC") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_SITE_CPC: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "WHITELIST_ZONE_CPC") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            WHITELIST_ZONE_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "WHITELIST_SITE_CPC" &&
                    isEdit
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            WHITELIST_SITE_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "SHORTLIST_ZONE_CPC" &&
                    isEdit
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            SHORTLIST_ZONE_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "SHORTLIST_SITE_CPC" &&
                    isEdit
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            SHORTLIST_SITE_CPC: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BLACKLIST_VARIATION_CPC") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            BLACKLIST_VARIATION_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "PAUSE_CAMPAIGN_LAST_7_DAYS_CPC"
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            PAUSE_CAMPAIGN_LAST_7_DAYS_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "PAUSE_CAMPAIGN_LAST_30_DAYS_CPC"
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            PAUSE_CAMPAIGN_LAST_30_DAYS_CPC: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "ADD_MORE_VARIATIONS_CPC") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            ADD_MORE_VARIATIONS_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "INCREASE_DAILY_BUDGET_CPC"
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            INCREASE_DAILY_BUDGET_CPC: element.id,
                        };
                    });
                } else if (
                    element.type.algorithm_type === "DECREASE_DAILY_BUDGET_CPC"
                ) {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            DECREASE_DAILY_BUDGET_CPC: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "BIDDING_OPTIMIZATION") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            BIDDING_OPTIMIZATION: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_OS_BLACKLISTING") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_OS_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_BROWSERS_BLACKLISTING") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_BROWSERS_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICES_BLACKLISTING") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICES_BLACKLISTING: element.id,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICE_TYPES_BLACKLISTING") {
                    setDropdownsCPC((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICE_TYPES_BLACKLISTING: element.id,
                        };
                    });
                }
            }
        }
    };

    const assignFactorsAlgorithms = (array) => {
        if (array.length >= 1) {
            for (const element of array) {
                if (element.type.algorithm_type === "FACTOR_BROWSERS_OPTIMIZATION") {
                    setCheckbox((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_BROWSERS_OPTIMIZATION: true,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_OS_OPTIMIZATION") {
                    setCheckbox((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_OS_OPTIMIZATION: true,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICES_OPTIMIZATION") {
                    setCheckbox((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICES_OPTIMIZATION: true,
                        };
                    });
                } else if (element.type.algorithm_type === "FACTOR_DEVICE_TYPES_OPTIMIZATION") {
                    setCheckbox((prevState) => {
                        return {
                            ...prevState,
                            FACTOR_DEVICE_TYPES_OPTIMIZATION: true,
                        };
                    });
                }
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data;
        let arrayAlgo = [];
        if (campaignType !== "TRANSIT") {
            assignAlgo(algorithmsList?.data, arrayAlgo);
        }

        if (recordForEdit) {
            data = {
                id: recordForEdit.id,
                date_created: recordForEdit.date_created,
                date_updated: recordForEdit.date_updated,
                external_date_created: recordForEdit.external_date_created,
                owner: recordForEdit.owner,
                created_by: recordForEdit.created_by,
                parent_id: recordForEdit.parent_id,
                remote_id: remote_id,
                voluum_id: voluum,
                name: remoteData.name,
                cost_model: remoteData.cost_model,
                epa: epa,
                bid_price: bidPrice,
                max_bid: maxBid,
                status: remoteData.status,
                campaign_type: campaignType,
                ts_account_id: tsAccountId,
                variation_ids: remoteData.variation_ids,
                add_banners_to_children: add_banners_to_children,
                daily_budget: remoteData.daily_budget,
                daily_budget_reset: remoteData.daily_budget_reset,
            };
        } else {
            data = {
                remote_id: remote_id,
                voluum_id: voluum,
                name: remoteData.name,
                external_date_created: remoteData.external_date_created,
                cost_model: remoteData.cost_model,
                variation_ids: remoteData.variation_ids,
                epa: epa,
                bid_price: bidPrice,
                max_bid: maxBid,
                status: remoteData.status,
                campaign_type: campaignType,
                ts_account_id: tsAccountId,
                add_banners_to_children: add_banners_to_children,
                daily_budget: remoteData.daily_budget,
                daily_budget_reset: remoteData.daily_budget_reset,
            };
        }

        if (data.max_bid === "") {
            delete data.max_bid;
        }

        addOrEdit(data, arrayAlgo);

        resetAssignAlgorithmsCampaign()(campaignDispatch);
    };

    const handleAlertDialog = () => {
        setShowError((prevCheck) => !prevCheck);
        resetMessageRemoteData()(campaignDispatch);
    };

    const handleCheckedCheckBox = (e) => {
        setCheckbox((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.checked,
        }));
    };
    const errorMessage = 'An error occurred: ' + apiError

    const containerRef = useRef(null);
    const [scrollToTop, setScrollToTop] = useState(false);

    useEffect(() => {
        if (apiError || scrollToTop) {
            containerRef.current.scrollIntoView({behavior: 'auto', block: 'start'});
            setScrollToTop(false);
        }
    }, [apiError, scrollToTop]);

    const handleSubmitSave = () => {
        if (apiError) {
            setScrollToTop(true);
        }
    };

    return (
        <Container component="main" maxWidth="xs" ref={containerRef}>
            {recordForEdit && recordCreated === 'update' ? (
                <Typography component="h1" variant="h5">
                    Update Campaign
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create Campaign
                </Typography>
            )}
            <Grid item xs={12} justifyContent="center" sx={{display: "flex"}}>
                {showError && (
                    <TransitionAlerts
                        title={message}
                        handleAlertDialog={handleAlertDialog}
                    />
                )}
            </Grid>
            {apiError && <Alert severity="error">{errorMessage}</Alert>}
            <Box
                sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={add_banners_to_children}
                                        onChange={handleChangeCheckBox}
                                        inputProps={{"aria-label": "controlled"}}
                                    />
                                }
                                label="Add new banners to the children"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={!isCreated}
                                required
                                fullWidth
                                onChange={handleChange}
                                id="remote_id"
                                label="Campaign ID"
                                name="remote_id"
                                value={remote_id}
                            />
                        </Grid>
                        {
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="ts-account-label">TS ACCOUNT</InputLabel>
                                    <Select
                                        required
                                        labelId="ts-account-label"
                                        id="ts-account-label-select"
                                        value={tsAccountId}
                                        label="TS ACCOUNT"
                                        onChange={handleChangeTsAccount}
                                    >
                                        {(tsAccounts || []).map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="campaign-type-select-label">
                                    Campaign Type
                                </InputLabel>
                                <Select
                                    required
                                    labelId="campaign-type-select-label"
                                    id="campaign-type-select"
                                    value={campaignType}
                                    label="Campaign Type"
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="SEED">SEED</MenuItem>
                                    <MenuItem value="WL">WL</MenuItem>
                                    <MenuItem value="SL">SL</MenuItem>
                                    <MenuItem value="TRANSIT">TRANSIT</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ts-account-label">Voluum</InputLabel>
                                <Select
                                    required
                                    labelId="voluum-id-label"
                                    id="voluum-id-label-select"
                                    value={voluum}
                                    label="Voluum"
                                    onChange={handleChangeVoluum}
                                >
                                    {(voluumCampaigns || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={handleChangeEpa}
                                fullWidth
                                name="epa"
                                label="Default EPA"
                                type="text"
                                id="epa"
                                value={epa}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                name="name"
                                label="Name"
                                type="text"
                                id="name"
                                value={remoteData.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                name="cost_model"
                                label="Cost Model"
                                type="text"
                                id="cost_model"
                                value={remoteData.cost_model}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={handleChangeBitPrice}
                                name="bid_price"
                                label={`Bid price in ${formattedData}`}
                                type="text"
                                id="bid_price"
                                value={bidPrice}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                onChange={handleChangeMaxBid}
                                name="max_bid"
                                label={`Max Bid in ${formattedData}`}
                                type="text"
                                id="max_bid"
                                value={maxBid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                name="status"
                                label="Status"
                                type="text"
                                id="status"
                                value={remoteData.status}
                            />
                        </Grid>
                    </Grid>
                    {campaignType !== "TRANSIT" && remoteData.cost_model === "CPC" && (
                        <AlgoCPC
                            campaignType={campaignType}
                            dropdownsCPC={dropdownsCPC}
                            handleChangeAlgorithmCPC={handleChangeAlgorithmCPC}
                            filtered={filtered}
                        />
                    )}
                    {campaignType !== "TRANSIT" && (
                        <Grid item xs={12}>
                            <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="FACTOR_BROWSERS_OPTIMIZATION"
                                                checked={checkbox.FACTOR_BROWSERS_OPTIMIZATION}
                                                onClick={handleCheckedCheckBox}
                                            />
                                        }
                                        label="BROWSERS_OPTIMIZATION"
                                    />
                                    <FormControlLabel
                                        value="30"
                                        control={
                                            <Checkbox
                                                checked={checkbox.FACTOR_OS_OPTIMIZATION}
                                                onClick={handleCheckedCheckBox}
                                                name="FACTOR_OS_OPTIMIZATION"
                                            />
                                        }
                                        label="OS_OPTIMIZATION"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkbox.FACTOR_DEVICES_OPTIMIZATION}
                                                onClick={handleCheckedCheckBox}
                                                name="FACTOR_DEVICES_OPTIMIZATION"
                                            />
                                        }
                                        label="DEVICES_OPTIMIZATION"
                                    />
                                    <FormControlLabel
                                        value="30"
                                        control={
                                            <Checkbox
                                                checked={checkbox.FACTOR_DEVICE_TYPES_OPTIMIZATION}
                                                onClick={handleCheckedCheckBox}
                                                name="FACTOR_DEVICE_TYPES_OPTIMIZATION"
                                            />
                                        }
                                        label="DEVICE_TYPES_OPTIMIZATION"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    )}
                    {campaignType !== "TRANSIT" && remoteData.cost_model === "CPM" && (
                        <AlgoCPM
                            campaignType={campaignType}
                            dropdownsCPM={dropdownsCPM}
                            handleChangeAlgorithmCPM={handleChangeAlgorithmCPM}
                            filtered={filtered}
                        />
                    )}

                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={error}
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                        onClick={handleSubmitSave}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            {children}
        </Container>
    );
}

export {CreateCampaign};
