import {
    GET_DOMAIN_ERROR,
    GET_DOMAIN_LOADING,
    GET_DOMAIN_SUCCESS,
    FETCH_DOMAIN_ERROR,
    FETCH_DOMAIN_LOADING,
    FETCH_DOMAIN_SUCCESS,
} from "../../constants/actionTypes";

const domains = (state, {payload, type}) => {
    switch (type) {
        case GET_DOMAIN_LOADING: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: true,
                },
            };
        }

        case GET_DOMAIN_SUCCESS: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_DOMAIN_ERROR: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    error: payload,
                },
            };
        }
        case FETCH_DOMAIN_LOADING: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: true,
                },
            };
        }

        case FETCH_DOMAIN_SUCCESS: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case FETCH_DOMAIN_ERROR: {
            return {
                ...state,
                domainsList: {
                    ...state.domainsList,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default domains;