import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {AlertDialog} from "../components/dialog";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import {error401} from "../constants/api";

import Grid from "@mui/material/Grid";
import {CreateCampaign} from "./createCampaign";
import {
    createCampaignAction,
    deleteSeedCampaign,
    getCampaigns,
    resetErrorDataCampaign,
    resetMessageRemoteData,
    resetRemoteData,
    updateCampaignAction,
} from "../context/actions/campaign/campaign";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {getVoluumCampaigns} from "../context/actions/voluum/voluum";
import LoadingSpinner from "../components/LoadingSpinner";

function getFormattedPrice(price, currency) {
    if (!price) {
        return "";
    }

    let formattedPrice = "";
    if (currency === "USD") {
        formattedPrice += "$";
    } else if (currency === "EUR") {
        formattedPrice += "€";
    }
    formattedPrice += price;
    return formattedPrice;
}

function Campaign() {
    const user = JSON.parse(localStorage.getItem("user"));

    const columns = [
        {field: "id", headerName: "ID", width: 50},
        {field: "remote_id", headerName: "Campaign ID", minWidth: 120},
        {field: "name", headerName: "Name", width: 400},
        {field: "status", headerName: "Status", width: 100},
        {field: "cost_model", headerName: "Cost Model", width: 100},
        {field: "epa", headerName: "EPA", width: 100},
        {
            field: "bid_price",
            headerName: "Bid Price",
            width: 100,
            renderCell: (cellValues) => {
                const tsAccount = tsAccountsList.data.find(
                    (x) => x.id === cellValues.row.ts_account_id
                );
                return tsAccount ? getFormattedPrice(cellValues.row.bid_price, tsAccount.currency) : '';
            },
        },
        {
            field: "max_bid",
            headerName: "Max Bid",
            width: 100,
            renderCell: (cellValues) => {
                if (!cellValues.row.max_bid) {
                    return "";
                }
                const tsAccount = tsAccountsList.data.find(
                    (x) => x.id === cellValues.row.ts_account_id
                );
                return getFormattedPrice(cellValues.row.max_bid, tsAccount.currency)
            },
        },
        {field: "campaign_type", headerName: "Campaign Type", width: 130},
        {field: "created_by", headerName: "Created By", width: 100},
        {field: "owner", headerName: "Owner", width: 100},
        {
            field: "ts_account_id",
            headerName: "Ts Account",
            width: 100,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {field: "date_created", headerName: "Date Created", width: 130},
        {field: "date_updated", headerName: "Date Updated", width: 130},
        {field: "external_date_created", headerName: "External Date", width: 130},
        {field: "parent_id", headerName: "Parent", minWidth: "130"},
        {
            field: "voluum_id",
            headerName: "Voluum",
            minWidth: "300",
            renderCell: (cellValues) => {
                let voluumCampaign = voluumCampaigns.data.find((x) => x.id === cellValues.value);
                return <>{voluumCampaign?.name}</>;
            },
        },
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                        <DeleteIcon
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const redirectToEdit = (cellValues) => {
        setRecordCreated('update');
        setOpenCreateEditDialog(true);
        setSelectedCampaign(cellValues.row);
    };

    const handleClick = (event, cellValues) => {
        setCampaign(cellValues.row.remote_id);
        setCampaignId(cellValues.row.id);
        setOpen(true);
    };

    const {
        campaignDispatch,
        campaignState,
        tsAccountsDispatch,
        tsAccountsState,
        voluumDispatch,
        voluumState,
    } = useContext(GlobalContext);

    const {
        campaigns: {data, loading, error},
    } = campaignState;
    const {tsAccountsList} = tsAccountsState;
    const {voluumCampaigns} = voluumState;
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [campaign, setCampaign] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [tsAccount, setTsAccount] = useState("");
    const [apiError, setApiError] = useState("");
    const [recordCreated, setRecordCreated] = useState('create')

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };
    const handleClose = () => {
        setOpen(false);
        setCampaign("");
        setCampaignId("");
    };

    const handleCloseCreateEditDialog = () => {
        setRecordCreated(true);
        setApiError(false);
        setOpenCreateEditDialog(false);
        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        resetMessageRemoteData()(campaignDispatch);
    };
    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };
    const handleDelete = () => {
        setApiError(false);
        selectedCampaign
            ? deleteSeedCampaign(selectedCampaign?.id)(campaignDispatch)
            : deleteSeedCampaign(campaignId)(campaignDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setSelectedCampaign(null);
    };

    const addOrEdit = (props, assignAlgo) => {
        setSelectedCampaign(null);
        resetRemoteData()(campaignDispatch);
        setRecordCreated(props.id ? 'update' : 'create')
      
        const errorType = props.id ? 'UPDATE_CAMPAIGN_ERROR' : 'CREATE_CAMPAIGN_ERROR';
        const successType = props.id ? 'UPDATE_CAMPAIGN_SUCCESS' : 'CREATE_CAMPAIGN_SUCCESS';
      
        const taskActionFn = props.id ? updateCampaignAction : createCampaignAction;
            
        taskActionFn(props, assignAlgo)((action) => {
          if (action.type === errorType) {
            setApiError(action.payload.detail[0].msg);
            setOpenCreateEditDialog(true);
            setSelectedCampaign(props);
          } 
          else if (action.type === successType) {
            handleCloseCreateEditDialog();
            getCampaigns()(campaignDispatch);
          }
        });

    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };
    useEffect(() => {
        getCampaigns(tsAccount)(campaignDispatch);
    }, [campaignDispatch, tsAccount]);

    useEffect(() => {
        getVoluumCampaigns()(voluumDispatch);
        getTsAccounts()(tsAccountsDispatch);
    }, [voluumDispatch, tsAccountsDispatch]);

    useEffect(() => {
        if (error?.detail === error401) {
            localStorage.clear();
            navigate("/");
            resetErrorDataCampaign()(campaignDispatch);
        }
    }, [error, campaignDispatch, navigate]);
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Campaign
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button
                        sx={{mb: 5}}
                        onClick={handleOpenCreateDialog}
                        variant="contained"
                        color="success"
                        >
                        Add Campaign
                    </Button>
                </Grid>
                {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") && (
                    <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                        <TrafficSourceAccountDropdown
                            tsAccount={tsAccount}
                            data={tsAccountsList.data}
                            width={300}
                            handleChangeTsAccount={handleChangeTsAccount}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>

            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}

            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateCampaign
                    recordCreated = {recordCreated}
                    apiError={apiError}
                    recordForEdit={selectedCampaign}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                    tsAccounts={tsAccountsList.data}
                    voluumCampaigns={voluumCampaigns.data}
                />
                <AlertDialog
                    open={openDeleteDialog}
                    handleClose={alertInCreateDialog}
                    name={selectedCampaign?.remote_id}
                    handleDelete={handleDelete}
                />
            </CreateAndEditDialog>

            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={campaign}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {Campaign};
