import React, {useContext, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {AlertDialog} from "../components/dialog";
import {createAndEditEvent, deleteEvent, getEvents,} from "../context/actions/events/events";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import {CreateEvent} from "./createEvent";
import LoadingSpinner from "../components/LoadingSpinner";

function Events() {
    const user = JSON.parse(localStorage.getItem("user"));

    const columns = [
        {field: "id", headerName: "ID", width: 50},
        {field: "name", headerName: "Name", width: 100},
        {field: "source", headerName: "Source", width: 100},
        {
            field: "ts_account_id",
            headerName: "Ts Account",
            width: 100,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {field: "remote_id", headerName: "Remote ID", width: 400},
        {field: "created_by", headerName: "Created By", width: 100},
        {field: "created_on", headerName: "Date On", width: 130},
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                        <DeleteIcon
                            onClick={() => {
                                handleClick(cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const {eventDispatch, eventState, tsAccountsDispatch, tsAccountsState} =
        useContext(GlobalContext);

    const {tsAccountsList} = tsAccountsState;
    const {
        eventsList: {data, loading},
    } = eventState;

    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [tsAccount, setTsAccount] = useState("");
    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState(null);
    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        getEvents(tsAccount)(eventDispatch);
    }, [eventDispatch, tsAccount]);

    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleCloseCreateEditDialog = () => {
        setOpenCreateEditDialog(false);
        setEvent(null);
    };

    const handleClick = (cellValues) => {
        setEvent(cellValues.row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEvent("");
    };

    const handleDelete = () => {
        setOpen(false);
        deleteEvent(event.id)(eventDispatch);
    };

    const redirectToEdit = (cellValues) => {
        setOpenCreateEditDialog(true);
        setEvent(cellValues.row);
    };

    const createAndEditEvents = (props) => {
        setOpenCreateEditDialog(false);
        setEvent(null);
        createAndEditEvent(props)(eventDispatch);
    };

    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Events
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button
                        sx={{mb: 5}}
                        onClick={handleOpenCreateDialog}
                        variant="contained"
                        color="success"
                    >
                        Add Event
                    </Button>
                </Grid>
                {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") && (
                    <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                        <TrafficSourceAccountDropdown
                            tsAccount={tsAccount}
                            data={tsAccountsList.data}
                            width={300}
                            handleChangeTsAccount={handleChangeTsAccount}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>

            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateEvent
                    tsAccounts={tsAccountsList.data}
                    recordForEdit={event}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    listEvents={data}
                    createAndEditEvents={createAndEditEvents}
                    tsAccountSelected={tsAccount}
                />
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={event?.name}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {Events};
