import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {AlertDialog} from "../components/dialog";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {CreateEditVoluum} from "./createEditVoluum";
import {createVoluum, deleteVoluum, getVoluumCampaigns, updateVoluum,} from "../context/actions/voluum/voluum";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import LoadingSpinner from "../components/LoadingSpinner";

function VoluumList() {
    const columns = [
        {field: "name", headerName: "Name", width: 300},

        {
            field: "ts_account_id",
            headerName: "Ts Account Name",
            width: 200,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {
            field: "voluum_campaign_id",
            headerName: "Voluum Campaign Id",
            width: 400,
        },
        {field: "created_by", headerName: "Created By", width: 100},
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                        <DeleteIcon
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const {voluumDispatch, voluumState, tsAccountsDispatch, tsAccountsState} =
        useContext(GlobalContext);

    const {
        voluumCampaigns: {data, loading},
    } = voluumState;
    const {tsAccountsList} = tsAccountsState;

    useEffect(() => {
        getVoluumCampaigns()(voluumDispatch);
    }, [voluumDispatch]);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    const redirectToEdit = (cellValues) => {
        setOpenCreateEditDialog(true);
        setSelectedVoluum(cellValues.row);
    };
    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleClick = (event, cellValues) => {
        setVoluum(cellValues.row.name);
        setVoluumId(cellValues.row.id);
        setOpen(true);
    };

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [voluum, setVoluum] = useState("");
    const [voluumId, setVoluumId] = useState("");
    const [selectedVoluum, setSelectedVoluum] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setVoluum("");
        setVoluumId("");
    };

    const handleDelete = () => {
        selectedVoluum
            ? deleteVoluum(selectedVoluum?.id)(voluumDispatch)
            : deleteVoluum(voluumId)(voluumDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setSelectedVoluum(null);
    };

    const handleCloseCreateEditDialog = () => {
        setOpenCreateEditDialog(false);
        setSelectedVoluum(null);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const addOrEdit = (props) => {
        setOpenCreateEditDialog(false);
        setSelectedVoluum(null);
        props.id
            ? updateVoluum(props)(voluumDispatch)
            : createVoluum(props)(voluumDispatch);
    };
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Voluum Campaigns
            </Typography>

            <Button
                sx={{mt: 3, mb: 5}}
                variant="contained"
                color="success"
                onClick={handleOpenCreateDialog}
            >
                Create Voluum
            </Button>
            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateEditVoluum
                    recordForEdit={selectedVoluum}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                    tsAccounts={tsAccountsList.data}
                >
                    <AlertDialog
                        open={openDeleteDialog}
                        handleClose={alertInCreateDialog}
                        name={selectedVoluum?.name}
                        handleDelete={handleDelete}
                    />
                </CreateEditVoluum>
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={voluum}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {VoluumList};
