import "./App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Login} from "./login";
import {Campaign, CampaignTree} from "./campaign";
import {GlobalProvider} from "./context/Provider";
import Layout from "./components/layout";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import {
    BidHistoryReports,
    CompleteReports,
    CountryReports,
    ErrorReports,
    PerformanceMonitor,
    SuccessReports,
    TotalReports,
    VariationsReports
} from "./reports";
import {UserManagement} from "./userManagement";
import {TaskManagement} from "./taskManagement";
import {TsAccountsList} from "./traffic/tsAccounts/tsList";
import {VoluumList} from "./voluum/voluumList";
import {BlackListZoneList, GlobalSiteZoneList} from "./globalSiteZone";
import {CreateAlgorithm, ListAlgorithms} from "./algorithms";
import {ListOfDefaultAlgorithms} from "./defaultAlgo/listOfDefaultAlgorithms";
import {BulkAlgorithmUpdate} from "./bulkAlgorithmUpdate/bulkAlgorithmUpdate";
import {Events} from "./events/listEvents";
import {ListDomains, ListDomainReplacementTasks, ReplaceDomains} from "./domains";
import {ListLanders} from "./domains/listLanders";
import {ListOffers} from "./offers";
import {BulkCampaignTypeUpdate} from "./bulkCampaignTypeUpdate/bulkCampaignTypeUpdate";
import {CustomReports} from "./reports/customReports";
import {CampaignReports} from "./reports/campaignReports";

function App() {
    return (
        <GlobalProvider>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <Login/>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/campaign"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <Campaign/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bulk_campaign_type_update"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <BulkCampaignTypeUpdate/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/campaign"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CampaignReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/complete"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CompleteReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/total"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <TotalReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/custom"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CustomReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/success"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <SuccessReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/error"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ErrorReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/reports/bid"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <BidHistoryReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/variations"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <VariationsReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user_management"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <UserManagement/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ts_accounts"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <TsAccountsList/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/voluum"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <VoluumList/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/country"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CountryReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/create_algorithm"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CreateAlgorithm/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/list_algorithms"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListAlgorithms/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/campaign_tree"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <CampaignTree/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/blacklist_zone"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <BlackListZoneList/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/blacklist_site"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <GlobalSiteZoneList/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/default_algo"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListOfDefaultAlgorithms/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bulk_algorithm_update"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <BulkAlgorithmUpdate/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/events"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <Events/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/performance_monitor"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <PerformanceMonitor/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/task_management"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <TaskManagement/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reports/total"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <TotalReports/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/domains/list_landers"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListLanders/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/domains/list_domains"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListDomains/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/domains/replace_domains"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ReplaceDomains/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/domains/domain_tasks"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListDomainReplacementTasks/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/list_offers"
                        element={
                            <PrivateRoute>
                                <Layout>
                                    <ListOffers/>
                                </Layout>
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Router>
        </GlobalProvider>
    );
}

export default App;
