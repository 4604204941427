import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_VOLUUM_ERROR,
    CREATE_VOLUUM_LOADING,
    CREATE_VOLUUM_SUCCESS,
    DELETE_VOLUUM_ERROR,
    DELETE_VOLUUM_LOADING,
    DELETE_VOLUUM_SUCCESS,
    GET_VOLUUM_ERROR,
    GET_VOLUUM_LOADING,
    GET_VOLUUM_SUCCESS,
    UPDATE_VOLUUM_ERROR,
    UPDATE_VOLUUM_LOADING,
    UPDATE_VOLUUM_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createVoluum = (voluum) => (dispatch) => {
    dispatch({
        type: CREATE_VOLUUM_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/voluum_campaigns/`, voluum)
        .then((res) => {
            dispatch({
                type: CREATE_VOLUUM_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_VOLUUM_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const updateVoluum = (voluum) => (dispatch) => {
    dispatch({
        type: UPDATE_VOLUUM_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/voluum_campaigns/`, voluum)
        .then((res) => {
            dispatch({
                type: UPDATE_VOLUUM_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_VOLUUM_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getVoluumCampaigns = () => (dispatch) => {
    dispatch({
        type: GET_VOLUUM_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/voluum_campaigns/`)
        .then((res) => {
            dispatch({
                type: GET_VOLUUM_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_VOLUUM_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteVoluum = (id) => (dispatch) => {
    dispatch({
        type: DELETE_VOLUUM_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/voluum_campaigns/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_VOLUUM_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_VOLUUM_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};
