import React, { useContext, useEffect } from "react";
import {
  getAlgorithmsTypes,
  turnOnOffAlgorithms,
} from "../../context/actions/algorithms/algorithms";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShieldTwoToneIcon from "@mui/icons-material/ShieldTwoTone";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ACTION_TYPES } from "./addAction";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/Provider";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

export function AlgorithmsAccordion({ trafficSource, handleDeleteAlgorithm }) {
  const { algorithmsState, algorithmsDispatch, tsAccountsState } =
    useContext(GlobalContext);

  useEffect(() => {
    getAlgorithmsTypes()(algorithmsDispatch);
  }, [algorithmsDispatch]);

  const {
    algorithmsList: { data },
    algorithmsTypes,
  } = algorithmsState;
  const { trafficSourceList } = tsAccountsState;

  const trafficSourceDict = trafficSourceList.data.reduce(function (map, obj) {
    map[obj.id] = obj.name;
    return map;
  }, {});

  const navigate = useNavigate();

  const handleTurnOnOffAlgorithm = (event, item, index) => {
    turnOnOffAlgorithms(
      item.id,
      event.target.checked,
      trafficSource
    )(algorithmsDispatch);
  };

  const convertActionToString = (action) => {
    switch (action.type) {
      case ACTION_TYPES.SET_BID:
        return (
          <span
            style={{
              marginLeft: "20px",
              color: "#666",
            }}
          >
            Set bid to: <b>{action.value}%</b> of <b>{action.epc_or_epm}</b> of
            last <b>{action.period_in_days}</b> days
          </span>
        );
      case ACTION_TYPES.INCREASE_BID:
        return (
          <span style={{ marginLeft: "20px", color: "#666" }}>
            Increase bid by: <b>{action.value}</b>%
          </span>
        );
      case ACTION_TYPES.DECREASE_BID:
        return (
          <span style={{ marginLeft: "20px", color: "#666" }}>
            Decrease bid by: <b>{action.value}</b>%
          </span>
        );
      default:
        return "";
    }
  };

  const replaceString = (name) => {
    const replace = name.replaceAll("_", " ");
    return replace.toLowerCase();
  };

  const handleCloneAlgorithm = (algorithm) => {
    // db returns NULL for multiplier_a in some parts
    const rules = algorithm.rules.map((rule) => {
      const newRule = {
        parts: rule.parts,
        period: rule.period,
      };
      newRule.parts = newRule.parts.map((part) => {
        if (part.multiplier_a === undefined || part.multiplier_a === null) {
          return {
            var_a: part.var_a,
            var_b: part.var_b,
            operator: part.operator,
            multiplier_b: part.multiplier_b,
            multiplier_a: 1.0,
          };
        }
        return part;
      });
      return newRule;
    });
    navigate("/create_algorithm", {
      state: {
        name: algorithm.name + " COPY",
        period: algorithm.period,
        traffic_source_id: algorithm.traffic_source_id,
        type: algorithm.type,
        rules: rules,
        action: algorithm.action,
      },
    });
  };

  return (
    <>
      {algorithmsTypes &&
        algorithmsTypes.data.map((type) => (
          <Accordion key={type.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>{type.algorithm_type}</div>
            </AccordionSummary>
            <AccordionDetails>
              {data
                .filter(
                  (algorithm) =>
                    algorithm.type.algorithm_type === type.algorithm_type
                )
                .map((algorithm, index) => (
                  <div key={algorithm.id}>
                    <Card variant="outlined" sx={{ mb: 5 }}>
                      <CardContent>
                        <List>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box
                                  variant="h5"
                                  component="div"
                                  color="text.secondary"
                                  display="flex"
                                >
                                  <span style={{ marginRight: "5px" }}>
                                    ID: <strong>{algorithm.id}</strong>
                                  </span>
                                  <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                      borderRightWidth: 2,
                                    }}
                                  />
                                  {algorithm.ts_account_id ? (
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        display: "flex",
                                      }}
                                    >
                                      <ShieldTwoToneIcon sx />{" "}
                                      <strong>Private</strong>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        display: "flex",
                                      }}
                                    >
                                      <PublicTwoToneIcon />{" "}
                                      <strong>Public</strong>
                                    </span>
                                  )}
                                </Box>
                              }
                            />
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box
                                  variant="h5"
                                  component="div"
                                  color="text.secondary"
                                >
                                  Name: <strong>{algorithm.name}</strong>
                                  <Switch
                                    checked={algorithm.turned_on}
                                    onClick={(event) => {
                                      handleTurnOnOffAlgorithm(
                                        event,
                                        algorithm,
                                        index
                                      );
                                    }}
                                    inputProps={{
                                      "aria-label": "uncontrolled",
                                    }}
                                  />
                                </Box>
                              }
                            ></ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box
                                  variant="h5"
                                  component="div"
                                  color="text.secondary"
                                >
                                  Traffic Source:{" "}
                                  <strong>
                                    {
                                      trafficSourceDict[
                                        algorithm.traffic_source_id
                                      ]
                                    }
                                  </strong>
                                </Box>
                              }
                            ></ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box variant="h5" color="text.secondary">
                                  Type:{" "}
                                  <strong>
                                    {algorithm.type.algorithm_type}
                                  </strong>
                                </Box>
                              }
                            ></ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box variant="h5" color="text.secondary">
                                  Period:
                                  <strong>
                                    {" " + algorithm.period.join(", ")}
                                    {algorithm.period.length > 0
                                      ? " days"
                                      : "None"}
                                  </strong>
                                </Box>
                              }
                            ></ListItemText>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={
                                <Box
                                  variant="h5"
                                  component="div"
                                  color="text.secondary"
                                  display="flex"
                                  alignItems="center"
                                >
                                  Operations:
                                  <div>
                                    <Button
                                      onClick={() =>
                                        handleDeleteAlgorithm(
                                          algorithm.id,
                                          algorithm.name
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                      Delete
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleCloneAlgorithm(algorithm)
                                      }
                                    >
                                      <FileCopyIcon />
                                      Clone
                                    </Button>
                                  </div>
                                </Box>
                              }
                            ></ListItemText>
                          </ListItem>
                        </List>

                        {algorithm.rules && algorithm.rules.length > 0 ? (
                          <>
                            <List key={"rulebox-" + algorithm.id}>
                              <ListItem disablePadding>
                                <ListItemText
                                  primary={
                                    <Box
                                      variant="h5"
                                      component="div"
                                      color="text.secondary"
                                    >
                                      Rules:
                                    </Box>
                                  }
                                ></ListItemText>
                              </ListItem>

                              <Box
                                variant="body2"
                                key={"rulebox-" + algorithm.id}
                              >
                                {algorithm.rules.map((rule, index) => (
                                  <>
                                    <ListItem>
                                      <ListItemText
                                        primary={"Rule " + (index + 1) + ":"}
                                        secondary={rule.parts.map((item, i) => (
                                          <Box
                                            component="div"
                                            sx={{ display: "inline" }}
                                            key={item.id}
                                          >
                                            <Box
                                              component="div"
                                              sx={{
                                                display: "inline",
                                                mr: 1.5,
                                              }}
                                            >
                                              <Box
                                                component="div"
                                                sx={{
                                                  display: "inline",
                                                  mr: 1.5,
                                                }}
                                              >
                                                {i ? "&" : ""}
                                              </Box>
                                              <Box
                                                component="div"
                                                sx={{
                                                  display: "inline",
                                                  mr: 1.5,
                                                }}
                                              >
                                                {replaceString(item.var_a)}
                                                {item.multiplier_a === null ||
                                                item.multiplier_a === 1
                                                  ? ""
                                                  : " * " + item.multiplier_a}
                                              </Box>
                                              <Box
                                                component="div"
                                                sx={{
                                                  display: "inline",
                                                  mr: 1.5,
                                                }}
                                              >
                                                {item.operator === "EQUAL" &&
                                                  "="}
                                                {item.operator ===
                                                  "GREATER_THAN" && ">"}
                                                {item.operator ===
                                                  "GREATER_THAN_OR_EQUAL" &&
                                                  ">="}
                                                {item.operator ===
                                                  "LOWER_THAN" && "<"}
                                                {item.operator ===
                                                  "LOWER_THAN_OR_EQUAL" && "<="}
                                              </Box>
                                              <Box
                                                component="div"
                                                sx={{
                                                  display: "inline",
                                                  mr: 0.5,
                                                }}
                                              >
                                                {item.multiplier_b}
                                                {item.var_b === "NONE"
                                                  ? ""
                                                  : "*"}
                                              </Box>
                                              <Box
                                                component="div"
                                                sx={{
                                                  display: "inline",
                                                  mr: 1.5,
                                                }}
                                              >
                                                {item.var_b === "NONE"
                                                  ? ""
                                                  : replaceString(item.var_b)}
                                              </Box>
                                            </Box>
                                          </Box>
                                        ))}
                                      ></ListItemText>
                                    </ListItem>
                                    {type.algorithm_type ===
                                    "BIDDING_OPTIMIZATION" ? (
                                      <ListItem>
                                        <ListItemText
                                          primary={
                                            <>
                                              Rule Period:{" "}
                                              <strong>
                                                {rule.period.join(", ") +
                                                  " days"}
                                              </strong>
                                            </>
                                          }
                                        ></ListItemText>
                                      </ListItem>
                                    ) : (
                                      ""
                                    )}
                                    <Divider />
                                  </>
                                ))}

                                {algorithm.action ? (
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        <>
                                          Action:
                                          <br />
                                          {convertActionToString(
                                            algorithm.action
                                          )}
                                        </>
                                      }
                                    ></ListItemText>
                                  </ListItem>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </List>
                          </>
                        ) : (
                          ""
                        )}
                      </CardContent>
                    </Card>
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
}
