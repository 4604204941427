import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    CREATE_TASK_ERROR,
    CREATE_TASK_LOADING,
    CREATE_TASK_SUCCESS,
    DELETE_TASK_ERROR,
    DELETE_TASK_LOADING,
    DELETE_TASK_SUCCESS,
    GET_TASK_ERROR,
    GET_TASK_LOADING,
    GET_TASK_SUCCESS,
    UPDATE_TASK_ERROR,
    UPDATE_TASK_LOADING,
    UPDATE_TASK_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const createTask = (task) => (dispatch) => {
    dispatch({
        type: CREATE_TASK_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/task`, task)
        .then((res) => {
            dispatch({
                type: CREATE_TASK_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
                const errors = []
                err.response.data.detail.map((item) => errors.push(item.loc[1]))
                dispatch({
                    type: CREATE_TASK_ERROR,
                    payload: {errors, errorType: "VALIDATION_ERROR"}
                });
            } else {
                dispatch({
                    type: CREATE_TASK_ERROR,
                    payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
                });
            }})
};

export const updateTask = (task) => (dispatch) => {
    dispatch({
        type: UPDATE_TASK_LOADING,
    });
    

    axiosInstance()
        .put(`/api/${version}/task`, task)
        .then((res) => {
            dispatch({
                type: UPDATE_TASK_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
                const errors = []
                err.response.data.detail.map((item) => errors.push(item.loc[1]))
                dispatch({
                    type: UPDATE_TASK_ERROR,
                    payload: {errors, errorType: "VALIDATION_ERROR"}
                });
            } else {
                dispatch({
                    type: UPDATE_TASK_ERROR,
                    payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
                });
            }})
};

export const getTasks = () => (dispatch) => {
    dispatch({
        type: GET_TASK_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/task`)
        .then((res) => {
            dispatch({
                type: GET_TASK_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_TASK_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteTask = (id) => (dispatch) => {
    dispatch({
        type: DELETE_TASK_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/task/${id}`)
        .then((res) => {
            dispatch({
                type: DELETE_TASK_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_TASK_ERROR,
                payload: err.response ? err.response.data : "could not connect",
            });
        });
};

