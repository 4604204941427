import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export function AlertDialogChangeMaxBudget({
  open,
  handleClose,
  handleChange,
  dataForEdit,
}) {
  const [data, setData] = useState({
    daily_budget_reset: "",
  });

  useEffect(() => {
    if (dataForEdit) {
      setData((prevState) => {
        return { ...prevState, ...dataForEdit };
      });
    }
  }, [dataForEdit]);

  const handleChangeSave = () => {
    handleChange(data.daily_budget_reset);
  };

  const handleChangeMaxBudget = (e) => {
    setData({ daily_budget_reset: e.target.value });
  };

  const percentage = (num, per) => {
    return (num / 100) * per;
  };

  const handleAdd10Percent = () => {
    const dataDaily = data.daily_budget_reset;
    const percent = percentage(dataDaily, 10);
    const res = dataDaily + percent;
    setData({ daily_budget_reset: res });
  };

  const handleRemove10Percent = () => {
    const dataDaily = data.daily_budget_reset;
    const percent = percentage(dataDaily, 10);
    const res = dataDaily - percent;
    setData({ daily_budget_reset: res });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={handleChangeMaxBudget}
                id="changeMaxBudget"
                label="Change Max Budget"
                name="changeMaxBudget"
                value={data.daily_budget_reset}
              />
              <Button variant="text" onClick={() => handleAdd10Percent(false)}>
                +
              </Button>
              <Button
                variant="text"
                onClick={() => handleRemove10Percent()}
              >
                -
              </Button>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleChangeSave}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
