import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../../context/Provider";
import {AlertDialog} from "../../components/dialog";
import {CreateAndEditDialog} from "../../components/createAndEditDialog";
import TransitionAlerts from "../../components/alert";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {CreateEditTsAccount} from "./createEditTsAccount";
import {
    createTsAccount,
    deleteTsAccount,
    getTsAccounts,
    updateTsAccount,
} from "../../context/actions/traffic/tsAccounts";
import LoadingSpinner from "../../components/LoadingSpinner";

function TsAccountsList() {
    const columns = [
        {field: "name", headerName: "Name", width: 300},
        {field: "api_key", headerName: "Api Key", width: 400},
        {field: "created_by", headerName: "Created By", width: 100},
        {field: "traffic_source_id", headerName: "Traffic Source", width: 200},
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                        <DeleteIcon
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);

    const {
        tsAccountsList: {data, loading, error, message},
    } = tsAccountsState;
    const [openAlertEmail, setOpenAlertEmail] = useState(false);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        setOpenAlertEmail(error);
    }, [error]);

    const redirectToEdit = (cellValues) => {
        setOpenCreateEditDialog(true);
        setSelectedTsAccount(cellValues.row);
    };
    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleClick = (event, cellValues) => {
        setTsAccount(cellValues.row.name);
        setTsAccountId(cellValues.row.id);
        setOpen(true);
    };

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [tsAccount, setTsAccount] = useState("");
    const [tsAccountId, setTsAccountId] = useState("");
    const [selectedTsAccount, setSelectedTsAccount] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setTsAccount("");
        setTsAccountId("");
    };

    const handleDelete = () => {
        selectedTsAccount
            ? deleteTsAccount(selectedTsAccount?.id)(tsAccountsDispatch)
            : deleteTsAccount(tsAccountId)(tsAccountsDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setSelectedTsAccount(null);
    };

    const handleCloseCreateEditDialog = () => {
        setOpenCreateEditDialog(false);
        setSelectedTsAccount(null);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const addOrEdit = (props) => {
        setOpenCreateEditDialog(false);
        setSelectedTsAccount(null);
        props.id
            ? updateTsAccount(props)(tsAccountsDispatch)
            : createTsAccount(props)(tsAccountsDispatch);
    };

    const handleAlertDialog = () => {
        setOpenAlertEmail((prevCheck) => !prevCheck);
    };
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Traffic Source
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button
                        sx={{mt: 3, mb: 5}}
                        variant="contained"
                        color="success"
                        onClick={handleOpenCreateDialog}
                    >
                        Create TS Account
                    </Button>
                </Grid>

                <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                    {openAlertEmail && (
                        <TransitionAlerts
                            title={message}
                            handleAlertDialog={handleAlertDialog}
                        />
                    )}
                </Grid>

                <Grid
                    item
                    xs={4}
                    sx={{display: "flex", justifyContent: "flex-end"}}
                />
            </Grid>

            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateEditTsAccount
                    recordForEdit={selectedTsAccount}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                >
                    <AlertDialog
                        open={openDeleteDialog}
                        handleClose={alertInCreateDialog}
                        name={selectedTsAccount?.name}
                        handleDelete={handleDelete}
                    />
                </CreateEditTsAccount>
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={tsAccount}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {TsAccountsList};
