import {
    CREATE_ALGORITHMS_ERROR,
    CREATE_ALGORITHMS_LOADING,
    CREATE_ALGORITHMS_SUCCESS,
    CREATE_DEFAULT_ALGORITHMS_ERROR,
    CREATE_DEFAULT_ALGORITHMS_LOADING,
    CREATE_DEFAULT_ALGORITHMS_SUCCESS,
    DELETE_ALGORITHMS_ERROR,
    DELETE_ALGORITHMS_ERROR_MASSAGE,
    DELETE_ALGORITHMS_LOADING,
    DELETE_ALGORITHMS_SUCCESS,
    DELETE_DEFAULT_ALGORITHMS_ERROR,
    DELETE_DEFAULT_ALGORITHMS_SUCCESS,
    GET_ALGORITHMS_ERROR,
    GET_ALGORITHMS_LOADING,
    GET_ALGORITHMS_NAME_ERROR,
    GET_ALGORITHMS_NAME_LOADING,
    GET_ALGORITHMS_NAME_SUCCESS,
    GET_ALGORITHMS_SUCCESS,
    GET_ALGORITHMS_TYPES_ERROR,
    GET_ALGORITHMS_TYPES_LOADING,
    GET_ALGORITHMS_TYPES_SUCCESS,
    GET_DEFAULT_ALGORITHMS_ERROR,
    GET_DEFAULT_ALGORITHMS_LOADING,
    GET_DEFAULT_ALGORITHMS_SUCCESS,
    TURN_ON_OFF_ALGORITHMS_ERROR,
    TURN_ON_OFF_ALGORITHMS_LOADING,
    TURN_ON_OFF_ALGORITHMS_SUCCESS,
} from "../../constants/actionTypes";

const algorithms = (state, {payload, type}) => {
    switch (type) {
        case GET_ALGORITHMS_TYPES_LOADING: {
            return {
                ...state,
                algorithmsTypes: {
                    ...state.algorithmsTypes,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_ALGORITHMS_TYPES_SUCCESS: {
            return {
                ...state,
                algorithmsTypes: {
                    ...state.algorithmsTypes,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_ALGORITHMS_TYPES_ERROR: {
            return {
                ...state,
                algorithmsTypes: {
                    ...state.algorithmsTypes,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_ALGORITHMS_LOADING: {
            return {
                ...state,
                addAlgorithms: {
                    ...state.addAlgorithms,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_ALGORITHMS_ERROR: {
            return {
                ...state,
                addAlgorithms: {
                    ...state.addAlgorithms,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                addAlgorithms: {
                    ...state.addAlgorithms,
                    loading: false,
                    data: payload,
                },
            };
        }

        case GET_ALGORITHMS_LOADING: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    data: payload,
                },
            };
        }

        case GET_ALGORITHMS_ERROR: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case TURN_ON_OFF_ALGORITHMS_LOADING: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: true,
                    error: false,
                },
            };
        }

        case TURN_ON_OFF_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    data: payload,
                },
            };
        }

        case TURN_ON_OFF_ALGORITHMS_ERROR: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case DELETE_ALGORITHMS_LOADING: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    data: state.algorithmsList.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }
        case GET_DEFAULT_ALGORITHMS_LOADING: {
            return {
                ...state,
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_DEFAULT_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_DEFAULT_ALGORITHMS_ERROR: {
            return {
                ...state,
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_DEFAULT_ALGORITHMS_LOADING: {
            return {
                ...state,
                addDefaultAlgorithms: {
                    ...state.addDefaultAlgorithms,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_DEFAULT_ALGORITHMS_ERROR: {
            return {
                ...state,
                addDefaultAlgorithms: {
                    ...state.addDefaultAlgorithms,
                    loading: false,
                },
            };
        }

        case CREATE_DEFAULT_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                addDefaultAlgorithms: {
                    ...state.addDefaultAlgorithms,
                    loading: false,
                    data: payload,
                },
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: false,
                    data: [payload, ...state.defaultAlgorithms.data],
                },
            };
        }

        case DELETE_DEFAULT_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: false,
                    data: state.defaultAlgorithms.data.filter(
                        (item) => item.id !== payload
                    ),
                },
            };
        }
        case DELETE_DEFAULT_ALGORITHMS_ERROR: {
            return {
                ...state,
                defaultAlgorithms: {
                    ...state.defaultAlgorithms,
                    loading: false,
                    message: payload,
                    error: true,
                },
            };
        }

        case DELETE_ALGORITHMS_SUCCESS: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    data: state.algorithmsList.data.filter((item) => item.id !== payload),
                },
            };
        }
        case DELETE_ALGORITHMS_ERROR: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    message: payload,
                    error: true,
                },
            };
        }

        case DELETE_ALGORITHMS_ERROR_MASSAGE: {
            return {
                ...state,
                algorithmsList: {
                    ...state.algorithmsList,
                    loading: false,
                    message: payload,
                    error: false,
                },
            };
        }

        case GET_ALGORITHMS_NAME_LOADING: {
            return {
                ...state,
                algorithmsName: {
                    ...state.algorithmsName,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_ALGORITHMS_NAME_SUCCESS: {
            return {
                ...state,
                algorithmsName: {
                    ...state.algorithmsName,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_ALGORITHMS_NAME_ERROR: {
            return {
                ...state,
                algorithmsName: {
                    ...state.algorithmsName,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default algorithms;
