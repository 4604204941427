import React, {useMemo} from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import {PeriodPanel} from "./periodPanel";

const typesWithPeriodsPerRule = ["BIDDING_OPTIMIZATION",
    "DECREASE_DAILY_BUDGET_CPC",
    "DECREASE_DAILY_BUDGET_CPM",
    "INCREASE_DAILY_BUDGET_CPC",
    "INCREASE_DAILY_BUDGET_CPM"
];

const typesWith7DaysPeriodOnly = ["PAUSE_CAMPAIGN_LAST_7_DAYS_CPC", "PAUSE_CAMPAIGN_LAST_7_DAYS_CPM"];
const typesWith30DaysPeriodOnly = ["PAUSE_CAMPAIGN_LAST_30_DAYS_CPC", "PAUSE_CAMPAIGN_LAST_30_DAYS_CPM"];

const defaultAvailablePeriods = [3, 7, 30];

const METRIC_A_PRETTY = {
    "G1": "G1",
    "G2": "G2",
    "COST": "Cost",
    "CLICKS": "Clicks",
    "IMPRESSIONS": "Impressions",
    "ECPA_OF_G1": "eCPA of G1",
    "ECPA_OF_G2": "eCPA of G2",
    "ROI": "ROI",
    "PROFIT": "Profit",
    "CTR": "CTR",
    "CPM": "CPM",
    "CPC": "CPC",
    "NUMBER_OF_VARIATIONS": "Number of variations",
    "DAILY_BUDGET": "Daily budget",
    "CAMPAIGN_AGE": "Campaign age",
    "TRAFFIC_ESTIMATE": "Traffic Estimate",
}

const METRIC_B = {
    NONE: "NONE",
    EPA: "EPA",
    COST: "COST"
}

const OPERATOR_PRETTY = {
    "EQUAL": "=",
    "GREATER_THAN": ">",
    "GREATER_THAN_OR_EQUAL": "≥",
    "LOWER_THAN": "<",
    "LOWER_THAN_OR_EQUAL": "≤"
}

const createNewPart = () => ({
    var_a: "",
    var_b: METRIC_B.NONE,
    operator: "",
    multiplier_a: 1.0,
    multiplier_b: 1.0,
})

export const createNewRule = () => ({
    parts: [createNewPart()],
    period: []
})

const isPartValid = (part) => {
    return part.var_a && part.var_a !== "" &&
        part.var_b && part.var_b !== "" &&
        part.operator !== "" &&
        part.multiplier_a !== undefined &&
        part.multiplier_b !== undefined;
}

const checkAlgorithmRuleValidity = (rule, selectedAlgorithmType) => {
    if (!rule.parts || rule.parts.length === 0) {
        return false;
    }

    const allPartsInRuleAreOk = rule.parts.every(isPartValid)
    if (selectedAlgorithmType !== "BIDDING_OPTIMIZATION") {
        return allPartsInRuleAreOk;
    }

    // at least one period is selected for this rule
    return rule.period && rule.period.length > 0;
}

export const checkAlgorithmRulesValidity = (rules, selectedAlgorithmType) => {
    if (rules.length === 0) {
        return false
    }

    return rules.every((rule) => checkAlgorithmRuleValidity(rule, selectedAlgorithmType));
}

export function AddRules(props) {
    const {
        /* string */
        selectedType,
        /* [number] */
        selectedAlgorithmPeriods,
        /* ([number]) => void */
        setSelectedAlgorithmPeriods,
        /* [{
              parts: [{
                var_a: METRIC_A,
                var_b: METRIC_B,
                operator: OPERATOR,
                multiplier_a: float32,
                multiplier_b: float32,
              }],
              period: [int]
            }]
         */
        algorithmRules,
        /* (algorithmRules) => void */
        setAlgorithmRules
    } = props;
    const availablePeriods = typesWith7DaysPeriodOnly.includes(selectedType)
        ? [7]
        : typesWith30DaysPeriodOnly.includes(selectedType)
            ? [3]
            : defaultAvailablePeriods

    const iF = {
        marginLeft: "40px",
        paddingTop: "30px",
    };
    const is = {
        paddingLeft: "17px",
        paddingTop: "30px",
    };

    const usePeriodsPerRule = useMemo(() => typesWithPeriodsPerRule.includes(selectedType), [selectedType])

    const removeRule = (indexOrRule) => {
        let copyOfRules = [...algorithmRules];
        copyOfRules.splice(indexOrRule, 1);
        setAlgorithmRules(copyOfRules);
    };

    const handleRulePartChange = (event, indexOfPart, indexOfRule) => {
        let copyOfRules = [...algorithmRules];
        copyOfRules[indexOfRule].parts[indexOfPart][event.target.name] = event.target.value;
        setAlgorithmRules(copyOfRules);
    };

    const addNewRule = () => {
        let newRule = createNewRule();
        setAlgorithmRules([...algorithmRules, newRule]);
    };


    const removeRulePart = (indexOfPart, indexOfRule) => {
        // find rule and copy it
        const ruleBeingModified = algorithmRules[indexOfRule];
        const newStateOfRule = {...ruleBeingModified}

        // set new state of parts
        let newStateOfParts = [...newStateOfRule.parts];
        newStateOfParts.splice(indexOfPart, 1);
        newStateOfRule.parts = newStateOfParts

        const newStateOfRules = algorithmRules.map((existingRule, index) => {
            if (index === indexOfRule) {
                return newStateOfRule;
            }
            return existingRule;
        });
        setAlgorithmRules(newStateOfRules)
    };

    const addNewRulePart = (indexOfRuleBeingModified) => {
        const newPart = createNewPart();
        const existingRuleAtIndex = algorithmRules[indexOfRuleBeingModified];
        const newStateOfRule = {...existingRuleAtIndex}
        newStateOfRule.parts = [...newStateOfRule.parts, newPart];

        const modifiedRules = algorithmRules.map((existingRule, index) => {
            if (index === indexOfRuleBeingModified) {
                return newStateOfRule;
            }
            return existingRule;
        });
        setAlgorithmRules(modifiedRules);
    };


    const handleSetRulePeriods = (indexOfRuleBeingModified, newSelectedPeriods) => {
        const existingRule = algorithmRules[indexOfRuleBeingModified];
        const modifiedRule = {...existingRule};
        modifiedRule.period = newSelectedPeriods;
        const newStateOfRules = algorithmRules.map((existingRule, index) => {
            if (index === indexOfRuleBeingModified) {
                return modifiedRule
            }
            return existingRule
        })
        setAlgorithmRules(newStateOfRules);
    };

    return (
        <>

            {!usePeriodsPerRule
                ? <PeriodPanel
                    label={"Algorithm level period"}
                    availablePeriods={availablePeriods}
                    selectedPeriods={selectedAlgorithmPeriods}
                    onSelectedPeriodsChange={setSelectedAlgorithmPeriods}
                />
                : null}

            {algorithmRules?.map((rule, indexOfRule) => {
                return (
                    <div style={{
                        border: "1px solid grey",
                        borderRadius: "5px",
                        marginBottom: "10px",
                    }} key={indexOfRule}>
                        <Button
                            sx={{mt: 3, mb: 2, mr: 3, float: "right"}}
                            onClick={() => removeRule(indexOfRule)}
                            color="success"
                            variant="contained"
                            disabled={algorithmRules.length <= 1}
                        >
                            REMOVE RULE
                        </Button>
                        {usePeriodsPerRule ? (
                            <PeriodPanel
                                label={"Period for rule:"}
                                availablePeriods={availablePeriods}
                                selectedPeriods={algorithmRules[indexOfRule].period}
                                onSelectedPeriodsChange={(selectedPeriods) => {
                                    handleSetRulePeriods(indexOfRule, selectedPeriods);
                                }}
                            />
                        ) : null}
                        {rule.parts.map((rulePart, indexOfPart) => {
                            return (
                                <div key={indexOfPart}>
                                    <Grid
                                        key={indexOfPart}
                                        container
                                        spacing={2}
                                        style={{flexWrap: "initial"}}
                                    >
                                        <div style={iF}>IF</div>
                                        <Grid item xs={2}>
                                            <FormControl required fullWidth>
                                                <InputLabel id="campaign-type-select-label">
                                                    Variable A
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="campaign-type-select-label"
                                                    id="campaign-type-select"
                                                    name="var_a"
                                                    label="Select Variable A"
                                                    value={rulePart.var_a}
                                                    onChange={(event) =>
                                                        handleRulePartChange(event, indexOfPart, indexOfRule)
                                                    }
                                                >
                                                    <MenuItem key="" value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {Object.entries(METRIC_A_PRETTY).map(([metric, prettyMetricName]) =>
                                                        <MenuItem key={metric} value={metric}>
                                                            {prettyMetricName}
                                                        </MenuItem>)
                                                    }
                                                </Select>
                                                {!rulePart.var_a ? (
                                                    <FormHelperText error>
                                                        Select Variable A
                                                    </FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                        <div style={is}>X</div>
                                        <Grid item xs={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                onChange={(event) =>
                                                    handleRulePartChange(event, indexOfPart, indexOfRule)
                                                }
                                                id="remote_id"
                                                label="Number"
                                                name="multiplier_a"
                                                value={rulePart.multiplier_a}
                                            />
                                            {!rulePart.multiplier_a ? (
                                                <FormHelperText style={{display: "block"}} error>
                                                    Select Number
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                        <div style={is}>Is</div>
                                        <Grid
                                            item
                                            xs={2}
                                            sx={{display: "flex", justifyContent: "flex-end"}}
                                        >
                                            <FormControl required fullWidth>
                                                <InputLabel id="campaign-type-select-label">
                                                    Operator
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="campaign-type-select-label"
                                                    id="campaign-type-select"
                                                    label="Operator"
                                                    name="operator"
                                                    value={rulePart.operator}
                                                    onChange={(event) =>
                                                        handleRulePartChange(event, indexOfPart, indexOfRule)
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {Object.entries(OPERATOR_PRETTY).map(([operator, operatorPretty]) =>
                                                        <MenuItem key={operator}
                                                                  value={operator}>{operatorPretty}</MenuItem>
                                                    )}
                                                </Select>
                                                {!rulePart.operator ? (
                                                    <FormHelperText error>Select Operator</FormHelperText>
                                                ) : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                onChange={(event) =>
                                                    handleRulePartChange(event, indexOfPart, indexOfRule)
                                                }
                                                id="remote_id"
                                                label="Number"
                                                name="multiplier_b"
                                                value={rulePart.multiplier_b}
                                            />
                                            {(rulePart.multiplier_b === "") ? (
                                                <FormHelperText error>Select Number</FormHelperText>
                                            ) : null}
                                        </Grid>
                                        <div style={is}>X</div>
                                        <Grid
                                            item
                                            xs={2}
                                            sx={{display: "flex", justifyContent: "flex-end"}}
                                        >
                                            <FormControl required fullWidth>
                                                <InputLabel id="campaign-type-select-label">
                                                    Variable B
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="campaign-type-select-label"
                                                    id="campaign-type-select"
                                                    label="Variable B"
                                                    name="var_b"
                                                    value={rulePart.var_b}
                                                    onChange={(event) =>
                                                        handleRulePartChange(event, indexOfPart, indexOfRule)
                                                    }
                                                >
                                                    <MenuItem key="" value="">
                                                        <em>No selected</em>
                                                    </MenuItem>
                                                    {Object.entries(METRIC_B).map(([metric, metricPretty]) =>
                                                        <MenuItem key={metric} value={metric}>{metricPretty}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                sx={{mr: 5}}
                                                onClick={() => removeRulePart(indexOfPart, indexOfRule)}
                                                color="success"
                                                disabled={rule.parts.length <= 1}
                                            >
                                                REMOVE PART
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={
                                                usePeriodsPerRule
                                                    ? {display: "block", textAlign: "start"}
                                                    : null
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <FormControl
                                            sx={{m: 3, display: "block"}}
                                            component="fieldset"
                                            variant="standard"
                                        />
                                    </Box>
                                </div>
                            );
                        })}
                        <Button
                            sx={{ml: 3, mb: 2}}
                            onClick={() => addNewRulePart(indexOfRule)}
                            color="warning"
                            variant="contained"
                        >
                            + ADD ANOTHER RULE PART
                        </Button>
                    </div>
                );
            })}

            <Button
                sx={{mt: 2, mb: 2, mr: 5}}
                onClick={addNewRule}
                color="warning"
                variant="contained"
            >
                ADD ANOTHER RULE
            </Button>
        </>
    );
}
