import {
    CREATE_BIDDING_BY_ID_ERROR,
    CREATE_BIDDING_BY_ID_LOADING,
    CREATE_BIDDING_BY_ID_SUCCESS,
    DELETE_BID_SCHEDULING_ERROR,
    DELETE_BID_SCHEDULING_LOADING,
    DELETE_BID_SCHEDULING_SUCCESS,
    UPDATE_BID_SCHEDULING_ERROR,
    UPDATE_BID_SCHEDULING_LOADING,
    UPDATE_BID_SCHEDULING_SUCCESS,
} from "../../constants/actionTypes";

const bidding = (state, {payload, type}) => {
    switch (type) {
        case CREATE_BIDDING_BY_ID_LOADING: {
            return {
                ...state,
                biddingReport: {
                    ...state.biddingReport,
                    loading: true,
                    data: payload,
                },
            };
        }
        case CREATE_BIDDING_BY_ID_SUCCESS: {
            return {
                ...state,
                biddingReport: {
                    ...state.biddingReport,
                    loading: false,
                    data: payload,
                    error: null,
                },
            };
        }
        case CREATE_BIDDING_BY_ID_ERROR: {
            return {
                ...state,
                biddingReport: {
                    ...state.biddingReport,
                    loading: false,
                    message: payload,
                    data: null,
                },
            };
        }
        case UPDATE_BID_SCHEDULING_LOADING: {
            return {
                ...state,
                createSchedulingBid: {
                    ...state.createSchedulingBid,
                    loading: true,
                },
            };
        }

        case UPDATE_BID_SCHEDULING_SUCCESS: {
            return {
                ...state,
                createSchedulingBid: {
                    ...state.createSchedulingBid,
                    loading: true,
                    data: payload,
                    message: null,
                    error: false,
                },
            };
        }
        case UPDATE_BID_SCHEDULING_ERROR: {
            return {
                ...state,
                createSchedulingBid: {
                    ...state.createSchedulingBid,
                    loading: false,
                    data: null,
                    message: payload,
                    error: true,
                },
            };
        }
        case DELETE_BID_SCHEDULING_LOADING: {
            return {
                ...state,
                deleteBidding: {
                    ...state.deleteBidding,
                    loading: true,
                    data: payload,
                },
            };
        }
        case DELETE_BID_SCHEDULING_SUCCESS: {
            return {
                ...state,
                deleteBidding: {
                    ...state.deleteBidding,
                    loading: false,
                    data: payload,
                },
            };
        }
        case DELETE_BID_SCHEDULING_ERROR: {
            return {
                ...state,
                deleteBidding: {
                    ...state.deleteBidding,
                    loading: false,
                    error: payload,
                },
            };
        }
        default:
            return state;
    }
};
export default bidding;
