import {
    CHANGE_BID_CAMPAIGN_ERROR,
    CHANGE_BID_CAMPAIGN_LOADING,
    CHANGE_BID_CAMPAIGN_SUCCESS,
    CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR,
    CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING,
    CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS,
    DUPLICATE_CAMPAIGN_ERROR,
    DUPLICATE_CAMPAIGN_LOADING,
    DUPLICATE_CAMPAIGN_SUCCESS,
    GET_BID_HISTORY_REPORT_ERROR,
    GET_BID_HISTORY_REPORT_LOADING,
    GET_BID_HISTORY_REPORT_SUCCESS,
    GET_CAMPAIGN_REPORT_BY_ID_ERROR,
    GET_CAMPAIGN_REPORT_BY_ID_LOADING,
    GET_CAMPAIGN_REPORT_BY_ID_SUCCESS,
    GET_ERRORS_ERROR,
    GET_ERRORS_LOADING,
    GET_ERRORS_SUCCESS,
    GET_PERFORMANCE_MONITOR_REPORT_ERROR,
    GET_PERFORMANCE_MONITOR_REPORT_LOADING,
    GET_PERFORMANCE_MONITOR_REPORT_SUCCESS,
    GET_REPORT_LOADING,
    GET_REPORT_SUCCESS,
    GET_REPORT_ERROR,
    GET_SUCCESSES_ERROR,
    GET_SUCCESSES_LOADING,
    GET_SUCCESSES_SUCCESS,
    GET_TOTAL_REPORT_ERROR,
    GET_TOTAL_REPORT_LOADING,
    GET_TOTAL_REPORT_SUCCESS,
    GET_VARIATIONS_HISTORY_REPORT_ERROR,
    GET_VARIATIONS_HISTORY_REPORT_LOADING,
    GET_VARIATIONS_HISTORY_REPORT_SUCCESS,
    PAUSE_CAMPAIGN_ERROR,
    PAUSE_CAMPAIGN_LOADING,
    PAUSE_CAMPAIGN_SUCCESS
} from "../../constants/actionTypes";

const reports = (state, action) => {

    switch (action.type) {
        case GET_CAMPAIGN_REPORT_BY_ID_LOADING: {
            return {
                ...state,
                campaignReport: {
                    ...state.campaignReport,
                    loading: true,
                },
            };
        }
        case GET_CAMPAIGN_REPORT_BY_ID_SUCCESS: {
            return {
                ...state,
                campaignReport: {
                    ...state.campaignReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }
        case GET_CAMPAIGN_REPORT_BY_ID_ERROR: {
            return {
                ...state,
                campaignReport: {
                    ...state.campaignReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }
        case GET_REPORT_LOADING: {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true,
                },
            };
        }
        case GET_REPORT_SUCCESS: {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    data: action.payload,
                },
            };
        }
        case GET_REPORT_ERROR: {
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    error: action.payload,
                },
            }
        }
        case PAUSE_CAMPAIGN_LOADING: {
            return {
                ...state,
                pauseCampaign: {
                    ...state.pauseCampaign,
                    loading: true,
                },
            };
        }
        case PAUSE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                pauseCampaign: {
                    ...state.pauseCampaign,
                    loading: false,
                },
            };
        }

        case PAUSE_CAMPAIGN_ERROR: {
            return {
                ...state,
                pauseCampaign: {
                    ...state.pauseCampaign,
                    loading: false,
                },
            };
        }

        case DUPLICATE_CAMPAIGN_LOADING: {
            return {
                ...state,
                duplicateCampaign: {
                    ...state.duplicateCampaign,
                    loading: true,
                },
            };
        }

        case DUPLICATE_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                duplicateCampaign: {
                    ...state.duplicateCampaign,
                    loading: false,
                },
            };
        }

        case DUPLICATE_CAMPAIGN_ERROR: {
            return {
                ...state,
                duplicateCampaign: {
                    ...state.duplicateCampaign,
                    loading: false,
                },
            };
        }
        case CHANGE_BID_CAMPAIGN_LOADING: {
            return {
                ...state,
                changeBid: {
                    ...state.changeBid,
                    loading: true,
                },
            };
        }

        case CHANGE_BID_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaignReport: {
                    loading: false,
                    data: state.campaignReport.data.map((element) => {
                        if (element.campaign_id === action.payload.remote_id) {
                            return {...element, bid: action.payload.bid_price};
                        }
                        return element;
                    }),
                },
            };
        }

        case CHANGE_BID_CAMPAIGN_ERROR: {
            return {
                ...state,
                changeBid: {
                    ...state.changeBid,
                    loading: false,
                },
            };
        }

        case CHANGE_DAILY_BUDGET_CAMPAIGN_LOADING: {
            return {
                ...state,
                changeDailyBudget: {
                    ...state.changeDailyBudget,
                    loading: true,
                },
            };
        }

        case CHANGE_DAILY_BUDGET_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                campaignReport: {
                    loading: false,
                    data: state.campaignReport.data.map((element) => {
                        if (element.campaign_id === action.payload.remote_id) {
                            debugger;
                            return {
                                ...element,
                                daily_budget_reset: action.payload.daily_budget_reset,
                            };
                        }
                        return element;
                    }),
                },
            };
        }

        case CHANGE_DAILY_BUDGET_CAMPAIGN_ERROR: {
            return {
                ...state,
                changeDailyBudget: {
                    ...state.changeDailyBudget,
                    loading: false,
                },
            };
        }

        case GET_TOTAL_REPORT_LOADING: {
            return {
                ...state,
                totalReport: {
                    ...state.totalReport,
                    loading: true,
                },
            };
        }

        case GET_TOTAL_REPORT_SUCCESS: {
            return {
                ...state,
                totalReport: {
                    ...state.totalReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }

        case GET_TOTAL_REPORT_ERROR: {
            return {
                ...state,
                totalReport: {
                    ...state.totalReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case GET_BID_HISTORY_REPORT_LOADING: {
            return {
                ...state,
                bidReport: {
                    ...state.bidReport,
                    loading: true,
                },
            };
        }

        case GET_BID_HISTORY_REPORT_SUCCESS: {
            return {
                ...state,
                bidReport: {
                    ...state.bidReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }

        case GET_BID_HISTORY_REPORT_ERROR: {
            return {
                ...state,
                bidReport: {
                    ...state.bidReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case GET_VARIATIONS_HISTORY_REPORT_LOADING: {
            return {
                ...state,
                variationsReport: {
                    ...state.variationsReport,
                    loading: true,
                },
            };
        }

        case GET_VARIATIONS_HISTORY_REPORT_SUCCESS: {
            return {
                ...state,
                variationsReport: {
                    ...state.variationsReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }

        case GET_VARIATIONS_HISTORY_REPORT_ERROR: {
            return {
                ...state,
                variationsReport: {
                    ...state.variationsReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case GET_SUCCESSES_LOADING: {
            return {
                ...state,
                successReport: {
                    ...state.successReport,
                    loading: true,
                },
            };
        }

        case GET_SUCCESSES_SUCCESS: {
            return {
                ...state,
                successReport: {
                    ...state.successReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }
        case GET_SUCCESSES_ERROR: {
            return {
                ...state,
                successReport: {
                    ...state.successReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case GET_ERRORS_LOADING: {
            return {
                ...state,
                errorReport: {
                    ...state.errorReport,
                    loading: true,
                },
            };
        }

        case GET_ERRORS_SUCCESS: {
            return {
                ...state,
                errorReport: {
                    ...state.errorReport,
                    loading: false,
                    data: action.payload,
                },
            };
        }
        case GET_ERRORS_ERROR: {
            return {
                ...state,
                errorReport: {
                    ...state.errorReport,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        case GET_PERFORMANCE_MONITOR_REPORT_LOADING: {
            return {
                ...state,
                performanceMonitor: {
                    ...state.performanceMonitor,
                    loading: true,
                },
            };
        }

        case GET_PERFORMANCE_MONITOR_REPORT_SUCCESS: {
            return {
                ...state,
                performanceMonitor: {
                    ...state.performanceMonitor,
                    loading: false,
                    data: action.payload,
                },
            };
        }
        case GET_PERFORMANCE_MONITOR_REPORT_ERROR: {
            return {
                ...state,
                performanceMonitor: {
                    ...state.performanceMonitor,
                    loading: false,
                    error: action.payload,
                },
            };
        }

        default:
            return state;
    }
};

export default reports;
