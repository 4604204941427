import React, {useContext, useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import {
    createDefaultAlgorithms,
    deleteAlgorithms,
    deleteDefaultAlgorithms,
    getAlgorithms,
    getAlgorithmsTypes,
    getDefaultAlgorithms,
    resetMessageAlgorithmDelete,
} from "../context/actions/algorithms/algorithms";
import {GlobalContext} from "../context/Provider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {AlertDialog} from "../components/dialog";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Grid from "@mui/material/Grid";
import {getTrafficSource} from "../context/actions/traffic/trafficSource";
import TransitionAlerts from "../components/alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";

export function ListOfDefaultAlgorithms() {
    const {
        algorithmsState,
        algorithmsDispatch,
        tsAccountsDispatch,
        tsAccountsState,
    } = useContext(GlobalContext);
    const {
        algorithmsList: {data, message, error},
        algorithmsTypes,
        defaultAlgorithms,
    } = algorithmsState;
    const {tsAccountsList} = tsAccountsState;
    const {trafficSourceList} = tsAccountsState;


    const [open, setOpen] = useState(false);
    const [algorithmId, setAlgorithmId] = useState("");
    const [algorithmName, setAlgorithmName] = useState("");
    const [trafficSource, setTrafficSource] = useState("1");
    const [tsAccount, setTsAccount] = useState("");
    const [campaignType, setCampaignType] = useState("SEED");
    const [openAlertEmail, setOpenAlertEmail] = useState(false);

    useEffect(() => {
        getAlgorithmsTypes()(algorithmsDispatch);
    }, [algorithmsDispatch]);

    useEffect(() => {
        getAlgorithms(trafficSource)(algorithmsDispatch);
    }, [algorithmsDispatch, trafficSource]);

    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        if (campaignType && tsAccount) {
            getDefaultAlgorithms(null, campaignType, tsAccount)(algorithmsDispatch);
        }
    }, [algorithmsDispatch, tsAccount, campaignType]);

    useEffect(() => {
        setOpenAlertEmail(error);
        if (openAlertEmail) {
            window.scrollTo({top: 0, behavior: "smooth"});
        }
    }, [error, openAlertEmail]);

    const handleClose = () => {
        setOpen(false);
        setAlgorithmId("");
        setAlgorithmName("");
    };

    const handleChangeTs = (event) => {
        setTrafficSource(event.target.value);
    };

    const handleDelete = () => {
        setOpen(false);
        deleteAlgorithms(algorithmId)(algorithmsDispatch);
        setAlgorithmId("");
    };

    const handleAlertDialog = () => {
        setOpenAlertEmail((prevCheck) => !prevCheck);
        resetMessageAlgorithmDelete()(algorithmsDispatch);
    };

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    const checkTrueOrFalse = (item) => {
        const asd = [...defaultAlgorithms.data];
        const array = asd.map((item) => {
            return item.algorithm_id;
        });
        return array.includes(item.id);
    };

    const handleChangeType = (event) => {
        setCampaignType(event.target.value);
    };
    const handleChange = (event) => {
        if (event.target.checked) {
            const value = {
                [event.target.name]: event.target.checked,
            };
            let key = Object.keys(value);
            const asd = key[0];
            const filterAlgo = data.filter((item) => item.id === parseInt(asd));
            const item = filterAlgo[0];
            const obj = {
                algorithm_id: item.id,
                algorithm_type_id: item.type.id,
                ts_account_id: tsAccount,
                campaign_type: campaignType,
            };
            createDefaultAlgorithms(obj)(algorithmsDispatch);
        } else {
            const data = [...defaultAlgorithms.data];
            const a = data.filter(
                (item) => item.algorithm_id === parseInt(event.target.name)
            );
            deleteDefaultAlgorithms(a[0].id)(algorithmsDispatch);
        }
    };
    return (
        <>
            <Grid container spacing={2} sx={{mb: 5}}>
                <Grid item xs={4} sx={{display: "flex", mb: 3}}>
                    <FormControl fullWidth>
                        <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                        <Select
                            required
                            labelId="ts-account-label"
                            id="ts-account-label-select"
                            value={trafficSource}
                            label="Traffic Source"
                            onChange={handleChangeTs}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {(trafficSourceList.data || []).map((item, i) => {
                                return (
                                    <MenuItem key={i} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                    <TrafficSourceAccountDropdown
                        tsAccount={tsAccount}
                        data={tsAccountsList.data}
                        width={300}
                        handleChangeTsAccount={handleChangeTsAccount}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="campaign-type-select-label">
                            Campaign Type
                        </InputLabel>
                        <Select
                            required
                            labelId="campaign-type-select-label"
                            id="campaign-type-select"
                            value={campaignType}
                            label="Campaign Type"
                            onChange={handleChangeType}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="SEED">SEED</MenuItem>
                            <MenuItem value="WL">WL</MenuItem>
                            <MenuItem value="SL">SL</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} justifyContent="center" sx={{display: "flex"}}>
                    {openAlertEmail && (
                        <TransitionAlerts
                            title={message}
                            handleAlertDialog={handleAlertDialog}
                        />
                    )}
                </Grid>
            </Grid>

            {algorithmsTypes &&
            algorithmsTypes.data.map((type) => (
                <Accordion key={type.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div>{type.algorithm_type}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {data
                            .filter(
                                (item) => item.type.algorithm_type === type.algorithm_type
                            )
                            .map((item) => (
                                <div key={item.id}>
                                    <Card variant="outlined" sx={{mb: 5}}>
                                        <CardContent>
                                            <Box
                                                variant="h5"
                                                component="div"
                                                color="text.secondary"
                                            >
                                                Name: {item.name}
                                            </Box>
                                            <Checkbox
                                                name={item.id.toString()}
                                                checked={checkTrueOrFalse(item)}
                                                onChange={handleChange}
                                                inputProps={{"aria-label": "controlled"}}
                                            />
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                    </AccordionDetails>
                </Accordion>
            ))}
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={algorithmName}
                handleDelete={handleDelete}
            />
        </>
    );
}
