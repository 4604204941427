import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    GET_DOMAIN_ERROR,
    GET_DOMAIN_LOADING,
    GET_DOMAIN_SUCCESS,
    FETCH_DOMAIN_ERROR,
    FETCH_DOMAIN_LOADING,
    FETCH_DOMAIN_SUCCESS,
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getDomains = () => (dispatch) => {
    dispatch({
        type: GET_DOMAIN_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/domain/cached-domains`)
        .then((res) => {
            dispatch({
                type: GET_DOMAIN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_DOMAIN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
export const fetchDomains = (callback) => (dispatch) => {
    dispatch({
        type: FETCH_DOMAIN_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/domain/fetch-domains`)
        .then((res) => {
            dispatch({
                type: FETCH_DOMAIN_SUCCESS,
                payload: res.data,
            });
            callback();
        })
        .catch((err) => {
            dispatch({
                type: FETCH_DOMAIN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

