import React, {createContext, useReducer} from "react";
import auth from "./reducers/auth";
import reports from "./reducers/reports";
import bidding from "./reducers/bidding";

import campaign from "./reducers/campaign";
import users from "./reducers/users";
import slackUsers from "./reducers/slackUsers";
import tasks from "./reducers/tasks";
import domains from "./reducers/domains";
import landers from "./reducers/landers";
import offers from "./reducers/offers";
import tsAccounts from "./reducers/trafficSoruceAccounts";
import voluum from "./reducers/voluum";
import algorithms from "./reducers/algorithms";
import campaignTypes from "./reducers/campaignTypes";
import updateCampaignTypes from "./reducers/updateCampaignTypes";
import blackListZone from "./reducers/blackListZone";
import events from "./reducers/events";
import domainTasks from "./reducers/domainsTasks"

import authInitialState from "./intialstates/initialAuthState";
import initialCampaignState from "./intialstates/initialCampaignState";
import {initReportsState} from "./intialstates/initialReportsState";
import {initialBiddingState} from "./intialstates/initialBiddingState";

import {initialUsersState} from "./intialstates/initialUsersState";
import {initialSlackUsersState} from "./intialstates/initialSlackUsersState";
import {initialTasksState} from "./intialstates/initialTasksState";
import {initialTsAccountsState} from "./intialstates/initialTsAccountsState";
import {initialVoluumState} from "./intialstates/initialVoluumState";
import {initialAlgorithmsState} from "./intialstates/initialAlgorithmsState";
import {initialBlackListZoneState} from "./intialstates/initialBlackListZone";
import {initialEventState} from "./intialstates/initialEventsState";
import {initialDomainsState} from "./intialstates/initialDomainState"
import {initialDomainTasksState} from "./intialstates/initialDomainTasksState";
import {initialLandersState} from "./intialstates/initialLandersState"
import {initialOffersState} from "./intialstates/initialOffersState"

export const GlobalContext = createContext({});

export const GlobalProvider = ({children}) => {
    const [authState, authDispatch] = useReducer(auth, authInitialState);
    const [campaignState, campaignDispatch] = useReducer(
        campaign,
        initialCampaignState
    );

    const [campaignTypesState, campaignTypesDispatch] = useReducer(
        campaignTypes,
        initialCampaignState.campaignTypes
    );

    const [updateCampaignTypesState, updateCampaignTypesDispatch] = useReducer(
        updateCampaignTypes,
        initialCampaignState.updateCampaignTypes
    )

    const [eventState, eventDispatch] = useReducer(events, initialEventState);

    const [algorithmsState, algorithmsDispatch] = useReducer(
        algorithms,
        initialAlgorithmsState
    );

    const [reportsState, reportsDispatch] = useReducer(reports, initReportsState);
    const [reportsBidding, biddingDispatch] = useReducer(
        bidding,
        initialBiddingState
    );
    const [usersState, usersDispatch] = useReducer(users, initialUsersState);
    const [slackUsersState, slackUsersDispatch] = useReducer(slackUsers, initialSlackUsersState);

    const [tasksState, tasksDispatch] = useReducer(tasks, initialTasksState);

    const [domainsState, domainsDispatch] = useReducer(domains, initialDomainsState);
    const [landersState, landersDispatch] = useReducer(landers, initialLandersState)
    const [offersState, offersDispatch] = useReducer(offers, initialOffersState)
    const [domainTasksState, domainTasksDispatch] = useReducer(domainTasks, initialDomainTasksState);

    const [tsAccountsState, tsAccountsDispatch] = useReducer(
        tsAccounts,
        initialTsAccountsState
    );

    const [voluumState, voluumDispatch] = useReducer(voluum, initialVoluumState);

    const [blackListZoneState, blackListZoneDispatch] = useReducer(
        blackListZone,
        initialBlackListZoneState
    );

    return (
        <GlobalContext.Provider
            value={{
                authState,
                authDispatch,
                campaignState,
                campaignTypesState,
                campaignTypesDispatch,
                updateCampaignTypesState,
                updateCampaignTypesDispatch,
                reportsBidding,
                biddingDispatch,
                campaignDispatch,
                reportsState,
                reportsDispatch,
                usersState,
                usersDispatch,
                slackUsersState,
                slackUsersDispatch,
                tasksState,
                tasksDispatch,
                landersState,
                landersDispatch,
                offersState,
                offersDispatch,
                domainsState,
                domainsDispatch,
                domainTasksState,
                domainTasksDispatch,
                tsAccountsState,
                tsAccountsDispatch,
                voluumState,
                voluumDispatch,
                algorithmsState,
                algorithmsDispatch,
                blackListZoneState,
                blackListZoneDispatch,
                eventState,
                eventDispatch,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
