import React, {useContext, useEffect, useState} from "react";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import TreeItem from "@mui/lab/TreeItem";
import {CampaignDialog} from "../components/campaignDialog";
import {getCampaignTree, resetMessageCampaignTree,} from "../context/actions/campaign/campaign";
import {GlobalContext} from "../context/Provider";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Grid from "@mui/material/Grid";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import useDebounce from "../helpers/debounce";
import TransitionAlerts from "../components/alert";
import LoadingSpinner from "../components/LoadingSpinner";

function CampaignTree() {
    const {
        campaignDispatch,
        campaignState,
        tsAccountsDispatch,
        tsAccountsState,
    } = useContext(GlobalContext);

    const {
        treeCampaign: {data, loading, error, message},
    } = campaignState;

    const {tsAccountsList} = tsAccountsState;

    const [open, setOpen] = useState(false);

    const [campaign, setCampaign] = useState(null);
    const [tsAccount, setTsAccount] = useState("");
    const [parentRemoteCampaignId, setParentRemoteCampaignId] = useState("");
    const [openAlert, setOpenAlert] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setCampaign(null);
    };
    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);
    useEffect(() => {
        setOpenAlert(error);
    }, [error]);
    const debouncedValueExo = useDebounce(parentRemoteCampaignId, 1000);

    useEffect(() => {
        getCampaignTree(tsAccount, debouncedValueExo)(campaignDispatch);
    }, [campaignDispatch, tsAccount, debouncedValueExo]);

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };
    const handleParentRemoteCampaignId = (event) => {
        setParentRemoteCampaignId(event.target.value);
    };
    const handleAlertDialog = () => {
        setOpenAlert((prevCheck) => !prevCheck);
        resetMessageCampaignTree()(campaignDispatch);
    };
    const renderLabel = (item) => (
        <span
            onClick={(event) => {
                setCampaign(item);
                setOpen(true);
                event.stopPropagation();
                event.preventDefault();
            }}
        >
      {item.remote_id}
    </span>
    );
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={renderLabel(nodes.data)}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );
    const renderData = (datalist) => {
        if (datalist) {
            return Object.keys(datalist).map((item) => {
                return renderTree(datalist[item]);
            });
        }
    };

    return (
        <>
            <Grid container spacing={2} sx={{mb: 5}}>
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <TextField
                        onChange={handleParentRemoteCampaignId}
                        name="campaign"
                        label="Parent Campaign Id"
                        type="text"
                        autoComplete="off"
                        value={parentRemoteCampaignId}
                    />
                </Grid>
                <Grid item xs={4} justifyContent="center" sx={{display: "flex"}}>
                    <TrafficSourceAccountDropdown
                        tsAccount={tsAccount}
                        data={tsAccountsList.data}
                        width={300}
                        handleChangeTsAccount={handleChangeTsAccount}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="center" sx={{display: "flex"}}>
                {openAlert && (
                    <TransitionAlerts
                        title={message}
                        handleAlertDialog={handleAlertDialog}
                    />
                )}
            </Grid>

            {data && !loading ? (
                <TreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpanded={["root"]}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    sx={{flexGrow: 1, maxWidth: 800, overflowY: "auto"}}
                >
                    {renderData(data)}
                </TreeView>
            ) : (
                <LoadingSpinner/>
            )}

            <CampaignDialog data={campaign} open={open} onClose={handleClose}/>
        </>
    );
}

export {CampaignTree};
