import {axiosInstance} from "../../../helpers/axiosInstance";
import {
    GET_LANDER_ERROR,
    GET_LANDER_LOADING,
    GET_LANDER_SUCCESS,
    TOGGLE_SHOULD_IGNORE_LANDER_ERROR,
    TOGGLE_SHOULD_IGNORE_LANDER_LOADING,
    TOGGLE_SHOULD_IGNORE_LANDER_SUCCESS
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getLandersDomains = () => (dispatch) => {
    dispatch({
        type: GET_LANDER_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/domain/cached-landers`)
        .then((res) => {
            dispatch({
                type: GET_LANDER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_LANDER_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const toggleShouldIgnoreLander = (landerDomain) => (dispatch) => {
    dispatch({
        type: TOGGLE_SHOULD_IGNORE_LANDER_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/domain/toggle-should-ignore?lander_domain=${landerDomain}`, {})
        .then((res) => {
            dispatch({
                type: TOGGLE_SHOULD_IGNORE_LANDER_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: TOGGLE_SHOULD_IGNORE_LANDER_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
