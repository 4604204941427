import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import MenuItem from "@mui/material/MenuItem";
import ButtonGroup from "@mui/material/ButtonGroup";


export const ACTION_TYPES = {
    INCREASE_BID: "INCREASE_BID",
    DECREASE_BID: "DECREASE_BID",
    SET_BID: "SET_BID",
}

export const BID_PRICE_DETERMINATORS = {
    EPC: "EPC",
    EPM: "EPM",
}

const activeTabStyle = {
    backgroundColor: "#87CEFA",
};


function createActionDefinition(actionType, actionValue, bidPriceDeterminant, days) {
    const actions = {
        type: actionType,
        value: actionValue
    };

    if (actionType === ACTION_TYPES.SET_BID) {
        actions.epc_or_epm = bidPriceDeterminant;
        actions.period_in_days = Number(days);
    }
    return actions;
}

export default function AddAction(props) {
    const {
        actionDefinition,
        onActionDefinitionChange,
    } = props;

    const [actionType, setActionType] = React.useState(actionDefinition?.type || ACTION_TYPES.SET_BID);
    const [actionValue, setActionValue] = React.useState(actionDefinition?.value || 0);
    const [bidPriceDeterminant, setBidPriceDeterminant] = React.useState(actionDefinition?.epc_or_epm || BID_PRICE_DETERMINATORS.EPC);
    const [days, setDays] = React.useState(actionDefinition?.period_in_days || 0);

    const [labelName, setLabelName] = React.useState("SET BID TO");

    useEffect(() => {
        onActionDefinitionChange(createActionDefinition(actionType, actionValue, bidPriceDeterminant, days))
    }, [actionType, actionValue, bidPriceDeterminant, days, onActionDefinitionChange]);
    const actionProperties = () => {
        return (
            <Box style={{display: "flex", flexDirection: "column"}}>
                <Grid style={{display: "flex", alignItems: "end"}}>
                    <Grid style={{display: "flex", alignItems: "baseline"}}>
                        <TextField
                            sx={{mt: 5, maxWidth: 150, mr: 1}}
                            type="text"
                            fullWidth
                            onChange={(e) => setActionValue(Number(e.target.value))}
                            id={`id-${actionType}`}
                            label={`${labelName}`}
                            name="changeBid"
                            value={actionValue}
                        />
                        <InputLabel>% </InputLabel>
                    </Grid>
                    {actionType === ACTION_TYPES.SET_BID ? (
                        <Grid style={{display: "flex", alignItems: "center"}}>
                            <InputLabel sx={{mt: 5, mr: 1}} id="set-bidding-label">
                                &nbsp;of
                            </InputLabel>
                            <Grid sx={{mt: 5, mr: 1}}>
                                <FormControl>
                                    <InputLabel id="demo-multiple-name-label">Type</InputLabel>
                                    <Select
                                        style={{width: "100px"}}
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={bidPriceDeterminant}
                                        label="Age"
                                        onChange={(e) => setBidPriceDeterminant(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={BID_PRICE_DETERMINATORS.EPC}>EPC</MenuItem>
                                        <MenuItem value={BID_PRICE_DETERMINATORS.EPM}>EPM</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <InputLabel sx={{mt: 5, mr: 1}} id="set-bidding-label">
                                of last
                            </InputLabel>
                            <Grid>
                                <TextField
                                    sx={{mt: 5, maxWidth: 150, mr: 1}}
                                    type="number"
                                    fullWidth
                                    onChange={(e) => setDays(Math.max(0, Number(e.target.value)))}
                                    id="days"
                                    label="Days"
                                    name="Days"
                                    value={days}
                                />
                            </Grid>
                            <InputLabel sx={{mt: 5, mr: 1}} id="set-bidding-label">
                                days
                            </InputLabel>
                        </Grid>
                    ) : null}
                </Grid>
                <Box>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => setActionValue(Math.min(actionValue + 10, 100))}
                    >
                        +
                    </Button>
                    <Button
                        variant="text"
                        style={{fontSize: "25px"}}
                        onClick={() => setActionValue(Math.max(actionValue - 10, 0))}
                    >
                        -
                    </Button>
                </Box>
            </Box>
        );
    };


    return (
        <Container>
            <div style={{marginTop: "10px"}}>Auto Bidding:</div>
            <ButtonGroup variant="outlined" style={{marginLeft: "5px"}}>
                <Button
                    style={actionType === ACTION_TYPES.INCREASE_BID ? activeTabStyle : null}
                    onClick={() => {
                        setActionType(ACTION_TYPES.INCREASE_BID);
                        setLabelName("INCREASE BID BY");
                    }}
                >
                    Increase
                </Button>
                <Button
                    style={actionType === ACTION_TYPES.DECREASE_BID ? activeTabStyle : null}
                    onClick={() => {
                        setActionType(ACTION_TYPES.DECREASE_BID);
                        setLabelName("DECREASE BID BY");
                    }}
                >
                    Decrease
                </Button>
                <Button
                    style={actionType === ACTION_TYPES.SET_BID ? activeTabStyle : null}
                    onClick={() => {
                        setActionType(ACTION_TYPES.SET_BID);
                        setLabelName("SET BID TO");
                    }}
                >
                    Set
                </Button>
            </ButtonGroup>
            {actionProperties()}
        </Container>
    );
}
