import React, { useContext, useEffect, useState } from "react";
import {
  deleteAlgorithms,
  getAlgorithms,
  resetMessageAlgorithmDelete,
} from "../context/actions/algorithms/algorithms";
import { GlobalContext } from "../context/Provider";
import { AlertDialog } from "../components/dialog";
import Grid from "@mui/material/Grid";
import { getTrafficSource } from "../context/actions/traffic/trafficSource";
import TransitionAlerts from "../components/alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AlgorithmsAccordion } from "./components/algorithmsAccordion";

export function ListAlgorithms() {
  const {
    algorithmsState,
    algorithmsDispatch,
    tsAccountsDispatch,
    tsAccountsState,
  } = useContext(GlobalContext);

  const {
    algorithmsList: { data, message, error },
  } = algorithmsState;
  const [open, setOpen] = useState(false);
  const [algorithmId, setAlgorithmId] = useState("");
  const [algorithmName, setAlgorithmName] = useState("");
  const { trafficSourceList } = tsAccountsState;
  const [trafficSource, setTrafficSource] = useState("");

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    getAlgorithms(trafficSource)(algorithmsDispatch);
  }, [algorithmsDispatch, trafficSource]);

  useEffect(() => {
    getTrafficSource()(tsAccountsDispatch);
  }, [tsAccountsDispatch]);

  useEffect(() => {
    setShowErrorMsg(error);
    if (showErrorMsg) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [error, showErrorMsg]);

  const handleDeleteAlgorithm = (id, name) => {
    setOpen(true);
    setAlgorithmId(id);
    setAlgorithmName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setAlgorithmId("");
    setAlgorithmName("");
  };

  const handleChangeTs = (event) => {
    setTrafficSource(event.target.value);
  };

  const handleDelete = () => {
    setOpen(false);
    deleteAlgorithms(algorithmId)(algorithmsDispatch);
    setAlgorithmId("");
  };

  const handleAlertDialog = () => {
    setShowErrorMsg((prevCheck) => !prevCheck);
    resetMessageAlgorithmDelete()(algorithmsDispatch);
  };

  return (
    <>
      <Grid item xs={4} sx={{ display: "flex", mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="ts-account-label">Traffic Source</InputLabel>
          <Select
            required
            labelId="ts-account-label"
            id="ts-account-label-select"
            value={trafficSource}
            label="Traffic Source"
            onChange={handleChangeTs}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {(trafficSourceList.data || []).map((item, i) => {
              return (
                <MenuItem key={i} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} justifyContent="center" sx={{ display: "flex" }}>
        {showErrorMsg && (
          <TransitionAlerts
            title={message}
            handleAlertDialog={handleAlertDialog}
          />
        )}
      </Grid>
      <AlgorithmsAccordion
        trafficSource={trafficSource}
        handleDeleteAlgorithm={handleDeleteAlgorithm}
      />

      <AlertDialog
        open={open}
        handleClose={handleClose}
        name={algorithmName}
        handleDelete={handleDelete}
      />
    </>
  );
}
