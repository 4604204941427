import {
    UPDATE_TYPE_TO_CAMPAIGNS_ERROR,
    UPDATE_TYPE_TO_CAMPAIGNS_LOADING,
    UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS
} from "../../constants/actionTypes";

const updateCampaignTypes = (state, {payload, type}) => {
    switch (type) {
        case UPDATE_TYPE_TO_CAMPAIGNS_LOADING: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }

        case UPDATE_TYPE_TO_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: payload,
            };
        }
        case UPDATE_TYPE_TO_CAMPAIGNS_ERROR: {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        }

        default:
            return state;
    }
};

export default updateCampaignTypes;
