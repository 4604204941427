import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    ADD_MORE_VARIATIONS_CPC,
    BIDDING_OPTIMIZATION,
    BLACKLIST_SITE_CPC,
    BLACKLIST_VARIATION_CPC,
    BLACKLIST_ZONE_CPC, DECREASE_DAILY_BUDGET_CPC,
    FACTOR_BROWSERS_BLACKLISTING,
    FACTOR_DEVICE_TYPES_BLACKLISTING,
    FACTOR_DEVICES_BLACKLISTING,
    FACTOR_OS_BLACKLISTING,
    INCREASE_DAILY_BUDGET_CPC,
    PAUSE_CAMPAIGN_LAST_30_DAYS_CPC,
    PAUSE_CAMPAIGN_LAST_7_DAYS_CPC,
    SHORTLIST_SITE_CPC,
    SHORTLIST_ZONE_CPC,
    WHITELIST_SITE_CPC,
    WHITELIST_ZONE_CPC,
} from "../constants/algosConst";

export default function AlgoCPC({
                                    campaignType,
                                    dropdownsCPC,
                                    handleChangeAlgorithmCPC,
                                    filtered,
                                }) {
    return (
        <>
            {(campaignType === "SEED" || campaignType === "SL") && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_ZONE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_ZONE_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.BLACKLIST_ZONE_CPC}
                                onChange={handleChangeAlgorithmCPC}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_ZONE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_SITE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_SITE_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.BLACKLIST_SITE_CPC}
                                onChange={handleChangeAlgorithmCPC}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_SITE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{WHITELIST_ZONE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={WHITELIST_ZONE_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.WHITELIST_ZONE_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === WHITELIST_ZONE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{WHITELIST_SITE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={WHITELIST_SITE_CPC}
                                labelId="voluum-id-label"
                                id="voluum-id-label-select"
                                value={dropdownsCPC.WHITELIST_SITE_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === WHITELIST_SITE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}

            {campaignType !== "WL" && campaignType !== "SL" && campaignType !== "" && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{SHORTLIST_SITE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={SHORTLIST_SITE_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.SHORTLIST_SITE_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === SHORTLIST_SITE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{SHORTLIST_ZONE_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={SHORTLIST_ZONE_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.SHORTLIST_ZONE_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === SHORTLIST_ZONE_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
            {campaignType !== "" && (
                <>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BLACKLIST_VARIATION_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BLACKLIST_VARIATION_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.BLACKLIST_VARIATION_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BLACKLIST_VARIATION_CPC) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{PAUSE_CAMPAIGN_LAST_7_DAYS_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={PAUSE_CAMPAIGN_LAST_7_DAYS_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.PAUSE_CAMPAIGN_LAST_7_DAYS_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === PAUSE_CAMPAIGN_LAST_7_DAYS_CPC
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{PAUSE_CAMPAIGN_LAST_30_DAYS_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={PAUSE_CAMPAIGN_LAST_30_DAYS_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.PAUSE_CAMPAIGN_LAST_30_DAYS_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === PAUSE_CAMPAIGN_LAST_30_DAYS_CPC
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{ADD_MORE_VARIATIONS_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={ADD_MORE_VARIATIONS_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.ADD_MORE_VARIATIONS_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === ADD_MORE_VARIATIONS_CPC
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{INCREASE_DAILY_BUDGET_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={INCREASE_DAILY_BUDGET_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.INCREASE_DAILY_BUDGET_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === INCREASE_DAILY_BUDGET_CPC
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{DECREASE_DAILY_BUDGET_CPC}</div>
                        <FormControl fullWidth>
                            <Select
                                name={DECREASE_DAILY_BUDGET_CPC}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.DECREASE_DAILY_BUDGET_CPC}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (
                                        item.type.algorithm_type === DECREASE_DAILY_BUDGET_CPC
                                    ) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{BIDDING_OPTIMIZATION}</div>
                        <FormControl fullWidth>
                            <Select
                                name={BIDDING_OPTIMIZATION}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.BIDDING_OPTIMIZATION}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === BIDDING_OPTIMIZATION) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_OS_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_OS_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.FACTOR_OS_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_OS_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_BROWSERS_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_BROWSERS_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.FACTOR_BROWSERS_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_BROWSERS_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_DEVICES_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_DEVICES_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.FACTOR_DEVICES_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_DEVICES_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{mt: 2}} xs={12}>
                        <div>{FACTOR_DEVICE_TYPES_BLACKLISTING}</div>
                        <FormControl fullWidth>
                            <Select
                                name={FACTOR_DEVICE_TYPES_BLACKLISTING}
                                id="voluum-id-label-select"
                                value={dropdownsCPC.FACTOR_DEVICE_TYPES_BLACKLISTING}
                                displayEmpty
                                onChange={handleChangeAlgorithmCPC}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(filtered || []).map((item, i) => {
                                    if (item.type.algorithm_type === FACTOR_DEVICE_TYPES_BLACKLISTING) {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    }
                                    return "";
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
        </>
    );
}
