import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {TrafficSourceAccountDropdown} from "../../components/trafficSourceAccountDropdown";

export function SelectTSAccount(props) {
    const {
        tsAccountsList,
        tsAccount,
        handleChangeTsAccount,
        trafficSource,
        handleChangeTs,
        trafficSourceList,
    } = props;
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" sx={{mt: 3, width: 500}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    id="ts-account-label-select"
                                    value={trafficSource}
                                    label="Traffic Source"
                                    onChange={handleChangeTs}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {(trafficSourceList || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TrafficSourceAccountDropdown
                                tsAccount={tsAccount}
                                data={tsAccountsList}
                                width={500}
                                handleChangeTsAccount={handleChangeTsAccount}
                            />
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
