import {
    CREATE_TASK_ERROR,
    CREATE_TASK_LOADING,
    CREATE_TASK_SUCCESS,
    DELETE_TASK_LOADING,
    DELETE_TASK_SUCCESS,
    GET_TASK_ERROR,
    GET_TASK_LOADING,
    GET_TASK_SUCCESS,
    UPDATE_TASK_ERROR,
    UPDATE_TASK_LOADING,
    UPDATE_TASK_SUCCESS,
} from "../../constants/actionTypes";

const tasks = (state, {payload, type}) => {
    switch (type) {
        case GET_TASK_LOADING: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: true,
                },
            };
        }

        case GET_TASK_SUCCESS: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_TASK_ERROR: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_TASK_LOADING: {
            return {
                ...state,
                addTask: {
                    ...state.addTask,
                    error: null,
                    loading: true,
                },
            };
        }

        case CREATE_TASK_ERROR: {
            return {
                ...state,
                addTask: {
                    ...state.addTask,
                    loading: false,
                    error: payload,
                },
            };
        }

        case CREATE_TASK_SUCCESS: {
            return {
                ...state,
                addTask: {
                    ...state.addTask,
                    loading: false,
                    data: payload,
                },

                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    data: [payload, ...state.tasksList.data],
                },
            };
        }
        case UPDATE_TASK_LOADING: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: true,
                },
            };
        }
        case UPDATE_TASK_SUCCESS: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    data: state.tasksList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }
        case UPDATE_TASK_ERROR: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    error: payload,
                    data: state.tasksList.data.map((element) => {
                        if (element.id === payload.id) {
                            return payload;
                        }
                        return element;
                    }),
                },
            };
        }

        case DELETE_TASK_LOADING: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    data: state.tasksList.data.map((item) => {
                        if (item.id === payload) {
                            return {...item, deleting: true};
                        }
                        return item;
                    }),
                },
            };
        }

        case DELETE_TASK_SUCCESS: {
            return {
                ...state,
                tasksList: {
                    ...state.tasksList,
                    loading: false,
                    data: state.tasksList.data.filter(
                        (item) => item.id !== payload
                    ),
                },
            };
        }

        default:
            return state;
    }
};

export default tasks;
