import {GET_SLACK_USERS_ERROR, GET_SLACK_USERS_LOADING, GET_SLACK_USERS_SUCCESS,} from "../../constants/actionTypes";

const slackUsers = (state, {payload, type}) => {
    switch (type) {
        case GET_SLACK_USERS_LOADING: {
            return {
                ...state,
                slackUsersList: {
                    ...state.slackUsersList,
                    loading: true,
                },
            };
        }

        case GET_SLACK_USERS_SUCCESS: {
            return {
                ...state,
                slackUsersList: {
                    ...state.slackUsersList,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_SLACK_USERS_ERROR: {
            return {
                ...state,
                slackUsersList: {
                    ...state.slackUsersList,
                    loading: false,
                    error: payload,
                },
            };
        }
        default:
            return state
    }
};

export default slackUsers;
