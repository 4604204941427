import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {AlertDialog} from "../components/dialog";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {CreateEditUser} from "./createEditUser";
import {createUser, deleteUser, getSlackUsers, getUsers, updateUser,} from "../context/actions/users/users";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import LoadingSpinner from "../components/LoadingSpinner";

function UserManagement() {
    const columns = [
        {field: "email", headerName: "Email", width: 300},
        {field: "role", headerName: "Role", minWidth: 150},
        {
            field: "ts_account_id",
            headerName: "Ts Account",
            width: 120,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {
            field: "slack_id",
            headerName: "Slack",
            width: 200,
            renderCell: (cellValues) => {
                let slackUser = slackUsersList.data.find((x) => x.id === cellValues.value);
                return <>{slackUser?.name}</>;
            },
        },
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <>
                        <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                        <DeleteIcon
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const {usersDispatch, usersState} = useContext(GlobalContext);

    const {tsAccountsDispatch, tsAccountsState} = useContext(GlobalContext);
    const {tsAccountsList} = tsAccountsState;

    const {slackUsersDispatch, slackUsersState} = useContext(GlobalContext);
    const {slackUsersList} = slackUsersState;
    const [recordCreated, setRecordCreated] = useState('create')

    const {
        usersList: {data, loading},
    } = usersState;

    useEffect(() => {
        getUsers()(usersDispatch);
    }, [usersDispatch]);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        getSlackUsers()(slackUsersDispatch);
    }, [slackUsersDispatch]);

    const redirectToEdit = (cellValues) => {
        setRecordCreated('update')
        setOpenCreateEditDialog(true);
        setSelectedUser(cellValues.row);
    };
    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleClick = (event, cellValues) => {
        setUser(cellValues.row.email);
        setUserId(cellValues.row.id);
        setOpen(true);
    };

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [user, setUser] = useState("");
    const [userId, setUserId] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [apiError, setApiError] = useState("");

    const handleClose = () => {
        setOpen(false);
        setUser("");
        setUserId("");
    };

    const handleDelete = () => {
        setApiError(false);
        selectedUser
            ? deleteUser(selectedUser?.id)(usersDispatch)
            : deleteUser(userId)(usersDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setSelectedUser(null);
    };

    const handleCloseCreateEditDialog = () => {
        setApiError(false);
        setOpenCreateEditDialog(false);
        setSelectedUser(null);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const addOrEdit = (props) => {
        setSelectedUser(props)
        setRecordCreated(props.id ? 'update' : 'create')

        const errorType = props.id ? 'UPDATE_USER_ERROR' : 'CREATE_USER_ERROR';
        const successType = props.id ? 'UPDATE_USER_SUCCESS' : 'CREATE_USER_SUCCESS';
      
        const taskActionFn = props.id ? updateUser : createUser;
            
        taskActionFn(props)((action) => {
          if (action.type === errorType) { 
            if(action.payload.errorType === 'VALIDATION_ERROR'){
                setApiError(action.payload);
                setOpenCreateEditDialog(true);
                setSelectedUser(props)
            }
            else{
                setApiError(action.payload);
                setOpenCreateEditDialog(true);
                setSelectedUser(props)
            }        
          } 
          else if (action.type === successType) {
            handleCloseCreateEditDialog();
            getUsers()(usersDispatch);
          }
        });
    };
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                User Management
            </Typography>

            <Button
                sx={{mt: 3, mb: 5}}
                variant="contained"
                color="success"
                onClick={handleOpenCreateDialog}
            >
                Create User
            </Button>
            {data && !loading ? (
                <div style={{width: "100%"}}>
                    <DataGrid rows={data} columns={columns} autoHeight={true}/>
                </div>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateEditUser
                    recordCreated = {recordCreated}
                    apiError={apiError}
                    recordForEdit={selectedUser}
                    tsAccounts={tsAccountsList.data}
                    slackUsers={slackUsersList.data}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                >
                    <AlertDialog
                        open={openDeleteDialog}
                        handleClose={alertInCreateDialog}
                        name={selectedUser?.email}
                        handleDelete={handleDelete}
                    />
                </CreateEditUser>
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={user}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {UserManagement};
