export const initialUsersState = {
    usersList: {
        loading: false,
        error: null,
        data: [],
    },
    addUser: {
        loading: false,
        error: null,
        data: null,
    },
};
