import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {green} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

function CreateEditVoluum(props) {
    const {
        addOrEdit,
        recordForEdit,
        handleCloseCreateEditDialog,
        children,
        handleCloseDeleteDialog,
        tsAccounts,
    } = props;

    const [voluum, setVoluum] = useState({
        name: "",
        voluum_campaign_id: "",
    });
    const [tsAccount, setTsAccount] = useState("");

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    useEffect(() => {
        if (recordForEdit) {
            const {id, ts_account_id, ...rest} = recordForEdit;
            setTsAccount(ts_account_id);

            setVoluum((prevState) => {
                return {...prevState, ...rest};
            });
        }
    }, [recordForEdit]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setVoluum((inputs) => ({...inputs, [name]: value}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data;
        if (recordForEdit) {
            data = {
                id: recordForEdit.id,
                voluum_campaign_id: voluum.voluum_campaign_id,
                name: voluum.name,
                created_by: recordForEdit.created_by,
                ts_account_id: tsAccount,
            };
        } else {
            data = {
                voluum_campaign_id: voluum.voluum_campaign_id,
                name: voluum.name,
                ts_account_id: tsAccount,
            };
        }

        addOrEdit(data);
    };
    return (
        <Container component="main" maxWidth="xs">
            {recordForEdit ? (
                <Typography component="h1" variant="h5">
                    Update Voluum
                </Typography>
            ) : (
                <Typography component="h1" variant="h5">
                    Create Voluum
                </Typography>
            )}
            <Box
                sx={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={handleChange}
                                id="name"
                                label="Name"
                                name="name"
                                type="text"
                                value={voluum.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                onChange={handleChange}
                                fullWidth
                                name="voluum_campaign_id"
                                label="Voluum Campaign Id"
                                type="text"
                                id="voluum_campaign_id"
                                value={voluum.voluum_campaign_id}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="ts-account-label">TS ACCOUNT</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    id="ts-account-label-select"
                                    value={tsAccount}
                                    label="TS ACCOUNT"
                                    onChange={handleChangeTsAccount}
                                >
                                    {(tsAccounts || []).map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {recordForEdit && (
                        <Button
                            sx={{mt: 3, mb: 2, mr: 5, color: green}}
                            onClick={handleCloseDeleteDialog}
                            color="success"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        sx={{mt: 3, mb: 2, mr: 5, color: green}}
                        onClick={handleCloseCreateEditDialog}
                        color="success"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{mt: 3, mb: 2, color: green}}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            {children}
        </Container>
    );
}

export {CreateEditVoluum};
