import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

export function CampaignDialog({open, onClose, data}) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{width: "100%"}}>
                            <Stack spacing={2}>
                                <Item>Name: {data?.name}</Item>
                                <Item>Exo Click Id: {data?.remote_id} </Item>
                                <Item>Status : {data?.status}</Item>
                                <Item>Cost Modal : {data?.cost_model}</Item>
                                <Item>EPA : {data?.epa}</Item>
                                <Item>Bid Price : {data?.bid_price}</Item>
                            </Stack>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Esc</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
