import {ERROR_RESET, LOGIN_RESET} from "../../../constants/actionTypes";

export const loginReset = () => (dispatch) => {
    dispatch({
        type: LOGIN_RESET,
        payload: null,
    });
};

export const errorReset = () => (dispatch) => {
    dispatch({
        type: ERROR_RESET,
        payload: null,
    });
};
