import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import {GlobalContext} from "../context/Provider";
import {assignAlgorithmsBidding, getAssignAlgorithms} from "../context/actions/campaign/campaign";
import {getAlgorithms} from "../context/actions/algorithms/algorithms";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export default function AutoBidding(props) {
    const {dataForEdit: campaignData, handleCloseAutoBiddingModal, handleCloseOriginalDialog} = props;

    const {
        tsAccountsState,
        campaignDispatch,
        campaignState,
        algorithmsState,
        algorithmsDispatch,
    } = useContext(GlobalContext);
    const {tsAccountsList} = tsAccountsState;
    const [algo, setAlgo] = useState({
        BIDDING_OPTIMIZATION: "",
    });
    const [algoItem, setAlgoItem] = useState({});
    const [errorAlgo, setErrorAlgo] = useState("");
    const [errorData, setErrorData] = useState(false);
    const [submit, setSubmit] = useState(false);

    let type_id;

    useEffect(() => {
        getAlgorithms(campaignData?.traffic_source_id)(algorithmsDispatch);
        getAssignAlgorithms(campaignData?.id)(campaignDispatch);
    }, [algorithmsDispatch, campaignData?.traffic_source_id, campaignData?.id]);
    const tsAccount = tsAccountsList.data.find(
        (x) => x.id === campaignData?.ts_account_id
    );
    const {
        remoteData: {remoteData},
        assignAlgoCampaign
    } = campaignState;
    const {algorithmsList} = algorithmsState;
    const filteredAlgorithmsList = [];
    algorithmsList.data.forEach((item) => {
        if (item.type.algorithm_type === "BIDDING_OPTIMIZATION") {
            type_id = item.type.id
            filteredAlgorithmsList.push(item);
        }
    });

    useEffect(() => {
        if (assignAlgoCampaign?.data?.length > 0) {
            for (let algo of assignAlgoCampaign.data) {
                if (algo.type.algorithm_type === "BIDDING_OPTIMIZATION") {
                    handleSetAlgo(algo);
                }
            }
        }

    }, [assignAlgoCampaign.data]);

    const handleSetAlgo = (item) => {
        setAlgoItem(item);
        setAlgo({
            BIDDING_OPTIMIZATION: item.id || ""
        })
    };

    const handleSubmitForm = () => {
        if (algoItem.id === "NONE") {
            assignAlgorithmsBidding(
                campaignData.id,
                {type_id: algoItem.type.id},
                algoItem.type.id
            )(campaignDispatch);
        } else {
            assignAlgorithmsBidding(
                campaignData.id,
                {
                    type_id: algoItem.type.id,
                    algorithm_id: algoItem.id,
                },
                algoItem.type.id
            )(campaignDispatch);
        }
        if (remoteData?.detail) {
            if (remoteData?.detail[0]?.msg) {
                setErrorAlgo(remoteData?.detail[0]?.msg);
            } else {
                setErrorAlgo(remoteData?.detail);
            }
            setErrorData(true);
        } else {
            setErrorData(false);
            setSubmit(true);
            setTimeout(() => {
                handleCloseAutoBiddingModal();
                handleCloseOriginalDialog();
                getAssignAlgorithms(campaignData?.id)(campaignDispatch);
            }, 1000);
        }
    };
    return (
        <div>
            <Box>
                <Button
                    sx={{mt: 2, mb: 2}}
                    onClick={handleCloseAutoBiddingModal}
                    color="warning"
                    startIcon={<ChevronLeftSharpIcon/>}
                >
                    Back
                </Button>
                <Alert severity="info">
                    Campaign: {campaignData.campaign_id}, Current Bid: {campaignData?.bid},
                    TS Account: {tsAccount?.name}
                </Alert>
                {remoteData?.detail && errorData ? (
                    <Alert severity="warning">{remoteData?.detail}</Alert>
                ) : null}

                {errorData === false && submit ? (
                    <Alert severity="success">"Successfully updated."</Alert>
                ) : null}

                <Grid sx={{mt: 2, mb: 2}}>
                    <InputLabel xs={{mt: 10}} id="demo-multiple-chip-label">
                        BIDDING_OPTIMIZATION
                    </InputLabel>
                    <FormControl fullWidth>
                        <Select
                            required
                            labelId="voluum-id-label"
                            id="voluum-id-label-select"
                            value={algo.BIDDING_OPTIMIZATION}
                            label="Voluum"
                        >
                            <MenuItem value="NONE" onClick={() => handleSetAlgo({id: "NONE", type: {id: type_id}})}>
                                <em>None</em>
                            </MenuItem>
                            {(filteredAlgorithmsList || []).map((item, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={item.id}
                                        onClick={() => handleSetAlgo(item)}
                                    >
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Button
                    sx={{mt: 2, mb: 2}}
                    onClick={handleCloseAutoBiddingModal}
                    color="warning"
                >
                    Cancel
                </Button>
                <Button
                    sx={{mt: 2, mb: 2, float: "right"}}
                    onClick={handleSubmitForm}
                    color="success"
                >
                    Submit
                </Button>
            </Box>
        </div>
    );
}
