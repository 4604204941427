import React, {useContext, useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import LoadingSpinner from "../components/LoadingSpinner";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {deleteDomainTask, getDomainTasks} from "../context/actions/domains/domainsTasks";
import {AlertDialog} from "../components/dialog"
import {Alert} from "@mui/material";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function ListDomainReplacementTasks() {

    let columns;
    columns = [
        {field: "replace_from", headerName: "Replace From", minWidth: 200},
        {field: "replace_to", headerName: "Replace To", minWidth: 200},
        {field: "submitted_at", headerName: "Submitted At", width: 200},
        {field: "status", headerName: "Status", minWidth: 150},
        {field: "last_update", headerName: "Last Updated", minWidth: 200},
        {field: "is_site_live", headerName: "Is Site Live", minWidth: 150},
        {
            field: "delete",
            headerName: "Delete",
            minWidth: 50,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        setUnableToDelete(false)
                        if (params.row.status !== "CREATED") {
                            setUnableToDelete(true)
                            return;
                        }
                        setrowToDelete(params.id)
                        setOpen(true)
                    }}
                    color="primary"
                    aria-label="delete"
                >
                    <DeleteIcon/>
                </IconButton>
            ),
        },
    ];

    const {domainTasksDispatch, domainTasksState} = useContext(GlobalContext);
    const [unableToDelete, setUnableToDelete] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [rowToDelete, setrowToDelete] = useState('')

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const {
        domainTasksList
    } = domainTasksState;

    useEffect(() => {
        getDomainTasks()(domainTasksDispatch);
        setUnableToDelete(false)
    }, [domainTasksDispatch]);

    const handleDeleteRow = (id) => {
        deleteDomainTask(id)(domainTasksDispatch);
        setrowToDelete('');
        setOpen(false);
    }

    useEffect(() => {
        setErrorMessage(unableToDelete ? "Only Tasks with Status 'CREATED' can be deleted" : '');
    }, [unableToDelete]);
    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                All Domain Replacement Tasks
            </Typography>
            {errorMessage ? (
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            ) : null}
            {domainTasksList.data && !domainTasksList.loading ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--new-task": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.success.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                        "& .super-app-theme--due-task": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid rows={domainTasksList.data} columns={columns} autoHeight/>
                </Box>
            ) : (
                <LoadingSpinner/>
            )}
            <AlertDialog
                open={open}
                handleClose={handleClose}
                handleDelete={() => handleDeleteRow(rowToDelete)}
                name={rowToDelete}
            />

        </>
    )
}

export {ListDomainReplacementTasks};
