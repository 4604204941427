import React, {useContext, useEffect, useState} from "react";

import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import {AlertDialog} from "../components/dialog";
import {CreateAndEditDialog} from "../components/createAndEditDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import {CreateEditTask} from "./createEditTask";
import {createTask, deleteTask, getTasks, updateTask} from "../context/actions/tasks/tasks";

import LoadingSpinner from "../components/LoadingSpinner";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function TaskManagement() {
    const user = JSON.parse(localStorage.getItem("user"));

    let columns;
    if (user?.role === "ADMIN" || user?.role === "SUPERADMIN") {
        columns = [
            {field: "task_message", headerName: "Task Message", minWidth: 300},
            {
                field: "user_email",
                headerName: "User",
                width: 200
            },
            {field: "remind_on", headerName: "Remind On (UTC)", minWidth: 200},
            {field: "campaign_remote_id", headerName: "Remote ID", width: 150},
            {field: "campaign_name", headerName: "Campaign Name", minWidth: 350},
            {field: "status", headerName: "Status", minWidth: 150},
            {field: "is_shown", headerName: "Seen", minWidth: 100},
            {
                field: "ts_account_name",
                headerName: "Ts Account",
                width: 120
            },
            {field: "created_on", headerName: "Created On (UTC)", minWidth: 200},
            {
                field: "created_by_email",
                headerName: "Created By",
                minWidth: 200
            },
            {
                field: "completed_on",
                headerName: "Completed On (UTC)",
                minWidth: 200
            },
            {
                field: "action",
                headerName: "",
                width: 100,
                renderCell: (cellValues) => {
                    return (
                        <>
                            <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                            <DeleteIcon
                                onClick={(event) => {
                                    handleClick(event, cellValues);
                                }}
                            />
                        </>
                    );
                },
            },
        ];
    } else {
        columns = [
            {field: "task_message", headerName: "Task Message", minWidth: 300},
            {field: "remind_on", headerName: "Remind On (UTC)", minWidth: 200},
            {field: "campaign_remote_id", headerName: "Remote ID", width: 150},
            {field: "campaign_name", headerName: "Campaign Name", minWidth: 350},
            {field: "status", headerName: "Status", minWidth: 150},
            {field: "is_shown", headerName: "Seen", minWidth: 100},
            {
                field: "ts_account_name",
                headerName: "Ts Account",
                width: 120
            },
            {field: "created_on", headerName: "Created On (UTC)", minWidth: 200},
            {
                field: "created_by_email",
                headerName: "Created By",
                minWidth: 200
            },
            {
                field: "completed_on",
                headerName: "Completed On (UTC)",
                minWidth: 200
            },
            {
                field: "action",
                headerName: "",
                width: 60,
                renderCell: (cellValues) => {
                    return (
                        <>
                            <EditIcon onClick={() => redirectToEdit(cellValues)}/>
                            <DeleteIcon
                                onClick={(event) => {
                                    handleClick(event, cellValues);
                                }}
                            />
                        </>
                    );
                },
            }
        ];
    }

    const {tasksDispatch, tasksState} = useContext(GlobalContext);

    const {
        tasksList
    } = tasksState;

    useEffect(() => {
        getTasks()(tasksDispatch);
    }, [tasksDispatch]);

    const redirectToEdit = (cellValues) => {
        setRecordCreated('update');
        setOpenCreateEditDialog(true);
        setSelectedTask(cellValues.row);
    };
    const handleOpenCreateDialog = () => {
        setOpenCreateEditDialog(true);
    };

    const handleClick = (event, cellValues) => {
        setTask(cellValues.row.email);
        setTaskId(cellValues.row.id);
        setOpen(true);
    };

    const [open, setOpen] = useState(false);
    const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [task, setTask] = useState("");
    const [taskId, setTaskId] = useState("");
    const [selectedTask, setSelectedTask] = useState(null);
    const [apiError, setApiError] = useState("");
    const [recordCreated, setRecordCreated] = useState('create')

    const handleClose = () => {
        setOpen(false);
        setTask("");
        setTaskId("");
    };

    const handleDelete = () => {
        setApiError(false);
        selectedTask
            ? deleteTask(selectedTask?.id)(tasksDispatch)
            : deleteTask(taskId)(tasksDispatch);
        setOpenCreateEditDialog(false);
        setOpenDeleteDialog(false);
        setOpen(false);
        setSelectedTask(null);
    };

    const handleCloseCreateEditDialog = () => {
        setRecordCreated(true);
        setApiError(false);
        setOpenCreateEditDialog(false);
        setSelectedTask(null);
    };

    const alertInCreateDialog = () => {
        setOpenDeleteDialog((prevCheck) => !prevCheck);
    };

    const addOrEdit = (props) => {
        setSelectedTask(null);
        setRecordCreated(props.id ? 'update' : 'create')

        const errorType = props.id ? 'UPDATE_TASK_ERROR' : 'CREATE_TASK_ERROR';
        const successType = props.id ? 'UPDATE_TASK_SUCCESS' : 'CREATE_TASK_SUCCESS';
        
        const taskActionFn = props.id ? updateTask : createTask;
        taskActionFn(props)((action) => {
          if (action.type === errorType) {
            if(action.payload.errorType === 'VALIDATION_ERROR'){
                setApiError(action.payload);
                setOpenCreateEditDialog(true);
                setSelectedTask(props)
            }
            else{
                setApiError(action.payload?.detail[0].msg);
                setOpenCreateEditDialog(true);
                setSelectedTask(props)
            }
          } 
          else if (action.type === successType) {
            handleCloseCreateEditDialog();
            getTasks()(tasksDispatch);
          }
        });
    };

    const handleCellClick = (params) => {
        if (params.field !== "action" && !params.row.is_shown) {
            const data = params.row;
            data.is_shown = true;
            addOrEdit(data)
        }
    }

    return (
        <>
            {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") ? (
                <Typography component="h1" variant="h5" sx={{mb: 5}}>
                    Tasks Management
                </Typography>
            ) : (
                <Typography component="h1" variant="h5" sx={{mb: 5}}>
                    Your Tasks
                </Typography>
            )}

            <Button
                sx={{mt: 3, mb: 5}}
                variant="contained"
                color="success"
                onClick={handleOpenCreateDialog}
            >
                Create Task
            </Button>
            {tasksList.data && !tasksList.loading ? (
                <Box sx={{
                    minHeight: 100,
                    width: "100%",
                    "& .super-app-theme--new-task": {
                        bgcolor: (theme) =>
                            getBackgroundColor(
                                theme.palette.success.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    "& .super-app-theme--due-task": {
                        bgcolor: (theme) =>
                            getBackgroundColor(
                                theme.palette.error.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                        },
                    }
                }}>
                    <DataGrid
                        rows={tasksList.data}
                        columns={columns}
                        autoHeight={true}
                        onCellClick={handleCellClick}
                        getRowClassName={(params) => {
                            if (user?.role === "MEDIABUYER" && params.row.is_shown === false) {
                                return `super-app-theme--new-task`;
                            }

                            const date_now_timestamp = new Date().getTime();
                            let remind_on_timestamp;
                            if (params.row.remind_on) {
                                remind_on_timestamp = Date.parse(params.row.remind_on + "Z");
                            }
                            if (params.row.status !== 'COMPLETED' && params.row.remind_on && remind_on_timestamp < date_now_timestamp) {
                                return `super-app-theme--due-task`;
                            }
                        }}/>
                </Box>
            ) : (
                <LoadingSpinner/>
            )}
            <CreateAndEditDialog
                open={openCreateEditDialog}
                handleClose={handleCloseCreateEditDialog}
            >
                <CreateEditTask
                    recordCreated = {recordCreated}
                    apiError={apiError}
                    recordForEdit={selectedTask}
                    addOrEdit={addOrEdit}
                    handleCloseCreateEditDialog={handleCloseCreateEditDialog}
                    handleCloseDeleteDialog={alertInCreateDialog}
                >
                    <AlertDialog
                        open={openDeleteDialog}
                        handleClose={alertInCreateDialog}
                        name={selectedTask?.task_message}
                        handleDelete={handleDelete}
                    />
                </CreateEditTask>
            </CreateAndEditDialog>
            <AlertDialog
                open={open}
                handleClose={handleClose}
                name={task}
                handleDelete={handleDelete}
            />
        </>
    );
}

export {TaskManagement};
