import {darken, lighten} from "@mui/material/styles";

export const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

export const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export const dateToStr = (d) => d.toISOString().substring(0, 10);

export const composeTitle = (groupBy, {from, to}) => `${groupBy} Report from ${dateToStr(from)} to ${dateToStr(to)}`;

export const composeTitleForCampaign = (campaignId, groupBy, {from, to}) => `${groupBy} report for campaign ${campaignId} from ${dateToStr(from)} to ${dateToStr(to)}`;

export const COMPLETE_REPORT_GROUP_BY = {
    CAMPAIGN: "Campaign",
    SITE: "Site",
    ZONE: "Zone",
    COUNTRY: "Country"
}
