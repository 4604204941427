import React from "react";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export function ModalDelete(props) {
    const {
        openModal,
        handleCloseModal,
        handleRemoveRecords
    } = props;
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Do you want to delete this record?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <Button
                            onClick={() => {
                                handleRemoveRecords();
                                handleCloseModal();
                                // deleteRecord();
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => {
                                handleCloseModal();
                            }}
                        >
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
