import {
    GET_CAMPAIGN_TYPES_ERROR,
    GET_CAMPAIGN_TYPES_LOADING,
    GET_CAMPAIGN_TYPES_SUCCESS
} from "../../constants/actionTypes";

const campaignTypes = (state, {payload, type}) => {
    switch (type) {
        case GET_CAMPAIGN_TYPES_LOADING: {
            return {
                ...state,
                campaignTypes: {
                    ...state.campaignTypes,
                    loading: true,
                    error: false,
                },
            };
        }

        case GET_CAMPAIGN_TYPES_SUCCESS: {
            return {
                ...state,
                campaignTypes: {
                    ...state.campaignTypes,
                    loading: false,
                    data: payload,
                },
            };
        }
        case GET_CAMPAIGN_TYPES_ERROR: {
            return {
                ...state,
                campaignTypes: {
                    ...state.campaignTypes,
                    loading: false,
                    error: payload,
                },
            };
        }

        default:
            return state;
    }
};

export default campaignTypes;
