import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export function SlackDataDropdown({
                                      slackName,
                                      handleChangeSlackName,
                                      width,
                                      data,
                                  }) {
    return (
        <>
            <FormControl sx={{width: width}}>
                <InputLabel id="slack-user-name-label">SLACK USER</InputLabel>
                <Select
                    labelId="slack-user-name-label"
                    id="slack-user-name-label-select"
                    value={slackName}
                    label="Real Name on Slack"
                    onChange={handleChangeSlackName}
                >
                    <MenuItem value={""}>
                        {" "}
                        <em>None</em>
                    </MenuItem>
                    {(data || []).map((item, i) => {
                        return (
                            <MenuItem key={i} value={item.id}>
                                {item.real_name} ({item.name})
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
}
