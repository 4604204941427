import {COMPLETE_REPORT_GROUP_BY} from "./common";
import {Tooltip} from "@mui/material";
import ReportTable from "./commonReportsTable";

export default function SiteReportsTable(props) {
    const {
        tsAccountId,
        campaignId,
        showAssignedOnly,
        dateRange,
    } = props;

    let columns = [
        {
            field: "site_name",
            headerName: "Site name",
            width: 300,
            color: "#484848",
            renderCell: (params) => (
                <>
                    <Tooltip title={params.row.site_name}>
                        <span
                            sx={{
                                pt: 1,
                                color: "#484848",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: 940,
                                whiteSpace: "nowrap",
                            }}
                        >
                          {params.row.site_name}
                        </span>
                    </Tooltip>
                </>
            ),
        },
        {
            field: "impressions",
            headerName: "Impressions",
            width: 110,
            color: "#484848",
            type: "number",
        },
        {
            field: "clicks",
            color: "#484848",
            headerName: "Clicks",
            width: 80,
            type: "number",
        },
        {
            field: "cpm",
            headerName: "eCPM",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "cost",
            headerName: "Cost",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "conversions",
            headerName: "Conversions",
            width: 100,
            color: "#484848",
            type: "number",
        },
        {
            field: "revenue",
            headerName: "Revenue",
            width: 100,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "profit",
            headerName: "Profit",
            width: 80,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return "$" + params?.value;
            },
        },
        {
            field: "roi",
            headerName: "ROI",
            width: 100,
            color: "#484848",
            type: "number",
            valueFormatter: (params) => {
                return params?.value + "%";
            },
        },
        {
            field: "ap",
            headerName: "AP",
            width: 80,
            color: "#484848",
            type: "number",
        },
        {
            field: "traffic_estimate",
            headerName: "Traffic Est",
            color: "#484848",
            width: 110,
            type: "number",
            valueFormatter: (params) => {
                if (params?.value === undefined) {
                    return "";
                }
                return params?.value + "%";
            },
        },
        {
            field: "G1",
            headerName: "G1",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G1 ? params.row.G1 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G1}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G2",
            headerName: "G2",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G2 ? params.row.G2 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G2}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G3",
            headerName: "G3",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G3 ? params.row.G3 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G3}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G4",
            color: "#484848",
            headerName: "G4",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G4 ? params.row.G4 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G4}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G5",
            headerName: "G5",
            color: "#484848",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G5 ? params.row.G5 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G5}
                  </span>
                </Tooltip>
            ),
        },
        {
            field: "G6",
            color: "#484848",
            headerName: "G6",
            width: 30,
            renderCell: (params) => (
                <Tooltip title={params.row.G6 ? params.row.G6 : ""}>
                  <span
                      sx={{
                          pt: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 940,
                          whiteSpace: "nowrap",
                      }}
                  >
                    {params.row.G6}
                  </span>
                </Tooltip>
            ),
        }
    ];

    return (
        <ReportTable
            columns={columns}
            dateRange={dateRange}
            showAssignedOnly={showAssignedOnly}
            tsAccountId={tsAccountId}
            campaignId={campaignId}
            groupBy={COMPLETE_REPORT_GROUP_BY.SITE}
        />
    )
}
