import {axiosInstance} from "../../../helpers/axiosInstance";
import {LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS,} from "../../../constants/actionTypes";

export const loginUser = (password, username) => (dispatch) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    dispatch({
        type: LOGIN_LOADING,
    });
    axiosInstance()
        .post("/api/v1/login", formData)
        .then((res) => {
            localStorage.token = res.data.access_token;
            localStorage.setItem("user", JSON.stringify(res.data.user));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data.user,
            });
        })
        .catch((err) => {
            dispatch({
                type: LOGIN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
