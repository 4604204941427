import React, {useContext, useEffect, useState} from "react";

import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import {CustomToolbar} from "../components/customToolbar";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import {Tooltip} from "@mui/material";

import {GlobalContext} from "../context/Provider";
import {getErrorsReport} from "../context/actions/reports/reports";
import Grid from "@mui/material/Grid";
import MaterialUIPickers from "../components/MaterialUIPickers";
import TextField from "@mui/material/TextField";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";
import useDebounce from "../helpers/debounce";
import LoadingSpinner from "../components/LoadingSpinner";

function ErrorReports() {
    const user = JSON.parse(localStorage.getItem("user"));
    let dateObj = new Date();
    const {reportsDispatch, reportsState, tsAccountsDispatch, tsAccountsState} =
        useContext(GlobalContext);
    const [title, setTitle] = useState(
        `Errors Report until ${dateObj.toISOString().substring(0, 10)}`
    );
    const {
        errorReport: {data, loading},
    } = reportsState;

    const {tsAccountsList} = tsAccountsState;
    const [reportDate, setReportDate] = useState(dateObj);
    const [tsAccount, setTsAccount] = useState("");
    const [campaignExternalId, setCampaignExternalId] = useState("");
    const [runnerType, setRunnerType] = useState("");
    const [rowsState, setRowsState] = React.useState({
        page: 0,
        pageSize: 100,
    });

    const [rowCountState, setRowCountState] = React.useState(
        data?.stats?.count || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            data?.stats?.count !== null ? data?.stats?.count : prevRowCountState
        );
    }, [data?.stats?.count, setRowCountState]);

    const handleReportDateChange = (date) => {
        setReportDate(date);
        setTitle(`Errors Report ${date.toISOString().substring(0, 10)}`);
    };
    const debouncedValueCampaignExternalId = useDebounce(
        campaignExternalId,
        1000
    );
    const debouncedValueRunnerType = useDebounce(
        runnerType.toLowerCase().replaceAll(" ", "_"),
        1000
    );

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);

    useEffect(() => {
        document.title = title;
        if (debouncedValueCampaignExternalId || debouncedValueRunnerType) {
            getErrorsReport(
                reportDate.toISOString().substring(0, 10),
                tsAccount,
                debouncedValueCampaignExternalId,
                debouncedValueRunnerType,
                rowsState.page,
                rowsState.pageSize
            )(reportsDispatch);
        } else {
            getErrorsReport(
                reportDate.toISOString().substring(0, 10),
                tsAccount,
                null,
                null,
                rowsState.page,
                rowsState.pageSize
            )(reportsDispatch);
        }
    }, [
        title,
        tsAccount,
        reportsDispatch,
        reportDate,
        rowsState.page,
        rowsState.pageSize,
        debouncedValueCampaignExternalId,
        debouncedValueRunnerType,
    ]);

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    const handleChangeCampaign = (event) => {
        setCampaignExternalId(event.target.value);
    };

    const handleChangeRunnerType = (event) => {
        setRunnerType(event.target.value);
    };

    const columns = [
        {
            field: "runner_type",
            headerName: "Runner Type",
            width: 200,
            filterable: false,
        },
        {
            field: "info",
            headerName: "Info",
            width: 500,
            renderCell: (params) => (
                <Tooltip title={params.row.info}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.info}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "campaign_id",
            headerName: "Campaign ID",
            width: 110,
            renderCell: (cellValues) => {
                return <>{cellValues?.row?.arguments?.campaign_external_id}</>;
            },
            filterable: false,
        },
        {
            field: "last_status_change",
            headerName: "Last status change in UTC",
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.row.last_status_change}>
          <span
              sx={{
                  pt: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 940,
                  whiteSpace: "nowrap",
              }}
          >
            {params.row.last_status_change}
          </span>
                </Tooltip>
            ),
        },
        {
            field: "last_days",
            headerName: "Days",
            width: 80,
            renderCell: (cellValues) => {
                return <>{cellValues?.row?.arguments?.last_days}</>;
            },
        },
        {
            field: "run_time_in_seconds",
            headerName: "Run Time",
            width: 90,
            valueFormatter: (params) => {
                if (params && params.value) {
                    return params.value.toFixed(2) + " s";
                }
            },
        },
        {
            field: "algorithm_id",
            headerName: "Algorithm ID",
            width: 110,
            renderCell: (cellValues) => {
                return <>{cellValues?.row?.arguments?.algorithm_id}</>;
            },
        },
        {
            field: "ts_account_id",
            headerName: "Ts Account",
            width: 110,
            renderCell: (cellValues) => {
                let user = tsAccountsList.data.find((x) => x.id === cellValues.value);
                return <>{user?.name}</>;
            },
        },
        {
            field: "id",
            headerName: "Error ID",
            width: 100,
        },
    ];

    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                Errors Report
            </Typography>
            <Grid container spacing={2} sx={{mb: 5}}>
                <Grid item xs={3}>
                    <MaterialUIPickers
                        handleDateChange={handleReportDateChange}
                        value={reportDate}
                        label="Report Date"
                    />
                </Grid>
                {(user.role === "ADMIN" || user.role === "SUPERADMIN") && (
                    <Grid item xs={3} justifyContent="left" sx={{display: "flex"}}>
                        <TrafficSourceAccountDropdown
                            tsAccount={tsAccount}
                            data={tsAccountsList.data}
                            width={300}
                            handleChangeTsAccount={handleChangeTsAccount}
                        />
                    </Grid>
                )}
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <TextField
                        onChange={handleChangeCampaign}
                        name="campaign"
                        label="Campaign ID"
                        type="text"
                        id="campaign"
                        autoComplete="off"
                        value={campaignExternalId}
                    />
                </Grid>
                <Grid item xs={3} sx={{display: "flex", justifyContent: "flex-end"}}>
                    <TextField
                        onChange={handleChangeRunnerType}
                        name="epa"
                        label="Runner Type"
                        type="text"
                        id="runner_type"
                        autoComplete="off"
                        value={runnerType}
                    />
                </Grid>
            </Grid>
            {data ? (
                <div style={{width: "100%"}}>
                    <DataGrid
                        columns={columns}
                        rows={data?.results}
                        density="compact"
                        rowCount={rowCountState}
                        loading={loading}
                        rowsPerPageOptions={[100]}
                        pagination
                        {...rowsState}
                        paginationMode="server"
                        onPageChange={(page) => setRowsState((prev) => ({...prev, page}))}
                        onPageSizeChange={(pageSize) =>
                            setRowsState((prev) => ({...prev, pageSize}))
                        }
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        autoHeight={true}
                    />
                </div>
            ) : (
                <LoadingSpinner/>
            )}
        </>
    );
}

export {ErrorReports};
