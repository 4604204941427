import React, {useContext, useEffect, useState} from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {SelectTSAccount} from "./components/selectTSAccount";
import {SelectCampaigns} from "./components/selectCampaigns";
import {GlobalContext} from "../context/Provider";
import {getAlgorithms} from "../context/actions/algorithms/algorithms";
import {
    assignAlgorithmToCampaigns,
    getCampaignsForAlgorithm,
    getCampaigns,
} from "../context/actions/campaign/campaign";

import {getTrafficSource} from "../context/actions/traffic/trafficSource";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";

const steps = ["Select TS Account", "Select Campaigns"];

export function BulkAlgorithmUpdate() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [algo, setAlgo] = useState("");
    const [campaignType, setCampaignType] = useState("");
    const [tsAccount, setTsAccount] = useState("");
    const [filter, setFilter] = useState("");
    const [filterCampaign, setFilterCampaign] = useState([]);

    const [costModel, setCostModel] = useState("");

    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);

    const {
        tsAccountsDispatch,
        tsAccountsState,
        campaignDispatch,
        campaignState,
        algorithmsState,
        algorithmsDispatch,
    } = useContext(GlobalContext);

    const {trafficSourceList, tsAccountsList} = tsAccountsState;
    const {campaigns, getAssignAlgorithmsCampaign} = campaignState;
    const [trafficSource, setTrafficSource] = useState("");

    const {algorithmsList} = algorithmsState;
    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
        getTsAccounts()(tsAccountsDispatch);
    }, [tsAccountsDispatch, algorithmsDispatch]);

    useEffect(() => {
        const forAlgorithms = true;
        getCampaigns(tsAccount, costModel, campaignType, forAlgorithms)(campaignDispatch);
    }, [campaignDispatch, tsAccount, costModel, campaignType]);

    useEffect(() => {
        if (algo) {
            getCampaignsForAlgorithm(algo)(campaignDispatch);
        }
    }, [campaignDispatch, algo]);

    useEffect(() => {
        getAlgorithms(trafficSource)(algorithmsDispatch);
    }, [trafficSource, algorithmsDispatch]);

    useEffect(() => {
        if (getAssignAlgorithmsCampaign.data.length > 0) {
            setCheckedList(getAssignAlgorithmsCampaign.data);
        }

    }, [getAssignAlgorithmsCampaign.data]);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    useEffect(() => {
        if (campaigns.data) {
            setFilterCampaign(campaigns.data);
        }
    }, [campaigns.data]);

    useEffect(() => {
        if ((getAssignAlgorithmsCampaign.data.length > 1 && campaigns.data.length > 1) && (getAssignAlgorithmsCampaign.data.length === campaigns.data.length)) {
            setItemsChecked(true)
        }
    }, [getAssignAlgorithmsCampaign.data, campaigns.data]);
    useEffect(() => {
        if ((getAssignAlgorithmsCampaign.data.length > 1 && campaigns.data.length > 1) && (getAssignAlgorithmsCampaign.data.length !== campaigns.data.length)) {
            setItemsChecked(false)
        }
    }, [getAssignAlgorithmsCampaign.data, campaigns.data]);
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            assignAlgorithmToCampaigns(checkedList, algo, tsAccount)(campaignDispatch);
        }
    };

    const selectItem = (e) => {
        const {checked} = e.target;
        const collection = [];

        if (checked) {
            for (const campaign of campaigns.data) {
                collection.push(campaign.id);
            }
        }

        setCheckedList(collection);
        setItemsChecked(checked);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    const handleReset = () => {
        setActiveStep(0);
        setTrafficSource("");
        setAlgo("");
        setTsAccount("");
    };

    const handleChangeTs = (event) => {
        setTrafficSource(event.target.value);
    };

    const handleChangeAlgo = (e) => {
        setAlgo(e.target.value);
    };

    const handleCostModel = (e) => {
        setCostModel(e.target.value);
    };
    const handleCampaignType = (e) => {
        setCampaignType(e.target.value);
    };

    const handleCheckboxClick = (e) => {
        const {value, checked} = e.target;

        if (checked) {
            setCheckedList([...checkedList, value * 1]);
        } else {
            setCheckedList(checkedList.filter((item) => item !== parseInt(value)));
        }
    };

    const handleFilterCampaignByName = (e) => {
        setFilter(e.target.value);
        if (e.target.value) {
            const a = campaigns.data.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilterCampaign(a);
        } else {
            return setFilterCampaign(campaigns.data);
        }
    };
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <SelectTSAccount
                        tsAccount={tsAccount}
                        handleChangeTsAccount={handleChangeTsAccount}
                        tsAccountsList={tsAccountsList.data}
                        trafficSource={trafficSource}
                        trafficSourceList={trafficSourceList.data}
                        handleChangeTs={handleChangeTs}
                    />
                );
            case 1:
                return (
                    <SelectCampaigns
                        filter={filter}
                        campaignType={campaignType}
                        handleCampaignType={handleCampaignType}
                        handleCostModel={handleCostModel}
                        costModel={costModel}
                        checkedList={checkedList}
                        selectCampaign={selectItem}
                        handleCheckboxClick={handleCheckboxClick}
                        campaignsList={filterCampaign}
                        handleFilterCampaignByName={handleFilterCampaignByName}
                        selectedCampaignType={algo}
                        handleChangeAlgo={handleChangeAlgo}
                        algorithms={algorithmsList.data}
                        allCampaignsChecked={itemsChecked}
                    />
                );
            default:
                return "Unknown step";
        }
    };
    return (
        <Box sx={{width: "100%"}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{mt: 2, mb: 1}}>
                        Updated successfully
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        <Box sx={{flex: "1 1 auto"}}/>
                        <Button onClick={handleReset}>Select New</Button>
                    </Box>
                </>
            ) : (
                <>
                    {getStepContent(activeStep)}
                    <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                        <Box sx={{flex: "1 1 auto"}}/>

                        {activeStep !== steps.length - 1 && (
                            <Button onClick={handleNext}>Next</Button>
                        )}
                        {activeStep === steps.length - 1 && (
                            <Button onClick={handleNext}>Finish</Button>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}
