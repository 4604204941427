import React, {useContext, useEffect} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {GlobalContext} from "../context/Provider";
import LoadingSpinner from "../components/LoadingSpinner";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {getLandersDomains, toggleShouldIgnoreLander} from "../context/actions/domains/landers";
import Tooltip from "@mui/material/Tooltip";

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function ListLanders() {
    let columns;
    columns = [
        {field: "domain", headerName: "Domain Name", minWidth: 350},
        {field: "last_virus_total_check", headerName: "Last Virus Total Check", width: 250},
        {field: "marked_as_malicious", headerName: "Malicious", minWidth: 150},
        {
            field: "vt_engine",
            headerName: "Engines",
            width: 500,
            renderCell: (params) => (
                <Tooltip title={params.row.vt_engine ? params.row.vt_engine : ""}>
                      <span
                          sx={{
                              pt: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 940,
                              whiteSpace: "nowrap",
                          }}>
                        {params.row.vt_engine}
                      </span>
                </Tooltip>
            ),
        },
        {
            field: "should_ignore",
            headerName: "Should Ignore",
            width: 150
        },
        {
            field: "toggle_ignore_action",
            headerName: "Toggle Ignore",
            width: 100,
            renderCell: (cellValues) => {
                if (cellValues.row.should_ignore) {
                    return (
                        <>
                            <ToggleOnOutlinedIcon onClick={() => toggleShouldIgnore(cellValues.row.domain)}/>
                        </>
                    );
                } else {
                    return (
                        <>
                            <ToggleOffOutlinedIcon onClick={() => toggleShouldIgnore(cellValues.row.domain)}/>
                        </>
                    );
                }
            },
        },
        {
            field: "notification_sent",
            headerName: "Notification Sent",
            width: 140
        },
    ];

    const {landersDispatch, landersState} = useContext(GlobalContext);

    const {
        landersList
    } = landersState;

    useEffect(() => {
        getLandersDomains()(landersDispatch);
    }, [landersDispatch]);

    const toggleShouldIgnore = (domain) => {
        toggleShouldIgnoreLander(domain)(landersDispatch)
        getLandersDomains()(landersDispatch);
    }

    return (
        <>
            <Typography component="h1" variant="h5" sx={{mb: 5}}>
                All Landers
            </Typography>
            {landersList.data && !landersList.loading ? (
                <Box
                    sx={{
                        minHeight: 100,
                        width: "100%",
                        "& .super-app-theme--malicious": {
                            bgcolor: (theme) =>
                                getBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                            "&:hover": {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor(
                                        theme.palette.error.main,
                                        theme.palette.mode
                                    ),
                            },
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.domain}
                        rows={landersList.data}
                        getRowClassName={(params) => {
                            if (params.row.marked_as_malicious === true) {
                                return `super-app-theme--malicious`;
                            }
                        }}
                        columns={columns}
                        autoHeight/>
                </Box>
            ) : (
                <LoadingSpinner/>
            )}
        </>
    )
}

export {ListLanders};
