import React, {useCallback, useContext, useEffect, useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {CustomLink} from "./customLink";
import {loginReset} from "../context/actions/auth/logout";
import {GlobalContext} from "../context/Provider";
import {useNavigate} from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ListIcon from '@mui/icons-material/List';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import {getTasks} from "../context/actions/tasks/tasks";
import {getLandersDomains} from "../context/actions/domains/landers";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {Badge} from "@mui/material";
import {getOffersDomains} from "../context/actions/offers/offers";

const drawerWidth = 240;

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function Layout({children}) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const {authDispatch} = useContext(GlobalContext);
    const navigate = useNavigate();

    const [openCollapseCampaigns, setOpenCollapseCampaigns] = useState(false);
    const [openCollapseReports, setOpenCollapseReports] = useState(false);
    const [openCollapseLogs, setOpenCollapseLogs] = useState(false);
    const [openCollapseAlgorithms, setOpenCollapseAlgorithms] = useState(false);
    const [openCollapseGlobalBlacklist, setOpenCollapseGlobalBlacklist] = useState(false);
    const [openCollapseSettings, setOpenCollapseSettings] = useState(false);
    const [openCollapseDomains, setopenCollapseDomains] = useState(false);

    const handleClickCollapseReports = () => {
        setOpenCollapseReports(!openCollapseReports);
    };

    const handleClickCollapseLogs = () => {
        setOpenCollapseLogs(!openCollapseLogs);
    };

    const handleClickCollapseCampaigns = () => {
        setOpenCollapseCampaigns(!openCollapseCampaigns);
    };

    const handleClickCollapseAlgorithms = () => {
        setOpenCollapseAlgorithms(!openCollapseAlgorithms);
    };

    const handleClickCollapseBlacklist = () => {
        setOpenCollapseGlobalBlacklist(!openCollapseGlobalBlacklist);
    };

    const handleClickCollapseDomains = () => {
        setopenCollapseDomains(!openCollapseDomains);
    };

    const handleClickCollapseSettings = () => {
        setOpenCollapseSettings(!openCollapseSettings);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogOut = useCallback(() => {
        loginReset()(authDispatch);
        localStorage.clear();
        navigate("/");
    }, [authDispatch, navigate]);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const {tasksDispatch, tasksState} = useContext(GlobalContext);
    const {landersDispatch, landersState} = useContext(GlobalContext);
    const {offersDispatch, offersState} = useContext(GlobalContext);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        getTasks()(tasksDispatch);
        if (user.role === "ADMIN" || user.role === "SUPERADMIN") {
            getLandersDomains()(landersDispatch);
            getOffersDomains()(offersDispatch);
        }
    }, [tasksDispatch, landersDispatch, offersDispatch, user.role]);

    const {
        tasksList
    } = tasksState;

    const {
        landersList
    } = landersState;

    const {
        offersList
    } = offersState;

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: "none"})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        MEDIA BUYER
                    </Typography>

                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem>{user?.email}</MenuItem>
                            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon/>
                        ) : (
                            <ChevronRightIcon/>
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    <ListItemButton onClick={handleClickCollapseCampaigns}>
                        <ListItemIcon>
                            <InsertDriveFileOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Campaigns"/>
                        {openCollapseCampaigns ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={openCollapseCampaigns} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <CustomLink sx={{pl: 4}} to="/campaign">
                                <ListItemIcon>
                                    <ListOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Campaigns List"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/campaign_tree">
                                <ListItemIcon>
                                    <AccountTreeOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Campaigns Tree"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/bulk_campaign_type_update">
                                <ListItemIcon>
                                    <DynamicFeedOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Bulk Campaign Type Update"/>
                            </CustomLink>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleClickCollapseAlgorithms}>
                        <ListItemIcon>
                            <CalculateOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Algorithms"/>
                        {openCollapseAlgorithms ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={openCollapseAlgorithms} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <CustomLink sx={{pl: 4}} to="/create_algorithm">
                                <ListItemIcon>
                                    <AddOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Create Algorithms"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/list_algorithms">
                                <ListItemIcon>
                                    <ListOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Algorithms List"/>
                            </CustomLink>
                            {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                                <CustomLink sx={{pl: 4}} to="/default_algo">
                                    <ListItemIcon>
                                        <ListOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Default Algorithms"/>
                                </CustomLink>
                            )}
                            <CustomLink sx={{pl: 4}} to="/bulk_algorithm_update">
                                <ListItemIcon>
                                    <DynamicFeedOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Bulk Algorithm Update"/>
                            </CustomLink>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleClickCollapseReports}>
                        <ListItemIcon>
                            <AnalyticsOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Reports"/>
                        {openCollapseReports ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={openCollapseReports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <CustomLink sx={{pl: 4}} to="/reports/total">
                                <ListItemIcon>
                                    <AnalyticsOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Total Reports"/>
                            </CustomLink>
                            {/*<CustomLink sx={{pl: 4}} to="/reports/custom">*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <DashboardCustomizeIcon/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText primary="Custom Reports"/>*/}
                            {/*</CustomLink>*/}
                            <CustomLink sx={{pl: 4}} to="/reports/campaign">
                                <ListItemIcon>
                                    <InsertDriveFileOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Campaign Reports"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/reports/country">
                                <ListItemIcon>
                                    <PublicOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Country Reports"/>
                            </CustomLink>
                            <ListItemButton sx={{pl: 4}} onClick={handleClickCollapseLogs}>
                                <ListItemIcon>
                                    <AutoGraphOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logs"/>
                                {openCollapseLogs ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={openCollapseLogs} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <CustomLink sx={{pl: 8}} to="/reports/success">
                                        <ListItemIcon>
                                            <CheckBoxOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Successes"/>
                                    </CustomLink>
                                    <CustomLink sx={{pl: 8}} to="/reports/error">
                                        <ListItemIcon>
                                            <ErrorOutlineOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Errors"/>
                                    </CustomLink>
                                </List>
                            </Collapse>
                        </List>
                    </Collapse>
                    {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") && (
                        <>
                            <ListItemButton onClick={handleClickCollapseBlacklist}>
                                <ListItemIcon>
                                    <BlockOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Global Blacklist"/>
                                {openCollapseGlobalBlacklist ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={openCollapseGlobalBlacklist} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <CustomLink sx={{pl: 4}} to="/blacklist_site">
                                        <ListItemIcon>
                                            <LanguageOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Global Blacklist Site"/>
                                    </CustomLink>
                                    <CustomLink sx={{pl: 4}} to="/blacklist_zone">
                                        <ListItemIcon>
                                            <WebOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Global Blacklist Zone"/>
                                    </CustomLink>
                                </List>
                            </Collapse>
                        </>
                    )}
                    {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                        <CustomLink to="/performance_monitor">
                            <ListItemIcon>
                                <DvrOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Performance Monitor"/>
                        </CustomLink>
                    )}
                    {(
                        <CustomLink to="/task_management">
                            <ListItemIcon>
                                {user?.role === "MEDIABUYER" && tasksList.data && !tasksList.loading &&
                                tasksList.data.filter(item => item.is_shown === false).length > 0 ? (
                                    <Badge badgeContent={tasksList.data.filter(item => item.is_shown === false).length}
                                           color="primary">
                                        <NotificationsActiveOutlinedIcon/>
                                    </Badge>
                                ) : (
                                    <NotificationsActiveOutlinedIcon/>
                                )}
                            </ListItemIcon>
                            <ListItemText primary="Tasks Management"/>
                        </CustomLink>
                    )}
                    {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                        <ListItemButton onClick={handleClickCollapseDomains}>
                            <ListItemIcon>
                                {landersList.data && !landersList.loading &&
                                landersList.data.filter(item => item.marked_as_malicious === true &&
                                    item.should_ignore === false).length > 0 ? (
                                    <Badge
                                        badgeContent={landersList.data.filter(item => item.marked_as_malicious === true &&
                                            item.should_ignore === false).length}
                                        color="primary">
                                        <DomainIcon/>
                                    </Badge>
                                ) : (
                                    <DomainIcon/>
                                )}
                            </ListItemIcon>
                            <ListItemText primary="Domains"/>
                            {openCollapseDomains ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                    )}
                    <Collapse in={openCollapseDomains} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <CustomLink sx={{pl: 4}} to="/domains/replace_domains">
                                <ListItemIcon>
                                    <FindReplaceIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Replace Domains"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/domains/list_landers">
                                <ListItemIcon>
                                    {
                                        landersList.data &&
                                        !landersList.loading &&
                                        landersList.data.filter(item => item.marked_as_malicious === true &&
                                            item.should_ignore === false).length > 0 ? (
                                            <Badge
                                                badgeContent={landersList.data.filter(item => item.marked_as_malicious === true &&
                                                    item.should_ignore === false).length}
                                                color="primary">
                                                <ListIcon/>
                                            </Badge>
                                        ) : (
                                            <ListIcon/>
                                        )}
                                </ListItemIcon>
                                <ListItemText primary="List Landers"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/domains/list_domains">
                                <ListItemIcon>
                                    <ListIcon/>
                                </ListItemIcon>
                                <ListItemText primary="List Domains"/>
                            </CustomLink>
                            <CustomLink sx={{pl: 4}} to="/domains/domain_tasks">
                                <ListItemIcon>
                                    <PendingActionsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Domain Replacement Tasks"/>
                            </CustomLink>
                        </List>
                    </Collapse>
                    {(
                        <CustomLink to="/list_offers">
                            <ListItemIcon>
                                {offersList.data && !offersList.loading &&
                                offersList.data.filter(item => item.marked_as_malicious === true &&
                                    item.should_ignore === false).length > 0 ? (
                                    <Badge
                                        badgeContent={offersList.data.filter(item => item.marked_as_malicious === true &&
                                            item.should_ignore === false).length}
                                        color="primary">
                                        <ListIcon/>
                                    </Badge>
                                ) : (
                                    <ListIcon/>
                                )}
                            </ListItemIcon>
                            <ListItemText primary="List Offers"/>
                        </CustomLink>
                    )}
                    {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") && (
                        <ListItemButton onClick={handleClickCollapseSettings}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Settings"/>
                            {openCollapseSettings ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>

                    )}
                    <Collapse in={openCollapseSettings} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(user?.role === "ADMIN" || user?.role === "SUPERADMIN") && (
                                <>
                                    <CustomLink sx={{pl: 4}} to="/ts_accounts">
                                        <ListItemIcon>
                                            <PeopleAltOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Traffic Source Accounts"/>
                                    </CustomLink>
                                </>
                            )}
                            {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                                <CustomLink sx={{pl: 4}} to="/voluum">
                                    <ListItemIcon>
                                        <PeopleAltOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Voluum Campaigns"/>
                                </CustomLink>
                            )}
                            {(user?.role === "SUPERADMIN" || user?.role === "ADMIN") && (
                                <CustomLink sx={{pl: 4}} to="/events">
                                    <ListItemIcon>
                                        <SportsScoreOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Events"/>
                                </CustomLink>
                            )}
                            {user?.role === "SUPERADMIN" && (
                                <CustomLink sx={{pl: 4}} to="/user_management">
                                    <ListItemIcon>
                                        <ManageAccountsOutlinedIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="User Management"/>
                                </CustomLink>
                            )}
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader/>
                {children}
            </Main>
        </Box>
    );
}
