import React, {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import {GlobalContext} from "../../context/Provider";
import useDebounce from "../../helpers/debounce";
import {getAlgorithmsName, getAlgorithmsTypes} from "../../context/actions/algorithms/algorithms";
import {getTrafficSource} from "../../context/actions/traffic/trafficSource";

export function AddAlgorithm(props) {
    const {
        /* {name, algorithmType, algorithmTypeId} */
        addAlgorithmState,
        /* (addAlgorithmState) => void */
        onAddAlgorithmStateChange,
    } = props;

    const {
        algorithmsState,
        algorithmsDispatch,
        tsAccountsState,
        tsAccountsDispatch,
    } = useContext(GlobalContext);


    /*
    SERVER RETURNED DATA
     */
    /* algorithm types pulled from server */
    const availableAlgorithmTypes = algorithmsState?.algorithmsTypes?.data || []
    /* result from server if algorithm with given name already exists */
    const nameIsTaken = algorithmsState?.algorithmsName?.data !== undefined
        && algorithmsState?.algorithmsName?.data !== null
    /* list of traffic sources pulled from serves */
    const availableTrafficSources = tsAccountsState?.trafficSourceList?.data || []
    useEffect(() => {
        getAlgorithmsTypes()(algorithmsDispatch);
    }, [algorithmsDispatch]);

    useEffect(() => {
        getTrafficSource()(tsAccountsDispatch);
    }, [tsAccountsDispatch]);
    /*
    COMPONENT STATE
     */
    const [name, setName] = useState(addAlgorithmState.name);
    const [trafficSourceId, setTrafficSourceId] = useState(addAlgorithmState.trafficSourceId);
    // descriptive algorithm type (e.g. BID_OPTIMIZATION, CPC_KVOK_BLOCK)
    const [algorithmType, setAlgorithmType] = useState(addAlgorithmState.algorithmType);
    const [algorithmTypeId, setAlgorithmTypeId] = useState(addAlgorithmState.algorithmTypeId);

    const debouncedName = useDebounce(name, 1000);
    useEffect(() => {
        if (debouncedName) {
            getAlgorithmsName(debouncedName)(algorithmsDispatch);
        }
    }, [algorithmsDispatch, debouncedName]);

    useEffect(() => {
        onAddAlgorithmStateChange({
            name,
            nameIsTaken,
            trafficSourceId,
            algorithmType,
            algorithmTypeId
        })
    }, [name, nameIsTaken, trafficSourceId, algorithmType, algorithmTypeId, onAddAlgorithmStateChange])

    const onAlgorithmTypeChange = (newAlgorithmTypeId) => {
        const selectedType = availableAlgorithmTypes.find((item) => item.id === newAlgorithmTypeId);
        const typeName = selectedType?.algorithm_type;
        setAlgorithmType(typeName);
        setAlgorithmTypeId(newAlgorithmTypeId);
    };

    return (
        <Container component="main" maxWidth="xs">
            {nameIsTaken && (
                <Alert severity="error">
                    An algorithm with this name already exists. Try another name.
                </Alert>
            )}
            <Box
                sx={{
                    marginTop: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box component="form" sx={{mt: 3, width: 500}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={(e) => setName(e.target.value)}
                                id="remote_id"
                                label="Algorithm Name"
                                name="name"
                                value={name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="campaign-type-select-label">
                                    Algorithm Type
                                </InputLabel>
                                <Select
                                    required
                                    labelId="campaign-type-select-label"
                                    id="campaign-type-select"
                                    value={algorithmTypeId}
                                    label="Algorithm Type"
                                    onChange={(e) => onAlgorithmTypeChange(e.target.value)}
                                >
                                    <MenuItem value={""}>
                                        {" "}
                                        <em>None</em>
                                    </MenuItem>
                                    {availableAlgorithmTypes.map((item, i) => {
                                        return createMenuItem(i, item.id, item.algorithm_type);
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="ts-account-label">Traffic Source</InputLabel>
                                <Select
                                    required
                                    labelId="ts-account-label"
                                    id="ts-account-label-select"
                                    value={trafficSourceId}
                                    label="Traffic Source"
                                    onChange={(e) => setTrafficSourceId(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {availableTrafficSources.map((ts, i) => createMenuItem(i, ts.id, ts.name))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

const createMenuItem = (key, value, text) => (
    <MenuItem key={key} value={value}>
        {text}
    </MenuItem>
)
