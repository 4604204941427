import {axiosInstance} from "../../../helpers/axiosInstance";
import{
    GET_DOMAIN_TASKS_ERROR,
    GET_DOMAIN_TASKS_LOADING,
    GET_DOMAIN_TASKS_SUCCESS,
    REPLACE_DOMAIN_ERROR,
    REPLACE_DOMAIN_LOADING,
    REPLACE_DOMAIN_SUCCESS,
    DELETE_DOMAIN_TASK_ERROR,
    DELETE_DOMAIN_TASK_LOADING,
    DELETE_DOMAIN_TASK_SUCCESS
} from "../../../constants/actionTypes";
import {version} from "../../../constants/api";

export const getDomainTasks = () => (dispatch) => {
    dispatch({
        type: GET_DOMAIN_TASKS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/domain_tasks`)
        .then((res) => {
            dispatch({
                type: GET_DOMAIN_TASKS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_DOMAIN_TASKS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
export const replaceDomain = (data) => (dispatch) => {
    dispatch({
        type: REPLACE_DOMAIN_LOADING,
    });
    axiosInstance()
        .post(`/api/${version}/domain_tasks/`, data)
        .then((res) => {
            dispatch({
                type: REPLACE_DOMAIN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: REPLACE_DOMAIN_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
export const deleteDomainTask = (id) => (dispatch) => {
    dispatch({
        type: DELETE_DOMAIN_TASK_LOADING,
    });
    axiosInstance()
        .delete(`/api/${version}/domain_tasks/${id}`)
        .then((res) => {
            dispatch({
                type: DELETE_DOMAIN_TASK_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_DOMAIN_TASK_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};
