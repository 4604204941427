import {axiosInstance} from "../../../helpers/axiosInstance";
import {version} from "../../../constants/api";
import {
    CREATE_ALGORITHMS_ERROR,
    CREATE_ALGORITHMS_LOADING,
    CREATE_ALGORITHMS_SUCCESS,
    CREATE_DEFAULT_ALGORITHMS_ERROR,
    CREATE_DEFAULT_ALGORITHMS_LOADING,
    CREATE_DEFAULT_ALGORITHMS_SUCCESS,
    DELETE_ALGORITHMS_ERROR,
    DELETE_ALGORITHMS_ERROR_MASSAGE,
    DELETE_ALGORITHMS_LOADING,
    DELETE_ALGORITHMS_SUCCESS,
    DELETE_DEFAULT_ALGORITHMS_ERROR,
    DELETE_DEFAULT_ALGORITHMS_LOADING,
    DELETE_DEFAULT_ALGORITHMS_SUCCESS,
    GET_ALGORITHMS_ERROR,
    GET_ALGORITHMS_LOADING,
    GET_ALGORITHMS_NAME_ERROR,
    GET_ALGORITHMS_NAME_LOADING,
    GET_ALGORITHMS_NAME_SUCCESS,
    GET_ALGORITHMS_SUCCESS,
    GET_ALGORITHMS_TYPES_ERROR,
    GET_ALGORITHMS_TYPES_LOADING,
    GET_ALGORITHMS_TYPES_SUCCESS,
    GET_DEFAULT_ALGORITHMS_ERROR,
    GET_DEFAULT_ALGORITHMS_LOADING,
    GET_DEFAULT_ALGORITHMS_SUCCESS,
    TURN_ON_OFF_ALGORITHMS_ERROR,
    TURN_ON_OFF_ALGORITHMS_LOADING,
    TURN_ON_OFF_ALGORITHMS_SUCCESS,
} from "../../../constants/actionTypes";

export const getAlgorithmsTypes = () => (dispatch) => {
    dispatch({
        type: GET_ALGORITHMS_TYPES_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/algorithms/types/`)
        .then((res) => {
            dispatch({
                type: GET_ALGORITHMS_TYPES_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALGORITHMS_TYPES_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};

export const getAlgorithmsName = (name) => (dispatch) => {
    dispatch({
        type: GET_ALGORITHMS_NAME_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/algorithms/names/${name}`)
        .then((res) => {
            dispatch({
                type: GET_ALGORITHMS_NAME_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALGORITHMS_NAME_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};

export const turnOnOffAlgorithms =
    (algorithmId, turnedOn, ts) => (dispatch) => {
        let url = `/api/${version}/algorithms/turn_on_off?algorithm_id=${algorithmId}&turned_on=${turnedOn}`;
        if (ts) {
            url += `&traffic_source_id=${ts}`;
        }

        dispatch({
            type: TURN_ON_OFF_ALGORITHMS_LOADING,
        });
        axiosInstance()
            .put(url)
            .then((res) => {
                dispatch({
                    type: TURN_ON_OFF_ALGORITHMS_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: TURN_ON_OFF_ALGORITHMS_ERROR,
                    payload: err.response
                        ? err.response.data.detail
                        : "COULD NOT CONNECT",
                });
            });
    };

export const createAlgorithms = (algorithms) => (dispatch) => {
    dispatch({
        type: CREATE_ALGORITHMS_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/algorithms`, algorithms)
        .then((res) => {
            dispatch({
                type: CREATE_ALGORITHMS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const getAlgorithms = (ts) => (dispatch) => {
    const id = ts ? ts : null;
    dispatch({
        type: GET_ALGORITHMS_LOADING,
    });
    axiosInstance()
        .get(`/api/${version}/algorithms/`, {params: {ts_id: id}})
        .then((res) => {
            dispatch({
                type: GET_ALGORITHMS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteAlgorithms = (id) => (dispatch) => {
    dispatch({
        type: DELETE_ALGORITHMS_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/algorithms/`, {params: {algorithm_id: id}})
        .then((res) => {
            dispatch({
                type: DELETE_ALGORITHMS_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};

export const getDefaultAlgorithms =
    (costModel, campaignType, tsAccount) => (dispatch) => {
        dispatch({
            type: GET_DEFAULT_ALGORITHMS_LOADING,
        });
        axiosInstance()
            .get(`/api/${version}/algorithms/default`, {
                params: {
                    cost_model: costModel,
                    campaign_type: campaignType,
                    ts_account_id: tsAccount,
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_DEFAULT_ALGORITHMS_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: GET_DEFAULT_ALGORITHMS_ERROR,
                    payload: err.response ? err.response.data : "COULD NOT CONNECT",
                });
            });
    };

export const createDefaultAlgorithms = (algorithms) => (dispatch) => {
    dispatch({
        type: CREATE_DEFAULT_ALGORITHMS_LOADING,
    });

    axiosInstance()
        .put(`/api/${version}/algorithms/default`, algorithms)
        .then((res) => {
            dispatch({
                type: CREATE_DEFAULT_ALGORITHMS_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_DEFAULT_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data : "COULD NOT CONNECT",
            });
        });
};

export const deleteDefaultAlgorithms = (id) => (dispatch) => {
    dispatch({
        type: DELETE_DEFAULT_ALGORITHMS_LOADING,
        payload: id,
    });

    axiosInstance()
        .delete(`/api/${version}/algorithms/default/${id}`)
        .then((res) => {
            dispatch({
                type: DELETE_DEFAULT_ALGORITHMS_SUCCESS,
                payload: id,
            });
        })
        .catch((err) => {
            dispatch({
                type: DELETE_DEFAULT_ALGORITHMS_ERROR,
                payload: err.response ? err.response.data.detail : "COULD NOT CONNECT",
            });
        });
};

export const resetMessageAlgorithmDelete = () => (dispatch) => {
    dispatch({
        type: DELETE_ALGORITHMS_ERROR_MASSAGE,
        payload: null,
    });
};
