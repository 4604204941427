import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import DateRangeFilter from "./components/dateRangeFilter";
import Typography from "@mui/material/Typography";
import {TrafficSourceAccountDropdown} from "../components/trafficSourceAccountDropdown";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

import {GlobalContext} from "../context/Provider";
import {getTsAccounts} from "../context/actions/traffic/tsAccounts";

import {getVoluumCampaigns} from "../context/actions/voluum/voluum";
import CampaignReportsTable from "./components/campaignReportsTable";
import {COMPLETE_REPORT_GROUP_BY, composeTitle} from "./components/common";

function CampaignReports() {
    const {
        tsAccountsDispatch,
        tsAccountsState,
        voluumDispatch,
    } = useContext(GlobalContext);

    const user = JSON.parse(localStorage.getItem("user"));

    const [dateRange, setDateRange] = useState({
        from: new Date(),
        to: new Date(),
    })

    const [tsAccount, setTsAccount] = useState("");

    const {tsAccountsList} = tsAccountsState;

    const [showAssignedOnly, setShowAssignedOnly] = useState(false);

    const [title, setTitle] = useState(composeTitle(COMPLETE_REPORT_GROUP_BY.CAMPAIGN, dateRange));

    const handleChangeTsAccount = (event) => {
        setTsAccount(event.target.value);
    };

    // automatically set title on date range change
    useEffect(() => setTitle(composeTitle(COMPLETE_REPORT_GROUP_BY.CAMPAIGN, dateRange)), [dateRange]);

    useEffect(() => {
        getTsAccounts()(tsAccountsDispatch);
        getVoluumCampaigns()(voluumDispatch);
    }, [tsAccountsDispatch, voluumDispatch]);

    return (
        <>
            <Box>
                <Typography component="h1" variant="h5" sx={{mb: 5}}>
                    {title}
                </Typography>
                <Grid container sx={{mb: 5}}>
                    {(user.role === "ADMIN" || user.role === "SUPERADMIN") && (
                        <Grid item xs={3} justifyContent="left" sx={{display: "flex"}}>
                            <TrafficSourceAccountDropdown
                                tsAccount={tsAccount}
                                data={tsAccountsList.data}
                                width={300}
                                handleChangeTsAccount={handleChangeTsAccount}
                            />
                        </Grid>
                    )}
                </Grid>
                <DateRangeFilter onDateRangeChange={setDateRange} dateRange={dateRange}/>
                <Grid container>
                    <Grid item xs={3} sx={{display: "flex"}}>
                        <FormControlLabel
                            value="3"
                            control={
                                <Checkbox
                                    checked={showAssignedOnly}
                                    onClick={(e) => setShowAssignedOnly(e.target.checked)}
                                    name="c3"
                                />
                            }
                            label="Show assigned only"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{display: "flex", justifyContent: "flex-end"}}
                    ></Grid>
                </Grid>
                <CampaignReportsTable
                    tsAccountsList={tsAccountsList}
                    dateRange={dateRange}
                    tsAccount={tsAccount}
                    showAssignedOnly={showAssignedOnly}
                />
            </Box>

        </>
    );
}

export {CampaignReports};
